import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';

import { WORLD_MAP_DATA } from 'lib/gql';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { TabMenu } from "components/map";
import MapCategory from "components/map/common/MapCategory";
import InputSelect from "components/map/common/InputSelect";
import FullPageButton from "components/map/common/controller/FullPageButton";
import MapBox from "components/map/common/MapBox";

export const LandingPageMapContents = ({subtitle,title,button,width}) => {
  const [tabColor, setTabColor] = useState("#E91313");
  const [fullScreen, setFullScreen] = useState(false);
  const [searchYear, setSearchYear] = useState({year:moment().format('YYYY')});
  const [searchMonth, setSearchMonth] = useState([]);
  const [pathogenGroup, setpathogenGroup] = useState('RV');
  const [searchCode, setSearchCode] = useState('FLUA');
  const [noResult, setNoResult] = useState(false);
  const [worldMapChart, setWorldMapChart] = useState([])

  const {loading, error, data: mapData, refetch} = useQuery(WORLD_MAP_DATA, {
    fetchPolicy: "no-cache",
    variables: {
        // 조회조건
        groupCode: pathogenGroup,
        searchYear: searchYear.year,
        pathogenCode: searchCode
    },
  });

  const [ viewport, setViewport ] = useState({ 
    // 초기값, 
    // latitude: 36.559212,
    // longitude: -36.477076,
    latitude: 24,
    longitude: 10,
    width: '100%',
    height: '100%',
    zoom: 2
  });

  const bgColor = {
    RV: "#E91313",
    GI: "#F3B508",
    STI: "#72C124",
    HPV: "#F53280",
    MENINGITIS: "#0C9EFF",
  };

  const handleTabMenuColor = useCallback((color) => {
    if(color === tabColor) {
      return;
    }
    setTabColor(bgColor[color]);
  },[tabColor]);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleSearchData = useCallback((groupCode) => {
    setpathogenGroup(groupCode);
    handleTabMenuColor(groupCode)
  },[pathogenGroup, searchCode])




  const history = useHistory();
  const sgstatsToken = localStorage.getItem('sgstatsToken');

  const authPath = () => {
    if (
        sgstatsToken === '' ||
        sgstatsToken === null ||
        sgstatsToken === undefined
      ) {
        history.push('/member/login');
    } else {
      history.push('/');
    }
  }
  

  let commonProps = {
    loading,
    tabColor,
    fullScreen,
    yearValue:searchYear.year
  }

  
  useEffect(() => {
    if(mapData !== undefined && mapData !== null){
      setWorldMapChart([...mapData.worldMapChartData.filter(i => i.nationalAreaCode !== 'TOTAL')])
    } 
  }, [mapData, loading])

  return (
    <RightPanel>
      <MapCategory tabColor={tabColor} fullScreen={fullScreen}/>

      <MapTopButton>
        <InputSelect value={searchYear.year} onChange={(e) => setSearchYear({year: e.target.value})} setSearchMonth={setSearchMonth} />
        {/* <FullPageButton {...commonProps} handleFullScreen={handleFullScreen} /> */}
      </MapTopButton>
      
      <MapArea className="mapContainer">
        <MapBox {...commonProps } viewport={viewport} clusterData={worldMapChart} noResult={noResult} setViewport={setViewport}/>
      </MapArea>

      <TabMenu {...commonProps } pathogenGroup={pathogenGroup} handleSearchData={handleSearchData} handleTabMenuColor={handleTabMenuColor} viewport={viewport} setViewport={setViewport} setSearchCode={setSearchCode} searchCode={searchCode} />
    </RightPanel>
  )
}

const MapChartWrapper = Styled.div`
  display: flex;
  justify-content: space-between;
  position:absolute;
  top: 85px;
  left:0;
  width:100%;
  height: calc(100% - 85px);
`;

const LeftPanel = Styled.div`
  z-index:100;
  position:absolute;
  left:${({fullScreen}) => fullScreen ? "-323px" : "0"};
  top:0;
  display:flex;
  flex-direction:column;
  border-right:15px solid #f2f5f8;
  width:323px;
  height:100%;
  transition:left .3s;
`;

const RightPanel = Styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height:100%;
  background: #212b34;
  border-radius: 2px;
  color: #fff;
  transition: width 0.3s ease;
`;

const MapTopButton = Styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  // right: 15px;
  // top: 15px;
  right: 50px;
  top: 90px;
  // width: 130px;
`;

const MapArea = Styled.div`
  width:100%;
  height:100%;
`;
