import React from 'react';
import styled from "styled-components";
import * as mixins from "assets/styled/Mixin.styled";

const TotalRange = ({background,value}) => {

  return (
    <StyledTotalRange
      percent={value}
      bgColor={background}
    >
      <div className="range">
        <span className="bullet" />
      </div>
      <div className="range-num">
        <span>0</span>
        <span>100</span>
      </div>
    </StyledTotalRange>
  )
}

export default TotalRange;

const StyledTotalRange = styled.div`
  position: relative;
  margin: 20px 0 10px;
  background:#e8e8e8;
  border-radius:80px;
  width:100%;
  height:4px;
  .range {
    position:absolute;
    left:0;
    top:0;
    width:${({percent}) => percent ? `${percent}%` : "0"};
    height:100%;
    background-color:${({bgColor}) => bgColor ? bgColor : "#e8e8e8"};
    border-radius:80px;
    transition: width 1s .3s ease;
    .bullet {
      z-index:1;
      position:absolute;
      right:-6px;
      top:50%;
      transform:translateY(-50%);
      width:8px;
      height:8px;
      background-color:${({bgColor}) => bgColor ? bgColor : "transparent"};
      border-radius:50%;
      &::before {
        z-index:-1;
        ${mixins.afterBefore()};
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        width:24px;
        height:24px;
        background-color:rgba(255,255,255,0.2);
        border-radius:50%;
        animation: light 2.5s ease-in-out infinite;
      }
    }

    @keyframes light{
      0%, 75%, 100%{
        opacity:0;
      }

      25%{
        opacity:1;
      }
    }
  }
  .range-num {
    display:flex;
    justify-content:space-between;
    width:100%;
    > span {
      ${mixins.fontSet("#899097", "11px")};
      display:inline-block;
      margin-top:10px;
      line-height:1;
      font-weight:400;
    }
  }
`;