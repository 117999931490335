import { createAction, handleActions } from 'redux-actions';
import * as api from 'lib/api';
import { ACCESS_TOKEN } from '../../constants';
import { fromJS } from 'immutable';
import { pender } from 'redux-pender';
import jwtDecode from 'jwt-decode';

const LOGIN = 'base/LOGIN';
const LOGOUT = 'base/LOGOUT';
const TEMP_LOGIN = 'base/TEMP_LOGIN';

export const login = createAction(LOGIN, (id, pw) => api.login(id, pw), id => ({ loggingId: id }));
export const logout = createAction(LOGOUT);
export const tempLogin = createAction(TEMP_LOGIN);


const emptyLoginInfo = {
  id: '',
  userId: '',
  userName: '',
};

const initialState = fromJS({
    logged: false, // 현재 로그인 상태
    loginInfo: emptyLoginInfo,
    registered: false,
    approved: false
  });

const loginProcess = (state, accessToken, registered=false, approved=false) => {
  try {
    const decPayload = jwtDecode(accessToken);
    const loginInfo = {
      id: decPayload.id,
      userId: decPayload.sub,
      userName: decPayload.name
    };
    if(!approved){
      return state.set('approved', false).mergeIn(['loginInfo'], loginInfo)
    }
    if(!state.get('registered'))  {
      state = state.set('registered', registered);
    }
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    return state.set('logged', true).mergeIn(['loginInfo'], loginInfo);
  } catch (e) {
    console.error(e);
    return state;
  }
};

const logoutProcess = (state) => {
  localStorage.removeItem(ACCESS_TOKEN);
  return state.set('logged', false).set('registered', false).mergeIn(['loginInfo'], emptyLoginInfo);
};

// reducer
export default handleActions({
  ...pender({
    type: LOGIN,
    // 로그인 성공 시
    onSuccess: (state, action) => {
      return (loginProcess(state, action.payload.data.sgstatsToken, action.payload.data.registered, action.payload.data.approved))
    }
    ,
    // 에러 발생 시
    onError: (state, action) => logoutProcess(state),
  }),
  [LOGOUT](state) {
    return logoutProcess(state);
  },
  [TEMP_LOGIN](state, action) {
    return loginProcess(state, action.payload.sgstatsToken);
  }
}, initialState);