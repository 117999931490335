import React from 'react';
import Styled from 'styled-components';

export const PolicyPopupData = () => {

  return (
    <>
      <h2>SEEGENE’S PRIVACY POLICY</h2>
      <ul>
        <li>Welcome to the Seegene’s privacy policy. Seegene respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your persona l data when you visit this web-based Services (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you. Please also use the Glossary to understand the meaning of some of the terms used in this Privacy Policy.</li>
        <li>1. Important Information and Who We Are <br/>• Purpose of this Privacy Policy <br/> We, Seegene, Inc. (“Seegene”), build technologies and services that\n enable people to share test results of Seegene products and \n statistical analysis thereof and to connect with each other (hereafter “Services”).</li>
        <li>This privacy policy aims to give you information on how Seegene collects and processes your personal data through your use of the Services, including any content you may provide when you use the Services. The Services are not intended for children, and we do not knowingly collect data from children.</li>
        <li>It is important that you read this Privacy Policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data</li>
        <li>about you so that you are fully aware of how and why we are using your data. This Privacy Policy supplements other notices and privacy policies and is not intended to override them.</li>
        <li>• Controller Seegene is the controller and responsible for your personal data (collectively referred to as “Seegene,” “we,” “us” or “our” in this Privacy Policy). <br/> Seegene is made up of different legal entities, details of which can be found here: <a href="http://www.seegene.com/global_networks">http://www.seegene.com/global_networks.</a> This privacy policy is issued on behalf of the Seegene Group so when we mention “Seegene,” “we,” “us,” or “our” in this privacy policy, we are referring to the relevant company in the Seegene Group responsible for processing your data.</li>
        <li>• Contact Details We have<br /> appointed a data privacy manager who is responsible for overseeing questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy, our privacy practices or the data we hold on you, or you would like to exercise one of your data protection rights, please contact the data privacy manager as follows: Director of IT Convergence Center, Seegene Inc.</li>
      </ul>
    </>
  )
}