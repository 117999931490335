import React from "react";
import styled from "styled-components";
import CategoryIcon from "assets/svg/icon_man.svg"
import * as mixins from 'assets/styled/Mixin.styled';
const { REACT_APP_APP_HOST: appHost } = process.env;

const MapCategory = ({ tabColor, fullScreen }) => {

  return (
    <MapCategoryWrapper tabColor={tabColor} fullScreen={fullScreen}>
      <strong>Test Category</strong>
      <ul>
        <li>
          <span />
        </li>
        <li>
          <span />
        </li>
        <li>
          <span />
        </li>
        <li>
          <span />
        </li>
        <li>
          <span />
        </li>
      </ul>
    </MapCategoryWrapper>
  );
};

export default MapCategory;

// Category Inner Style func
function getStyle() {
  const size = {
    1: "16",
    2: "14",
    3: "12",
    4: "10",
    5: "6",
  };

  const width = {
    1: "58",
    2: "46",
    3: "34",
    4: "22",
    5: "10",
  };

  let style = "";

  for (let i in size) {
    style += `
      &:nth-child(${i}) > span {
        width: ${width[i] + "px"}
      }
      &:nth-child(${i})::before {
        width: ${size[i] + "px"};
        height: ${size[i] + "px"};
        left: ${i + "px"};
      }
      &:last-child::before {
        left: 6px;
      }
    `;
  }
  return style;
}

const MapCategoryWrapper = styled.div`
  position: absolute;
  z-index: 1;
  // left: ${({fullScreen}) => fullScreen ? "15px" : "338px"};
  left: 50px;
  top: 90px;
  padding: 12px;
  width: 120px;
  background: #161e26;
  border-radius: 4px;
  transition: left .3s;
  > strong {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 800;
  }
  > ul li {
    position: relative;
    padding-left: 24px;
    height: 16px;
    span {
      display: inline-block;
      height: 100%;
      background: url("${appHost}${CategoryIcon}") space;
    }
    &::before {
      ${mixins.afterBefore()}
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: ${({ tabColor }) => (tabColor === '#E91313' ? '#F9C5C5' : tabColor === '#F3B508' ? '#FCEDC3' : tabColor === '#72C124' ? '#DCEFC9' : tabColor === '#F53280' ? '#FCCDE0' : tabColor === '#0C9EFF' ? '#C4E7FF' : '#fff')};
      opacity: 0.9;
      border-radius: 50%;
      transition: background 0.3s ease;
    }
    &:not(:first-child) {
      margin-top: 8px;
    }
    ${getStyle()};
  }
`;
