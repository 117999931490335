import React, {useEffect, useState} from "react";
import * as mixins from 'assets/styled/Mixin.styled';
import { MapTrendChart } from "components/amchart";
import { CircleLoader } from "components/loader";
import styled from "styled-components";
import IconNoData from 'assets/svg/icon_trend_nodata.svg';
const { REACT_APP_APP_HOST: appHost } = process.env;

const MapTrendPositive = ({ tabColor, searchMonth, trendData, loading, yearValue, noResult}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if(trendData && trendData.length > 0) {
      let arr = [...searchMonth];
      for(let i=0; i<searchMonth.length; i++) {
        const find = searchMonth.findIndex(data => data.exptDate === trendData[i]?.exptDate);
        if(find !== -1){
          delete arr[find];
          arr[find] = {
            month:`${find+1}`,
            exptDate:trendData[i]?.exptDate,
            count:trendData[i]?.positiveCases,
          };
        }
        setChartData([...arr])
      }
    }
  }, [trendData])

  return (
    <MapTrendPositiveStyled>
      <div className="wrapper">
        <TrendChartTitle>
          <strong>{`${yearValue} Trend of positive cases`}</strong>
        </TrendChartTitle>
        {
          !loading &&
          !noResult &&
          trendData?.length > 0 ? (
            <div className="chart-area">
              <TrendChart tabColor={tabColor}>
                <MapTrendChart
                  id={`prevalenceBar`}
                  data={chartData}
                  type="mapchart"
                  width={'100%'}
                  color={tabColor}
                  height={'100%'}
                />
              </TrendChart>
              <span>&#40; Month &#41;</span>
            </div>
          ) : (
            <strong className="not_found">No Data</strong>
          )
      }
      
      </div>
      {
        loading &&
        <CircleLoader isLoading={loading} bgColor="black"/>
      }
    </MapTrendPositiveStyled>
  );
};

export default MapTrendPositive;

const MapTrendPositiveStyled = styled.div`
  display: flex;
  flex:0 0 271px;
  justify-content: space-between;
  flex-direction: column;
  position:relative;
  background:#fff;
  color: #fff;
  .wrapper {
    padding: 15px 20px;
    height:100%;
    background: #212b34;
    border-radius: 2px;
  }
  .chart-area {
    position:relative;
    height:calc(100% - 23px);
      span {
      position:absolute;
      right:0;
      bottom:10px;
      display: inline-block;
      color: #717c87;
      font-size: 10px;
    }
  }
  .not_found {
    ${mixins.fontSet("#717C87","12px")};
    position:absolute;
    left: 50%;
    top: 48%;
    transform:translateX(-50%);
    padding-top:40px;
    display:block;
    background:url('${appHost}${IconNoData}')no-repeat 50% 0;
    text-align:center;
    color:#899097;
    font-weight:400;
  }
`;

const TrendChartTitle = styled.div`
  > strong {
    display: block;
    padding-bottom:8px;
    margin-bottom: 12px;
    border-bottom:1px solid rgba(53, 69, 84, 1);
    font-size: 14px;
    font-weight: 800;
  }
`;

const TrendChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px -26px;
  height: 100%;
  transform: translateX(-10px);
`;
