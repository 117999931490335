import React,{ useState } from 'react';
import Styled from 'styled-components';
import ArrowIcon from 'assets/svg/icon_arrow_right.svg';
import {LandingPagePopup} from './LandingPagePopup';
import { useCallback } from 'react';

export const LandingPageFooter = () => {
  const [popName, setPopupName] = useState("");
  const [open, setOpen] = useState(false);

  const handlePopOpen = useCallback(type => {
    if(type === '' || undefined) {
      setOpen(false)
    }
    if(type === "polisy") {
      setPopupName(type)
      setOpen(!open)
    } else if (type === "terms") {
      setPopupName(type)
      setOpen(!open)
    }
  }, [open])

  return (
    <>
      <Footer>
        <CopyRight>Copyright © Seegene Inc. All rights reserved.</CopyRight>
        <ButtonWrapper>
          {/* <PopupButton onClick={() => handlePopOpen("polisy")} title="Polisy">Seegene Privacy Policy</PopupButton> */}
          <PopupButton onClick={() => handlePopOpen("terms")} title="Terms" style={{background:'#00000077'}}>Terms of Service</PopupButton>
        </ButtonWrapper>
      </Footer>
      {
        open &&
        <LandingPagePopup PopState={popName} PopOpen={open} handlePopOpen={handlePopOpen}/>
      }
    </>
  )
}

const Footer = Styled.footer`
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  position:relative;
  z-index:10;
`;

const CopyRight = Styled.p`
  font-size:13px;


  position: absolute;
  right: 0px;
  top: 9px;
  color: #aaa;
`;

const ButtonWrapper = Styled.div`


  position: absolute;
  right: 0px;
`;

const PopupButton = Styled.button`
  padding: 0 33px 0 20px;
  width:auto;
  height:30px;
  background:transparent url('${ArrowIcon}')no-repeat 92% 50%;
  border: 1px solid rgba(112,112,112,0.5);
  border-radius:90px;
  color:#fff;
  font-size:12px;
  font-weight:800;
  &:not(:first-child){
    margin-left:10px;
  }
`;


