import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { confirmMail } from 'lib/api';

const CheckEmail = () => {
  const { history, location } = useReactRouter();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(()=>{
    (async () => {
      try {
        const res = await confirmMail(token);
        if(res.data.message === 'success') {
          history.push('/member/gomain?type=confirm');
        }else {
          alert("Authentication failure");
        }
      }catch(err) {
        alert(err);
      }
    })();
  },[]);

  return <></>;
}

export default CheckEmail;