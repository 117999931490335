import React from 'react';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';

const StyledInputText = styled.span`
  display:inline-block;
  width:100%;
  text-align:left;

  label {
    display:inline-block;
    ${mixins.fontSet('#111', '16px')};
    font-weight:700; 
  }

  input {
    height:40px;
    border:0;
    border-radius:2px;
    background:#fff;
    color:#111;
    &::placeholder{
      color:#a7adb4;
      font-size: 12px;
      font-weight:400;
    }
  }

  > strong {
    display:inline-block;
    margin: 4px 0 0 154px;
    color:#E91313;
    font-size: 14px;
    font-weight:400;
  }
`;

const InputText = ({id, label, name, type, errormsg, placeholder, disabled, autocomplete, value, onChange}) => {

  return (
    <StyledInputText errormsg={errormsg}>
      <label htmlFor={id}>{label ? label : name}</label>
      <input type={type} id={id} name={name} placeholder={placeholder} disabled={disabled} autoComplete={autocomplete ? '' : 'new-password'} value={value ? value : ''} onChange={onChange}/>
      {
        errormsg && 
        <strong style={{margin: 0}}>{errormsg}</strong>
      }
    </StyledInputText>
  )
}

export default InputText;