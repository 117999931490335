import React, {useEffect} from 'react';
import styled from 'styled-components';
import { CircleLoader } from 'components/loader';
import * as mixins from 'assets/styled/Mixin.styled';
import IconNoData from 'assets/svg/icon_positive_nodata.svg';
const { REACT_APP_APP_HOST: appHost } = process.env;

const MapPositive = ({tabColor, worldData, loading, noResult, setNoResult}) => {

  useEffect(() => {
    if (worldData && worldData?.filter(list => list.positiveCases > 0).length > 0) {
      setNoResult(false);
    } else {
      setNoResult(true);
    }
  }, [worldData])


  return (
    <MapPositiveStyled tabColor={tabColor}>
      <div className="wrapper">
        <strong>Positive Cases &amp; Rate by Country</strong>
        <div className="listItem">
          {worldData && worldData?.filter(list => list.positiveCases > 0).length > 0 ?
            worldData?.sort((a,b) => b.positiveCases - a.positiveCases).filter(list => list.positiveCases > 0).map((list, index) => (
              <dl key={`country-${index}`}>
                <dt>{list.nationalName}</dt>
                <dd>{list.positiveCases}<span>&#40;{`${list.positiveRate}%`}&#41;</span></dd>
              </dl>
            )) : (
              <strong className="not_found">No Data</strong>
            )
          }
        </div>
      </div>
        {
          loading &&
          <CircleLoader isLoading={loading} bgColor="black" /> 
        }
    </MapPositiveStyled>
  )
}

export default MapPositive;

const MapPositiveStyled = styled.div`
  position:relative;
  border-bottom:10px solid #f2f5f8;
  background:#fff;
  color:#fff;
  height:calc(100% - 439px);
  .wrapper {
    overflow:hidden;
    display:flex;
    flex-direction:column;
    padding:17px 20px;
    height:100%;
    background:#212B34;
    border-radius:3px;
    > strong {
      display:block;
      padding-bottom:8px;
      margin-bottom:15px;
      border-bottom:1px solid rgba(53, 69, 84, 1);
      color:#fff;
      font-size:14px;
      font-weight:800;
    }
    .listItem {
      position:relative;
      overflow-y:auto;
      display:flex;
      flex-direction:column;
      height:calc(100% - 58px);
      > dl {
        margin-bottom:5px;
        dt {
          display:inline-block;
          position:relative;
          padding-left:7px;
          color:#899097;
          font-size:12px;
          font-weight:400;
          &::before {
            content:'';
            position:absolute;
            left:0;
            top:50%;
            transform:translateY(-50%);
            width:1.5px;
            height:1.5px;
            background:#899097;
            border-radius:50%;
          }
        }
        dd {
          display:block;
          float:right;
          color:${({tabColor}) => tabColor};
          font-size:12px;
          font-weight:900;
          transition:color .3s ease;
          > span {
            display:inline-block;
            margin-left:5px;
            font-size:12px;
            font-weight:900;
          }
        }
      }
      // 임시 not_found
      > strong.not_found {
        ${mixins.fontSet("#717C87","12px")};
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        padding-top:40px;
        display:block;
        background:url('${appHost}${IconNoData}')no-repeat 50% 0;
        text-align:center;
        color:#899097;
        font-weight:400;
      }
    }
  }
`;