import React from 'react';
import styled from 'styled-components';
import Full_true from 'assets/svg/icon_map_full.svg'
import Full_false from 'assets/svg/icon_map_Shrink.svg'
import { useCallback } from 'react';
const { REACT_APP_APP_HOST: appHost} = process.env;

const FullPageButton = ({fullScreen, handleFullScreen, viewport, setViewport}) => {
  
  return (
    <FullScreenButton
      type="button"
      title="Full Screen"
      onClick={() => handleFullScreen(!fullScreen)}
      fullScreen={fullScreen}
    />
  )
}

export default FullPageButton;

const FullScreenButton = styled.button`
  width:34px;
  height:34px;
  background:#161E26 ${({fullScreen}) => !fullScreen ? `url(${appHost}${Full_true})no-repeat center` : `url(${appHost}${Full_false})no-repeat center`};
  border-radius:50px;
  box-shadow: 2px 2px 3px #0000002E;
`; 