import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CaseIcon from "assets/svg/icon_case.svg";
import { CircleLoader } from "components/loader";
import TotalRange from "../common/TotalRange";
const { REACT_APP_APP_HOST: appHost } = process.env;

const MapTotalPositive = ({ tabColor, totalData, loading }) => {

  return (
    <MapTotalStyled>
      <div className="wrapper">
        <TotalArea tabColor={tabColor}>
          {
            <>
              <li>
                <strong>Total Positive Cases</strong>
                <p>{totalData[0]?.positiveCases ? totalData[0].positiveCases : 0}</p>
              </li>
              <li>
                <strong>Rate</strong>
                <p>{totalData[0]?.positiveRate ? `${totalData[0]?.positiveRate}%` : '0%'}</p>
              </li>
            </>
          }
        </TotalArea>
        <TotalRange
          background={tabColor}
          value={totalData[0]?.positiveRate}
        />
      </div>
      {
        loading &&
        <CircleLoader isLoading={loading} bgColor="black" />
      }
    </MapTotalStyled>
  );
};

export default MapTotalPositive;

const MapTotalStyled = styled.div`
  position:relative;
  flex:0 0 160px;
  background:#fff;
  border-bottom: 10px solid #f2f5f8;
  color: #fff;
  .wrapper {
    padding: 20px 20px 24px;
    border-radius: 3px;
    background: #212b34;
  }
`;

const TotalArea = styled.ul`
  display: flex;
  overflow:hidden;
  > li {
    padding:8px 13px 15px;
    width: 100%;
    background: #283541;
    border-radius: 2px;
    text-align: center;

    strong {
      color: #fff;
      font-size: 14px;
      font-weight: 800;
    }

    p {
      padding-top: 10px;
      margin-top: 8px;
      border-top:1px solid #3d4f60;
      text-align: center;
      line-height: 1;
      color: ${({ tabColor }) => tabColor};
      font-size: 18px;
      font-weight: 900;
      letter-spacing:-0.2px;
      transition: color 0.3s ease;
    }

    &:first-child {
      margin-right: 5px;
      min-width: 150px;

      > p {
        padding-left: 10px;
        background: url("${appHost}${CaseIcon}") no-repeat 3px 90%;
      }
    }
  }
`;
