import React, { useEffect } from 'react';
import Styled, { css, keyframes } from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import img_floor_line from 'assets/images/img_floor_line.png';
import bg_bling from 'assets/images/bg_bling.png';


export const LandingPageAnimation = () => {

  return (
    <StyledAnimation>
      <div className="twinklebox">
        <span className="star-1 size-1"></span>
        <span className="star-2 size-2"></span>
        <span className="star-3 size-2"></span>
        <span className="star-4 size-2"></span>
        <span className="star-5 size-2"></span>
        <span className="star-6 size-3"></span>
        <span className="star-7 size-3"></span>
        <span className="star-8 size-3"></span>
        <span className="star-9 size-3"></span>
        <span className="star-10 size-4"></span>
        <span className="star-11 size-4"></span>
        <span className="star-12 size-4"></span>
        <span className="star-13 size-4"></span>
        <span className="star-14 size-5"></span>
        <span className="star-15 size-5"></span>
        <span className="star-16 size-5"></span>
        <span className="star-17 size-5"></span>
        <span className="star-18 size-5"></span>
        <span className="star-19 size-5"></span>
        <span className="star-20 size-5"></span>
        <span className="star-21 size-5"></span>
      </div>
      <div className="graph-1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 253">
          <defs>
            <linearGradient id="gradient-1" x1="0.5" y1="0.1" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#e14040"/></linearGradient>
            <linearGradient id="gradient-2" x1="0.5" y1="0.1" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#e1981a"/></linearGradient>
            <linearGradient id="gradient-3" x1="0.5" y1="0.1" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#e84b32"/></linearGradient>
            <linearGradient id="gradient-4" x1="0.5" y1="0.1" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#ff9539"/></linearGradient>
          </defs>
          <rect className="bar-1 gradient-1" width="15" x="0" y="0" />
          <rect className="bar-2 gradient-1" width="15" x="25" y="0" />
          <rect className="bar-3 gradient-1" width="15" x="50" y="0" />
          <rect className="bar-4 gradient-1" width="15" x="75" y="0" />
          <rect className="bar-5 gradient-1" width="15" x="100" y="0" />
          <rect className="bar-6 gradient-1" width="15" x="125" y="0" />
          <rect className="bar-7 gradient-2" width="15" x="150" y="0" />
          <rect className="bar-8 gradient-1" width="15" x="175" y="0" />
          <rect className="bar-9 gradient-1" width="15" x="200" y="0" />
          <rect className="bar-10 gradient-1" width="15" x="225" y="0" />
          <rect className="bar-11 gradient-3" width="15" x="250" y="0" />
          <rect className="bar-12 gradient-1" width="15" x="275" y="0" />
          <rect className="bar-13 gradient-1" width="15" x="300" y="0" />
          <rect className="bar-14 gradient-1" width="15" x="325" y="0" />
          <rect className="bar-15 gradient-4" width="15" x="350" y="0" />
          <rect className="bar-16 gradient-1" width="15" x="375" y="0" />
          <rect className="bar-17 gradient-1" width="15" x="400" y="0" />
          <rect className="bar-18 gradient-1" width="15" x="425" y="0" />
          <rect className="bar-19 gradient-1" width="15" x="450" y="0" />
          <rect className="bar-20 gradient-1" width="15" x="475" y="0" />
        </svg>
      </div>
      <div className="graph-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254 326">
          <defs>
            <linearGradient id="gradient-5" x1="0.5" y1="0.5" x2="0.5" y2="0.6" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#e1981a"/></linearGradient>
            <linearGradient id="gradient-6" x1="0.5" y1="0.5" x2="0.5" y2="0.6" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#e14040"/></linearGradient>
            <linearGradient id="gradient-7" x1="0.5" y1="0.5" x2="0.5" y2="0.6" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#110202"/><stop offset="1" stopColor="#ff9539"/></linearGradient>
          </defs>
          <rect className="bar-21 gradient-6" width="12" x="0" y="0" />
          <rect className="bar-22 gradient-6" width="12" x="22" y="0" />
          <rect className="bar-23 gradient-7" width="12" x="44" y="0" />
          <rect className="bar-24 gradient-6" width="12" x="66" y="0" />
          <rect className="bar-25 gradient-6" width="12" x="88" y="0" />
          <rect className="bar-26 gradient-6" width="12" x="110" y="0" />
          <rect className="bar-27 gradient-6" width="12" x="132" y="0" />
          <rect className="bar-28 gradient-5" width="12" x="154" y="0" />
          <rect className="bar-29 gradient-6" width="12" x="176" y="0" />
          <rect className="bar-30 gradient-6" width="12" x="198" y="0" />
          <rect className="bar-31 gradient-6" width="12" x="220" y="0" />
          <rect className="bar-32 gradient-6" width="12" x="242" y="0" />
        </svg>
      </div>
      <div className="graph-line">
        <svg viewBox="0 0 600 220">
          <defs>
            <filter id="line-shadow"><feGaussianBlur stdDeviation="5"/></filter>
            <radialGradient id="bling" cx="0.5" cy="0.5" r="0.499" gradientTransform="matrix(1.001, 0, 0, -1.002, -0.001, 1.12)" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#fff1d9"/><stop offset="0.067" stopColor="#dec59a"/><stop offset="0.131" stopColor="#977947"/><stop offset="0.185" stopColor="#5c4b2e"/><stop offset="0.244" stopColor="#3f3420"/><stop offset="0.309" stopColor="#282014"/><stop offset="0.384" stopColor="#16120b"/><stop offset="0.473" stopColor="#090704"/><stop offset="0.591" stopColor="transparent"/><stop offset="0.857"/><stop offset="1"/></radialGradient>
          </defs>
          <path className="shadowline" d="M 28 185 L 100 120, 153 150, 227 35, 275 114, 350 55, 405 96, 453 47, 506 73, 550 35" stroke="#f6f1dc" strokeWidth="6" fill="none" />
          <path className="line" d="M 28 185 L 100 120, 153 150, 227 35, 275 114, 350 55, 405 96, 453 47, 506 73, 550 35" stroke="#f6f1dc" strokeWidth="3.5" fill="none" />
            <circle className="border-1" cx="27" cy="186" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-1" cx="27" cy="186" r="4.8" fill="#f6f1dc" />
            <path className="bling-1" d="M245.56,267.869a41.087,41.087,0,1,0-41.09,41.148A41.12,41.12,0,0,0,245.56,267.869Z" />
            <circle className="border-2" cx="100" cy="122" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-2" cx="100" cy="122" r="4.8" fill="#f6f1dc" />
            <path className="bling-2" d="M261.634,275.917a49.124,49.124,0,1,0-49.127,49.2A49.164,49.164,0,0,0,261.634,275.917Z" />
            <circle className="border-3" cx="152" cy="150" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-3" cx="152" cy="150" r="4.8" fill="#f6f1dc" />
            <path className="bling-3" d="M267.386,278.8a52,52,0,1,0-52,52.077A52.042,52.042,0,0,0,267.386,278.8Z" />
            <circle className="border-4" cx="227" cy="37" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-4" cx="227" cy="37" r="4.8" fill="#f6f1dc" />
            <path className="bling-4" d="M254.473,272.332a45.543,45.543,0,1,0-45.547,45.611A45.58,45.58,0,0,0,254.473,272.332Z" />
            <circle className="border-5" cx="274.5" cy="114" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-5" cx="274.5" cy="114" r="4.8" fill="#f6f1dc" />
            <path className="bling-5" d="M290.206,290.224a63.41,63.41,0,1,0-63.415,63.5A63.461,63.461,0,0,0,290.206,290.224Z" />
            <circle className="border-6" cx="352" cy="57" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-6" cx="352" cy="57" r="4.8" fill="#f6f1dc" />
            <path className="bling-6" d="M245.56,267.869a41.087,41.087,0,1,0-41.09,41.148A41.12,41.12,0,0,0,245.56,267.869Z" />
            <circle className="border-7" cx="403" cy="94" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-7" cx="403" cy="94" r="4.8" fill="#f6f1dc" />
            <path className="bling-7" d="M280.686,285.457a58.65,58.65,0,1,0-58.654,58.737A58.7,58.7,0,0,0,280.686,285.457Z" />
            <circle className="border-8" cx="454" cy="47" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-8" cx="454" cy="47" r="4.8" fill="#f6f1dc" />
            <path className="bling-8" d="M245.56,267.869a41.087,41.087,0,1,0-41.09,41.148A41.12,41.12,0,0,0,245.56,267.869Z" />
            <circle className="border-9" cx="504" cy="73" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-9" cx="504" cy="73" r="4.8" fill="#f6f1dc" />
            <path className="bling-9" d="M267.386,278.8a52,52,0,1,0-52,52.077A52.042,52.042,0,0,0,267.386,278.8Z" />
            <circle className="border-10" cx="552" cy="35" r="5.5" stroke="#f6f1dc" strokeWidth="0.5" fill="transparent" />
            <circle className="star-10" cx="552" cy="35" r="4.8" fill="#f6f1dc" />
            <path className="bling-10" d="M264.554,277.38a50.584,50.584,0,1,0-50.588,50.659A50.625,50.625,0,0,0,264.554,277.38Z" />
        </svg>
      </div>
      <div className="lightbox">
        <div className="light-1"></div>
        <div className="light-2"></div>
        <div className="light-3"></div>
        <div className="light-4"></div>
        <div className="light-5"></div>
      </div>
      <div className="stripesbox"></div>
    </StyledAnimation>
  )
}

const barHeight = [50, 94, 127, 155, 127, 169, 146, 200, 169, 233, 125, 155, 178, 214, 253, 196, 161, 127, 82, 107, 159, 200, 177, 251, 286, 200, 165, 244, 220, 262, 200, 172];
const gradient = () => {
  let style=``;
  for(let i=1; i<=7; i++){
    style +=`
      .gradient-${i} {
        fill: url('#gradient-${i}');
      }
    `
  }
  return style;
}
const barAnimation= () => {
  let min = Math.ceil(-50);
  let max = Math.floor(50);
  let random, random2;
  let style=``;
  barHeight.forEach((value, index)=>{
    random = Math.floor(Math.random() * (max - min)) + min;
    random2 = Math.floor(Math.random() * (max - min)) + min;
    style+=`
      @keyframes glow${index+1} {
        0% {height:0}
        100% {height:${value}px}
      }

      @keyframes reset${index+1} {
        0% {height:${value}px}
        25% {height:${value+random}px}
        50% {height:${value}px}
        75% {height:${value+random2}px}
        100% {height:${value}px}
      }
    `
  });
  for(let i=1; i<=20; i++){
    style +=`
      .bar-${i} {
        animation:glow${i} 0.7s ${2+i/6}s forwards, reset${i} 30s 10s infinite;
      }
    `
  }
  for(let i=21; i<=32; i++){
    style +=`
      .bar-${i} {
        animation:glow${i} 0.7s 5.3s forwards, reset${i} 30s 10s infinite;
      }
    `
  }
  return style;
}
const circleAnimation= () => {
  let style=``;
  for(let i=1; i<=10; i++){
    style +=`
      .border-${i} {
        animation:fadeIn 2s ${2+i/2.5}s forwards;
      }
      .star-${i} {
        animation:fadeIn 2s ${2+i/2.5}s forwards;
      }
      .bling-${i} {
        animation:twinkle 5s ${5+i/2.5}s infinite;
      }
    `
  }
  return style;
}
const createStars = (top, right) => css`
  top:${top}; 
  right:${right};
  animation-delay:${Math.floor(Math.random() * (15 - 6)) + 6}s;
`
const StyledAnimation = Styled.div`
  ${mixins.absolute('top 50% left 50%')};
  width:1260px;
  height:100%;
  transform:translate(-50%, -50%);
  .twinklebox {
    z-index:0;
    position:fixed;
    width:100%;
    height:100%;
    &::after {
      ${mixins.afterBefore};
      ${mixins.absolute('top 340px right -28px')};
      width:800px;
      height:285px;
      background:url('${bg_bling}') no-repeat 30px 50px;
      opacity:0;
      animation:fadeIn 1s 6s forwards;
    }
    [class^="star-"] {
      display:inline-block;
      position:absolute;
      opacity:0;
      background: transparent radial-gradient(closest-side at 50% 50%, #FACD7F 0%, #D2AC6A 6%, #A68854 11%, #7E6740 14%, #5C4B2E 19%, #3F3420 24%, #282014 31%, #16120B 38%, #090704 47%, #020101 59%, #000000 86%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
    }
    .size-1 {
      width:139px;
      height:139px;
      animation:twinkle 10s infinite;
    }
    .size-2 {
      width:82px;
      height:82px;
      animation:twinkle 9s infinite;
    }
    .size-3 {
      width:66px;
      height:66px;
      animation:twinkle 8s infinite;
    }
    .size-4 {
      width:37px;
      height:37px;
      animation:twinkle 7s infinite;
    }
    .size-5 {
      width:25px;
      height:25px;
      animation:twinkle 6s infinite;
    }   
    .star-1 {${createStars('140px', '750px')}}
    .star-2 {${createStars('230px', '-15px')}}
    .star-3 {${createStars('665px', '165px')}}
    .star-4 {${createStars('670px', '1010px')}}
    .star-5 {${createStars('695px', '405px')}}
    .star-6 {${createStars('150px', '1075px')}}
    .star-7 {${createStars('412px', '43px')}}
    .star-8 {${createStars('195px', '235px')}}
    .star-9 {${createStars('300px', '525px')}}
    .star-10 {${createStars('302px', '123px')}}
    .star-11 {${createStars('235px', '405px')}}
    .star-12 {${createStars('270px', '425px')}}
    .star-13 {${createStars('400px', '488px')}}
    .star-14 {${createStars('180px', '188px')}}
    .star-15 {${createStars('244px', '473px')}}
    .star-16 {${createStars('290px', '595px')}}
    .star-17 {${createStars('160px', '325px')}}
    .star-18 {${createStars('488px', '630px')}}
    .star-19 {${createStars('206px', '-38px')}}
    .star-20 {${createStars('527px', '-48px')}}
    .star-21 {${createStars('743px', '471px')}}
  }
  .graph-1 {
    z-index:2;
    ${mixins.absolute('top 474px right 351px')};
    width:490px;
    height:253px;
    transform:translate(50%, -50%) scaleY(-1);
  }
  .graph-2 {
    z-index:1;
    ${mixins.absolute('top 369px right 309px')};
    width:254px;
    height:326px;
    transform:translate(50%, -50%) scaleY(-1);
    opacity:0.5;
  }
  [class^='bar-'] {
    position:relative;
    height:0;
  }
  ${gradient};
  ${barAnimation};
  .graph-line {
    z-index:10;
    ${mixins.absolute('top 379px right 41px')};
    width:600px;
    height:220px;
    mix-blend-mode:screen;
    svg {
      width:100%;
      height:100%;
      path.line,
      path.shadowline {
        box-shadow:0 0 5px #fffef9;
        stroke-dashoffset:1000;
        stroke-dasharray:1000;
        animation:dash 10s 2s forwards;
      }
      path.shadowline {
        filter:url('#line-shadow');
      }
      circle{
        opacity:0;
      }
      ${circleAnimation};
      [class^="bling"]{
        opacity:0;
        isolation:isolate;
        mix-blend-mode:screen;
        fill:url('#bling');
      }
      .bling-1 {
        transform:translate(-177px, -92px)
      }
      .bling-2 {
        transform:translate(-110px, -165px)
      }
      .bling-3 {
        transform:translate(-63px, -140px)
      }
      .bling-4 {
        transform:translate(19px, -246px)
      }
      .bling-5 {
        transform:translate(50px, -193px)
      }
      .bling-6 {
        transform:translate(146px, -220px)
      }
      .bling-7 {
        transform:translate(183px, -206px)
      }
      .bling-8 {
        transform:translate(250px, -229px)
      }
      .bling-9 {
        transform:translate(291px, -219px)
      }
      .bling-10 {
        transform:translate(335px, -254px)
      }

    }
  }
  .stripesbox {
    z-index:0;
    ${mixins.absolute('top 340px right -15px')};
    width:730px;
    height:500px;
    background:url('${img_floor_line}') no-repeat 50% / 730px auto;
    animation:stripes 2s both;
  }
  .lightbox {
    z-index:10;
    ${mixins.absolute('top 440px right -270px')}
    width:1217px;
    height:285px;
    mix-blend-mode: screen;
    transform-origin:center;
    transform:scale(0, 0);
    animation:light 5s forwards;
    .light-1 {
      ${mixins.absolute('bottom 0 left 50%')};
      width: 730px;
      height: 204px;
      background: transparent radial-gradient(closest-side at 50% 50%, #DD9C33 0%, #D29430 10%, #A17225 23%, #76531B 36%, #513912 54%, #33240B 69%, #1C1406 87%, transparent 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
      opacity: 0.72;
      transform:translateX(-50%);
    }
    .light-2 {
      ${mixins.absolute('bottom 43px left 50%')};
      width: 1217px;
      height: 201px;
      background: transparent radial-gradient(closest-side at 50% 50%, #D9C47D 0%, #9F7913 8%, #CB8E1F 16%, #DF6E20 23%, #D35600 28%, #A24200 35%, #763000 43%, #522100 51%, #341500 59%, #1D0B00 67%, #0C0500 77%, #030100 87%, transparent 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
      opacity: 1;
      transform:translateX(-50%);
    }
    .light-3 {
      ${mixins.absolute('bottom 104px left 50%')};
      width: 1062px;
      height: 81px;
      background: transparent radial-gradient(closest-side at 50% 50%, #FFB700 0%, #FFA400 1%, #FF7300 3%, #FF6800 3%, #D35600 10%, #A24200 19%, #763000 29%, #522100 38%, #341500 49%, #1D0B00 59%, #0C0500 71%, #030100 83%, transparent 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
      opacity: 1;
      transform:translateX(-50%);
    }
    .light-4 {
      ${mixins.absolute('bottom 56px left 50%')};
      width: 779px;
      height: 109px;
      background: transparent radial-gradient(closest-side at 50% 50%, #F5EAD8 0%, #E6C898 13%, #DAB478 27%, #C79749 41%, #76531B 56%, #120C03 74%, #020100 89%, transparent 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
      opacity: 0.46;
      transform:translateX(-50%);
    }
    .light-5 {
      ${mixins.absolute('bottom 37px left 50%')};
      width: 549px;
      height: 230px;
      background: transparent radial-gradient(closest-side at 50% 50%, #F5EAD8 0%, #E6C898 13%, #C79749 30%, #76531B 44%, #120C03 63%, #020100 89%, transparent 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: screen;
      opacity: 0.83;
      transform:translateX(-50%);
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  @keyframes twinkle {
    0% {
      opacity:0;
    }
    50% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  @keyframes stripes {
    0% {
      transform:scaleY(0)
    }
    100% {
      transform:scaleY(1)
    }
  }
  @keyframes light {
    0% {
      transform:scale(0, 0);
    }
    30% {
      transform:scale(1, 0.01);
    }
    100% {
      transform:scale(1, 1);
    }
  }
`


