import styled from 'styled-components';
import * as mixins from '../../assets/styled/Mixin.styled';
import WARN from '../../assets/svg/icon_warn.svg';
import Register from '../../assets/svg/icon_register.svg'
import Plus from '../../assets/svg/icon_interest_add.svg';
import Checked from '../../assets/svg/icon_check.svg';

function getBackground() {
  const colorList = {
    RP: "#98E3FF",
    GI: "#FFCA7D",
    STI: "#FFCDCD",
    HPV: "#E4C7F9",
    TB: "#CAD3FE",
    DR: "#B6F4E4",
    Meningitis: "#BCEDA1"
  }

  let background = '';
  for (let i in colorList) {
      background += `
        &.${i} input[type=checkbox]:checked + label{
          background: ${colorList[i]};
      }
    `
  }
  return background;
}


export const StyledRegister = styled.div`
  width:100%;
  .centerwrap{
    text-align:center;
    .layoutBox{
      strong{
        display:block;
        padding-top:120px;
        margin-bottom:20px;
        color:#354554;
        font-size:26px;
        font-weight:800;
        background: url('${Register}')no-repeat 50% 0;
      }
      .warn {
        display:inline-block;
        position:relative;
        margin-bottom:40px;
        text-align:center;
        color:#59697A;
        font-size:16px;
        font-weight:400;
        &:before{
          ${mixins.afterBefore()};
          ${mixins.absolute('top 5px left 40px')};
          width:16px;
          height:16px;
          background:url('${WARN}')no-repeat;
        }
      }
    }
    
    
    .boardlist{
      margin: 0 -100% 45px;
      .checkBox{
        display:inline-block;
        margin-right:10px;
        font-size:16px;
        font-weight:400;

        input[type=checkbox] + label{
          z-index:0;
          padding-left:17px;
          width:132px;
          height:40px;
          background:#fff;
          border: 1px solid #eee;
          border-radius:30px;
          vertical-align:top;
          line-height:40px;
          text-align:left;
          color:#a7adb4;
          
          
          &::before{
            top:50%;
            left:100px;
            transform:translateY(-50%);
            margin-top:0;
            width:16px;
            height:16px;
            background:url('${Plus}')no-repeat;
            border:none;
          }

          &::after{
            display:none;
          }
        }

        input[type=checkbox]:checked + label{
          color:#000;
        }

        input[type=checkbox]:checked + label::before{
          background:url('${Checked}')no-repeat;
        }
        ${getBackground()};
      }
    }
    .error-field{
      margin-bottom:8px;
      height:20px;
      .error-message{
        margin:0 0 0 auto;
        width:169px;
        line-height:1;
        text-align:left;
        color:#e91313;
        font-size:14px;
        font-weight:400;
      }
    }
    

    .button-wrapper{
      display:flex;
      justify-content:space-between;
      align-items:center;
      
      > button {
          width: 169px;
      }
    }
    .skip-text{
      margin-top:25px;
      text-align: center;
      color:#5d5d5d;
      font-weight:400;
    }
  }
`;

          
        