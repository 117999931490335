import React  from 'react';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import Icon_arrow_down from 'assets/svg/icon_arrow_down.svg';
const { REACT_APP_APP_HOST: appHost} = process.env;

const StyledInputSelect = styled.span`
  display:block;
  width: 100%;
  select {
    display:block;
    position:relative;
    width:100%;
    height:40px;
    padding:0 8px;
    background:url('${appHost}${Icon_arrow_down}') #fff no-repeat 95% 50%;
    border:0;
    border-radius:2px;
    color:#a7adb4;
    font-size:12px;
    font-weight:400;
  }
`;
//사용하는곳이 없어서 조금 수정했습니다.
const InputSelect = ({id, name, children, width, height, onChange, value})  => {
    return (
        <StyledInputSelect width={width} height={height}>
            <label htmlFor={id}>{name}</label>
            <select id={id} name={name} onChange={onChange} value={value}>
                {children}
            </select>
        </StyledInputSelect>
    );
}

export default InputSelect;