import React, { useRef, useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//다크모드
// am4core.useTheme(am4themes_dark);

//animated
am4core.useTheme(am4themes_animated);

//license
am4core.options.commercialLicense = true;

const MapTrendChart = ({ id, data, header, showTitle, width, height, color}) => {

  const chartRef = useRef(null);
  let chart;
  useEffect(() => {
    //필수체크 하자...
    if(!data || data.length == 0) {
      return;
    }

    if(!header || !header.x || !header.y) {
      //TODO
      // console.error("'header' is required")
    }

    chart = am4core.create(id, am4charts.XYChart);

    // Add data
    chart.data = data;
    // prepareParetoData();

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.3; //한 카테고리 안의 시작 막대 시작 위치
    categoryAxis.renderer.cellEndLocation = 0.7; //한 카테고리 안의 마지막 막대 끝 위치
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.cursorTooltipEnabled = false;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fontSize = 10;
    
    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.stroke = am4core.color(color);
    series.strokeWidth = 1.5;
    series.dataFields.valueY = "count";
    series.dataFields.categoryX = "month";

    // Bullet
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.fill = am4core.color(color);
    bullet.stroke = am4core.color('#212B34');
    bullet.strokeWidth = 2.5;
    bullet.circle.scale = 1;
    bullet.fillOpacity = 0.95;

    // Hover Bullet
    let bullet2 = series.bullets.push(new am4charts.CircleBullet());
    
    if(color !== '#E91313') {
      bullet2.fill = am4core.color(color);
    } else {
      bullet2.fill = am4core.color("#fff");
    }
    bullet2.circle.scale = 1.4;
    bullet2.strokeWidth = 0;
    bullet2.fillOpacity = 0;

    let hoverBullet = bullet2.states.create("hover");

    if(color !== '#E91313') {
      hoverBullet.properties.fillOpacity = 0.2;

    } else {
      hoverBullet.properties.fillOpacity = 0.12;
    }

    /* bullet.events.on('over', function(e) {
      console.log(e.target.dataItem)
    }) */

    // Line Color

    valueAxis.renderer.labels.template.fill = "rgba(255,255,255,0.5)";
    valueAxis.renderer.grid.template.stroke = am4core.color("rgba(53, 69, 84, 1)"); //x축
    valueAxis.renderer.grid.template.strokeOpacity = 1;
    categoryAxis.renderer.labels.template.fill = "rgba(255,255,255,0.5)";
    categoryAxis.renderer.grid.template.stroke = am4core.color("rgba(53, 69, 84, 1)"); //y축
    categoryAxis.renderer.grid.template.strokeOpacity = 1;

    // Cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";
    
    chartRef.current = chart;
    
    return () => {
      chart.dispose();
    };

  }, [data]);

  return (
    <>
      <div id={id} style={{ width: width, height:height }}></div>
    </>
  )
}

MapTrendChart.defaultProps = {
  showTitle: false
};

export default MapTrendChart;
