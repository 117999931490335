import React from 'react';
import styled from 'styled-components';
import icon_error from '../assets/svg/icon_error.svg'
import * as mixins from '../assets/styled/Mixin.styled';
import { ResetStyle } from 'assets/styled/Reset.styled';

function reset() {
  localStorage.removeItem('variables');
  localStorage.removeItem('_regionsite');
  localStorage.removeItem('_products');
  localStorage.removeItem('_productline');
  window.location.href = "/";
}

const NotFoundPage = () => (
  <>
  <ResetStyle/>
  <Container>
    <div className="wrap">
      <strong>Page not found</strong>
      <p>We can't find the page that<br/>you're looking for.</p>
      <button onClick={reset}>
        GO HOME
      </button>
    </div>
  </Container>
  </>
);

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100vw;
  height:100vh;
  background:#F2F5F8;
  .wrap {
    padding:60px 120px;
    width:500px;
    background:#fff;
    text-align:center;
    > strong {
      position:relative;
      display:block;
      padding-top:165px;
      color:#354554;
      font-size:30px;
      font-weight:800;
      &:after{
        content:'';
        position:absolute;
        top: 0;
        left:50%;
        transform:translateX(-50%);
        width:150px;
        height:150px;
        border-radius:50%;
        background:#F2F5F8 url('${icon_error}')no-repeat center;
      }
    }

    p {
      margin-bottom:40px;
      color:#a7adb4;
      font-size:18px;
      font-weight:400;
    }

    button {
      ${mixins.btnNavy('100%', '50px')};
      font-size:18px;
      font-weight:800;
    }
  }
`;


export default NotFoundPage;
