import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configure from './store/configure';
import transit from 'transit-immutable-js';
import './index.css';
import 'assets/styled/font.css';
import ContainerApp from './ContainerApp';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';
ReactGA.initialize('278737735');
ReactGA.pageview(window.location.pathname + window.location.search);

const preloadedState = window.__PRELOADED_STATE__ && transit.fromJSON(window.__PRELOADED_STATE__);
const store = configure(preloadedState);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <CookiesProvider>
        {/*<React.StrictMode>*/}
          <ContainerApp />
        {/*</React.StrictMode>*/}
      </CookiesProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

