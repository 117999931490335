import React, { useRef, useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//다크모드
// am4core.useTheme(am4themes_dark);

//animated
am4core.useTheme(am4themes_animated);

//license
am4core.options.commercialLicense = true;

const ColumnBarChart = ({ id, data, seriesData, header, showTitle, width, height, isNoResult, handleLoading, isRound, isStack, isExternalLegend, isLabelBullet, isScrollbar, xMinGridDistance }) => {

  const chartRef = useRef(null);

  useEffect(() => {
  
    if(!header) {
      console.error("'header' is required")
    }

    handleLoading('start');

    let chart = am4core.create(id, am4charts.XYChart);

    if(!data) {
      return;
    }

    if(data.length == 0 && isNoResult) {
      const container = chart.chartContainer.createChild(am4core.Container);
      container.align = 'center';
      container.isMeasured = false;
      container.x = am4core.percent(50);
      container.horizontalCenter = 'middle';
      container.y = am4core.percent(50);
      container.verticalCenter = 'middle';
      container.layout = 'vertical';
    
      const lebel = container.createChild(am4core.Label);
      lebel.text = 'There is no experimental result.';
      lebel.fill = am4core.color("#a7adb4");
      lebel.textAlign = 'middle';
      lebel.maxWidth = 300;
      lebel.wrap = true;
      handleLoading('end');
      return;
    }

    // loading end
    chart.events.on('ready', () => {
      handleLoading('end');
    });

    // this creates initial fade-in
    chart.hiddenState.properties.opacity = 0; 
    
    // Add data
    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = header.category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.stroke = am4core.color("rgba(0, 0, 0, 0.3)");
    categoryAxis.renderer.minGridDistance = xMinGridDistance || 20;
    if(id==='uploadBar'){
      categoryAxis.renderer.cellStartLocation = 0.3;
      categoryAxis.renderer.cellEndLocation = 0.7;
    }else{
      categoryAxis.renderer.cellStartLocation = 0.4; //한 카테고리 안의 시작 막대 시작 위치
      categoryAxis.renderer.cellEndLocation = 0.6; //한 카테고리 안의 마지막 막대 끝 위치
    }

    if(isScrollbar){
      //categoryAxis.start = 0.3;
      categoryAxis.keepSelection = true;
    }
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.stroke = am4core.color("rgba(0, 0, 0, 0.3)");

    if(id==='uploadBar' && data.length > 0){
      valueAxis.max = Math.max(...data.map(a=>Object.values(a).filter(a=>typeof a === 'number').reduce((a, b) => a + b, 0))) * 1.2;
    }

    if(isScrollbar){
      // Add scrollbar
      chart.scrollbarX = new am4charts.XYChartScrollbar();
    }
    
    // Create series
    header && header.series && header.series.forEach(s => {
      if(seriesData && !seriesData.includes(s.value)){
        return;
      }
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = s.value;
      series.dataFields.categoryX = s.category;
      series.tooltip.label.textAlign = "middle";
      series.tooltip.pointerOrientation = "down";
      series.tooltip.dy = -5;
      if(isStack){
        series.stacked = true;
        series.name = s.value;
        series.fill = s.color;
        series.stroke = s.color;
      }
      
      series.columns.template.tooltipText = "{categoryX}\n[bold]{valueY}[/]";
      if(isRound){
        series.columns.template.column.fillOpacity = 0.8;
        series.columns.template.fillOpacity = .8;
        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
      }
      series.columns.template.tooltipY = 0;

      if(isScrollbar){
        chart.scrollbarX.series.push(series);
        chart.scrollbarX.parent = chart.bottomAxesContainer;
      }

      if(isLabelBullet){
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.verticalCenter = "bottom";
        labelBullet.label.dy = -3;
        labelBullet.label.fontSize = 10;
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
      }
    });

    if(isExternalLegend){
      /* Create legend */
      let legend = new am4charts.Legend();
      legend.fontSize = 12;
      legend.fontWeight = 800;
      legend.contentAlign = "right";
      legend.markers.template.width = 10;
      legend.markers.template.height = 10;
      legend.labels.template.fill = am4core.color('#a7adb4');
      legend.valueLabels.template.fill = am4core.color('#a7adb4');
      /* Create a separate container to put legend in */
      var legendContainer = am4core.create("externalLegend", am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      chart.legend = legend;
      chart.legend.parent = legendContainer;
    }

    chartRef.current = chart;
    
    return () => {
      chart.dispose();
    };

  }, [data]);

  return (
    <>
      <div id={id} style={{ width: width, height:height }}></div>
    </>
  )
}

ColumnBarChart.defaultProps = {
  showTitle: false
};

export default ColumnBarChart;
