import React,{ useState } from 'react';
import styled from 'styled-components';
import PlusIcon from 'assets/svg/icon_map_+.svg';
import MinusIcon from 'assets/svg/icon_map_-.svg';
import { useEffect } from 'react';
import { useCallback } from 'react';
const { REACT_APP_APP_HOST: appHost} = process.env;

const ViewController = ({viewport,setViewport}) => {

  const handleZoomLevel = useCallback(value => {
    if (value === 0) return;
    if (value > 0) {
      setViewport(Object.assign({}, {
        ...viewport,
        zoom: value
      }))
    }
  }, [viewport])

  return (
    <StyledViewController>
      <button type="button" onClick={() => handleZoomLevel(viewport.zoom - 1)} className="Shrink" title="Shrink" />
      <button type="button" onClick={() => handleZoomLevel(viewport.zoom + 1)} className="Expansion" title="Expansion" />
    </StyledViewController>
  )
}

export default ViewController;

const StyledViewController = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction:column;
  padding:8px 7px;
  width:34px;
  height:62px;
  background:#161E26;
  border-radius:50px;

  > button {
    width:20px;
    height:20px;
    background:url('${appHost}${PlusIcon}')no-repeat center;
    border-radius:50%;
    &.Shrink {
      background-image:url('${appHost}${MinusIcon}');
    }
  }
`;