import { createGlobalStyle } from 'styled-components';
import * as mixins from './Mixin.styled';
export const LayoutStyle = createGlobalStyle`
  body {
      #root {
        display:flex;
        flex-direction:column;
        overflow:hidden;
        width:100%;
        height:100%;
        min-width:1260px;
        min-height:700px;
      }
      .container {
        display:flex;
        flex-direction:column;
        flex:1;
        position:relative;
        width:100%;
        background:#f2f5f8;
      }

      /* 2020.12.17 LandingPage Style */
      .landing_area {
        ${mixins.flex('space-between','space-between')};
        flex-direction:column;
        height:100%;
        min-height:100vh;
        padding:31px 50px 40px;
        background:#000;
        .contentsarea{
          // width:1260px;
          // margin:0 auto;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      .memberarea {
        ${mixins.flex('flex-start','flex-start')};
        flex-direction:column;

        .contents {
          ${mixins.flex('center','flex-start')};
          flex:1;
          width:100%;
          .centerwrap {
            padding-bottom:50px;
            margin: 70px auto 0;
            max-width:350px;
            height:100%;
          }
          .layoutBox{
            margin: 0 -50%;
          }
        }
      }
      .signuparea {
        ${mixins.flex('flex-start','center')};
        flex-direction:column;
        padding-top:30px;
      }
  }
`;