import React from 'react';
import styled from 'styled-components';


const CircleLoader = ({isLoading, bgColor}) => {
  return (
    <Container Loading={isLoading} bgColor={bgColor} className="circle-loader">
      <div className="circle-wrapper">
        <span />
        <span />
        <span />
      </div>
    </Container>
  )
}

export default CircleLoader;

const Container = styled.div`
  display:${({Loading}) => Loading ? "block" : "none"};
  position:absolute;
  z-index:1000;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background: ${({bgColor}) => bgColor === 'black' ? "rgba(33,43,52, 0.95)" : "rgba(255,255,255,0.95)"};
  .circle-wrapper {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    > span {
      display:inline-block;
      width:5px;
      height:5px;
      background:#DEDEDE;
      border-radius:50%;
      transition: all 0.3s ease;
      &:nth-child(1) {
        animation: bounce 1.1s ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: bounce 1.1s ease-in-out 0.33s infinite;
      }
      &:nth-child(3) {
        animation: bounce 1.1s ease-in-out 0.66s infinite;
      }
      &:not(:first-child){
        margin-left:3px;
      }
    }
  }

  @keyframes bounce{
  0%, 75%, 100%{
    -webkit-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px);
    opacity:0.5;
    height:4px;
  }

  25%{
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity:1;
    height:5px;
    background:#E56A4B;
  }
}
`;
