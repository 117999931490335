import React, { useReducer, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useCookies } from 'react-cookie';
import * as baseActions from 'store/modules/base';
import { useKeypress } from "lib/common";
import TeremsField from 'components/TermsField';
import {StyledLogin} from './Login.styled'
import {Link} from "react-router-dom";
import { REMEMBER_ME } from '../../constants';

const initLoginForm = {
  userId: '',
  password: '',
  rememberMe: false,
  loading: false,
  confirm: true,
  message: ''
}

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {...initLoginForm}
    }
    case 'login': {
      return { ...state, userId: '', password: '' }
    }
    case 'remember': {
      return { ...state, userId: action.value, rememberMe: true }
    }
    default: {
      return { ...state, [action.type]: action.value }
    }
  }
}

const dispatcherHandler = d => e => {
  if(e && e.target && typeof e.target === 'object') {
    if(!e.target.name) throw new Error("'name' element is empty");

    const {name, type, value, checked} = e.target;
    if(type === 'checkbox') {
      d({type: name, value: checked})
    }else if(type === 'radio') {
      d({type: name, value: checked})
    }else {
      d({type: name, value})
    }
  }
}

const LoginPage = ({lnb}) => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const [login, dispatchLogin] = useReducer(loginFormReducer, initLoginForm);
  const userIdRef = useRef(null);
  const passwordRef = useRef(null);
  const [cookies, ] = useCookies(['skipReg']);
  const [agreeState, setAgreeState] = useState(false);
  
  useEffect(() => {
    const rememberMe = localStorage.getItem(REMEMBER_ME);
    if(rememberMe) {
      dispatchLogin({type: 'remember', value: rememberMe});
    }
  },[])

  useKeypress('Escape', () => {
    // alert('어디로 가야하오.. 일단 / 로 push');
    history.push("/");
  });

  const handleLogin = e => {
    dispatchLogin({type: 'message', value: ''});
    const {userId, password, rememberMe} = login;

    if(!userId) {
      userIdRef.current.focus();
      dispatchLogin({type: 'message', value: 'Enter your ID'});
      return;
    }
    if(!password) {
      passwordRef.current.focus();
      dispatchLogin({type: 'message', value: 'Enter your password'});
      return;
    }
    if(!agreeState) {
      dispatchLogin({type: 'message', value: 'Please agree to the terms of service.'});
      return;
    }

    const BaseActions = bindActionCreators(baseActions, dispatch);
    // this.handleDisabled(true);
    BaseActions.login(userId, password)
      .then((res) => {
        if(!res.data.approved){
          dispatchLogin({type: 'message', value: 'Please wait for approval.'});
          return;
        }
        if(rememberMe) {
          localStorage.setItem(REMEMBER_ME, userId);
        }
        dispatchLogin({type: 'login'});

        if(!res.data.certificated){ 
          // console.log('비 인증 유저: 로그인 후, ‘인증요청 페이지＇ 이동');
          history.push("/member/verify");
        }
        // todo: 한달 쿠키가 유효한지도 체크해야함...
        else if(!res.data.registered && cookies.skipReg !== 'Y') {
          // console.log('한달 쿠키 유효여부 체크');
          // console.log('관심분야 미등록 유저: 로그인 후, ‘관심분야 등록 권유‘ 이동');
          history.push("/member/register");
        }
        else {
          // console.log('인증 유저: 로그인 후, ‘Home Dashboard’ 이동');
          history.push("/app/analysis");
        }
      })
      .catch((error) => {
        if ( error.response.status == 403 ) {
          dispatchLogin({type: 'message', value: 'Your account is currently awaiting approval from the system administrator. Upon approval, we will notify you via your registered email address.'});
        } else {
          dispatchLogin({type: 'message', value: 'Check Your ID or password'});
        }
        // this.handleDisabled(false);
      });
  }

  const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
		  e.target.blur();
		  handleLogin(e);
		}
  }

  return(
    <>
      <StyledLogin lnb={lnb}>
        <div className="contents">
          <div className="centerwrap">
            <div className="loginbox">
                <TeremsField agreeState={(state)=> setAgreeState(state)} value={agreeState} errorMsg={login.message==="Please agree to the terms of service."}/>
                <span className="id-field">
                  <label>ID</label>
                  <input 
                    type="text" 
                    name="userId"
                    placeholder="username" 
                    title="Write down your Username"
                    value={login.userId}
                    ref={userIdRef}
                    onChange={dispatcherHandler(dispatchLogin)}
                    onKeyPress={handleKeyPress} 
                    />
                </span>
                <span className="pw-field">
                  <label>Password</label>
                  <input 
                    type="password" 
                    name="password"  
                    title="Write down your Password"
                    ref={passwordRef}
                    onChange={dispatcherHandler(dispatchLogin)} 
                    onKeyPress={handleKeyPress}
                    />
                </span>
                <p className="remember">
                  <input 
                    id="rememberMe"
                    type="checkbox" 
                    name="rememberMe" 
                    checked={login.rememberMe}
                    onClick={dispatcherHandler(dispatchLogin)} 
                    onChange={e => {
                      //e.preventDefault()
                    }} />
                  <label htmlFor="rememberMe">Remember me</label>
                </p>
                {
                  login.message && 
                  (<div className="error-field">
                    <p className="error-message">{login.message}</p>
                  </div>)
                }
                <div className="button-wrapper">
                  <button 
                    className="btn-orange" 
                    type="button" 
                    title="Login"
                    onClick={handleLogin}
                    >LOGIN</button>
                    <Link to="/signup/agree">
                      <button type="button" title="Sign Up">SIGN UP</button>
                    </Link>
                </div>
                <div className="reset-pw">
                  <Link to="/member/change" >Reset password</Link>
                </div>
            </div>
          </div>
        </div>
      </StyledLogin>
    </>
  )
}

export default LoginPage;
