import React, { useState } from "react";
import styled from "styled-components";
import * as mixins from "assets/styled/Mixin.styled";

const InputRadioBox = ({ text, value, name, pathogenFullName, checked, background, onChange }) => {
  return (
    <StyledMapCheckItem background={background}>
      <input type="radio" id={value} name={name} checked={checked} value={value} onChange={onChange}/>
      <label htmlFor={value} title={pathogenFullName}>{text}</label>
    </StyledMapCheckItem>
  );
};

const StyledMapCheckItem = styled.span`
  display: inline-block;
  input[type="radio"] + label {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: 1px solid #3f454b;
    border-radius: 20px;
    line-height: 21px;
    color: #a7adb4;
    font-size: 12px;
    font-weight: 800;
    transition: all 0.3s ease;
    &::before, ::after{
      display:none;
    }
  }

  input[type="radio"]:checked + label {
    color: #fff;
    background: ${({ background }) => (background ? background : "#3F454B")};
    border-color: ${({ background }) => (background ? background : "#3F454B")};
  }
`;

export default InputRadioBox;
