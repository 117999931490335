import { createGlobalStyle } from 'styled-components';
import * as mixins from './Mixin.styled';

export const ResetStyle = createGlobalStyle`
  * {
    margin:0; 
    padding:0;
    outline:none;
    box-sizing:border-box;
  }
  html {
    width:100%;
    height:100%;
  }
  body {
    width:100%;
    height:100%;
    line-height:1.5; 
    ${mixins.fontSet('#666', '14px')};
    word-break:keep-all; 
    word-wrap:break-word;
  }
  ::after, ::before {
    -webkit-text-size-adjust:none; 
    -webkit-overflow-scrolling:touch; 
    -webkit-box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    box-sizing:border-box;
  }
  ::-moz-selection {
    color: #fff;
    background: #3978f8;
  }
  ::selection {
    color: #fff;
    background: #3978f8;
  }
  ul, ol, li {list-style:none}
  a {
    color:inherit; 
    text-decoration:none; 
    cursor:pointer;
  }
  em, address, i {font-style:normal}
  dt, strong, em, b {font-weight:800}
  img, input, textarea, button {vertical-align:middle};
  hr {
    font-size:0; 
    line-height:0;
    border:0;
  }
  pre {
    white-space:pre-line;
    word-break:break-all;
  }
  img {border:0}
  input, textarea{
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
    border-radius: 0;
    &::-webkit-input-placeholder {
      ${mixins.placeHolder()};
    }
    &:-ms-input-placeholder {
      ${mixins.placeHolder()};
    }
    &::placeholder {
      ${mixins.placeHolder()};
    }
  }
  label {
    display:block;
    margin-bottom:5px;
    text-align:left;
    color:#111;
    font-size:14px;
    font-weight:400;
  }
  input[type=radio] {
    ${mixins.absolute('top 0 left 0')};
    width: 0;
    height: 0;
    border: 0;
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
    & + label {
      display:inline-block;
      position:relative;
      padding-left:25px;
      vertical-align:middle;
      line-height:30px;
      cursor:pointer;
      &::before {
        ${mixins.afterBefore}
        ${mixins.absolute('top 50% left 0')};
        width:20px;
        height:20px;
        margin-top:-10px;
        border:1px solid #999;
        border-radius:50%;
      }
      &::after {
        content:"";
        display:none;
        ${mixins.absolute('top 50% left 5px')};
        width:10px;
        height:10px;
        margin-top:-5px;
        background:#999;
        border-radius:50%;
      }
    }
    &:checked {
      & + label::after {
        display:block;
      }
    }
    &:disabled {
      & + label {
        color:#aaa;
      }
      & + label::before {
        border-color:#aaa;
      }
    }
  }
  input[type=checkbox] {
    ${mixins.absolute('top 0 left 0')};
    width: 0;
    height: 0;
    border: 0;
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
    & + label {
      display:inline-block;
      position:relative;
      padding-left:25px;
      vertical-align:middle;
      line-height:30px;
      cursor:pointer;
      &::before {
        ${mixins.afterBefore}
        ${mixins.absolute('top 50% left 0')};
        width:20px;
        height:20px;
        margin-top:-10px;
        border:1px solid #999;
      }
      &::after {
        content:"";
        display:none;
        ${mixins.absolute('top 50% left 5px')};
        width:10px;
        height:10px;
        margin-top:-5px;
        background:#999;
      }
    }
    &:checked {
      & + label::after {
        display:block;
      }
    }
    &:disabled {
      & + label {
        color:#aaa;
      }
      & + label::before {
        border-color:#aaa;
      }
    }
  }
  input[type=text], input[type=password], input[type=file], input[type=email] {
    display:block; 
    width:100%;
    height:40px;
    padding:0 10px;
    background:#fff; 
    border:0; 
    border-radius:0; 
    color:#666;
    -webkit-appearance:none;
    -moz-appearance:none; 
    appearance:none
  }
  textarea {
    display:block; 
    overflow-x:hidden; 
    overflow-y:auto; 
    resize:none; 
    background:#fff; 
    color:#666;
  }
  select {
    border-radius:0; 
    color:#666; 
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
    &::-ms-expand {display:none}
  }
  button {
    width:100%;
    height:50px;
    border-radius:2px;
    background: #354554;
    color:#fff;
    font-size:16px;
    font-weight:800;
    border:0; 
    cursor:pointer;
    &.btn-orange{
      background:#e56a4b;
    }
    &.btn-white {
      background:#fff;
      border:1px solid #E4E7EC;
      color:#354554;
    }
    &.btn-gray{
     background: #A7ADB4;
    }
  }
  fieldset {
    margin:0; 
    padding:0; 
    border:0; 
    background:0;
  }
  fieldset legend {
    visibility:hidden; 
    font-size:0; 
    line-height:0;
  }
  table {border-collapse:collapse}
  caption {
    visibility:hidden; 
    width:0; 
    height:0; 
    overflow:hidden; 
    font-size:0
  }
  body, button, input, textarea, select, pre {
    font-family:Lato, Dotum, Arial, sans-serif;
    letter-spacing:-0.02px;
    &:not(button){
      font-weight:200;
    }
  }
`;