import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as baseActions from 'store/modules/base';

class LoginPage extends Component {
  state = {
	  userId: '',
	  password: '',
	  chkremeber: false,
	  loading: false,
	  confirmyn: true,
	  message: ''
  }

  handleChange = (e) => {
		//if (this._isMounted) {
      this.setState({message: ''});
		  	this.setState({
				[e.target.name]: e.target.value,
		  	});
		//}
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
		  e.target.blur();
		  this.handleClick(e);
		}
  }
  
  handleClick = (e) => {
    e.preventDefault();
    const { userId, password } = this.state;
    if (userId === null || userId === '') {
      // console.log('아이디를 입력하세요.');
      return;
    }
    if (password === null || password === '') {
      // console.log('비밀번호를 입력하세요.');
      return;
    }
    this.handleLogin(userId, password);
  }

  handleLogin = (userId, password) => {
    const { history, BaseActions } = this.props;
  
    this.handleDisabled(true);
    BaseActions.login(userId, password)
      .then((data) => {
        console.log('??');
        //if (this._isMounted) {
          this.setState({
            userId: '',
            password: '',
          });
          history.push("/app/analysis");
        //}
      })
      .catch((error) => {
        this.handleDisabled(false);
        console.error(error);
      });
  }

  handleDisabled = (loading) => {
    if (this._isMounted) {
      this.setState({
        loading,
      });
    }
  }
  
  handleLogout = () => {
    const { BaseActions } = this.props;
    BaseActions.logout();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { userId } = this.state;
    return (
      <>
        <div>
          <label htmlFor="text-input-username" >사용자 아이디</label>          
          <input id="text-input-username" name="userId" type="text" value={userId} onChange={this.handleChange} autoComplete="off" />
          <label htmlFor="text-input-paswd" >비밀번호</label>          
          <input id="text-input-paswd" type="password" name="password" placeholder="" 
              onChange={this.handleChange} 
              onKeyPress={this.handleKeyPress} />
          <button onClick={this.handleClick}>로그인</button>
        </div>                  
      </>
  	);
	}
}

export default connect(
	state => ({
    logged: state.base.get('logged')
  }),
  dispatch => ({
      BaseActions: bindActionCreators(baseActions, dispatch),
  }),
)(withRouter(LoginPage));
