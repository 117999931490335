import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import icon_plus_01 from 'assets/svg/icon_plus_01.svg';
import btn_close from 'assets/svg/btn_close.svg';
import img_profile_default from 'assets/svg/img_profile_default.svg';
import img_profile_people_01 from 'assets/svg/img_profile_people_01.svg';
import img_profile_people_02 from 'assets/svg/img_profile_people_02.svg';
import img_profile_people_03 from 'assets/svg/img_profile_people_03.svg';
import img_profile_people_04 from 'assets/svg/img_profile_people_04.svg';
import img_profile_people_05 from 'assets/svg/img_profile_people_05.svg';
import img_profile_people_06 from 'assets/svg/img_profile_people_06.svg';
import img_profile_people_07 from 'assets/svg/img_profile_people_07.svg';
import img_profile_people_08 from 'assets/svg/img_profile_people_08.svg';
import img_profile_people_09 from 'assets/svg/img_profile_people_09.svg';
import img_profile_people_10 from 'assets/svg/img_profile_people_10.svg';
import img_profile_people_11 from 'assets/svg/img_profile_people_11.svg';
import img_profile_people_12 from 'assets/svg/img_profile_people_12.svg';


const ProfileImage = ({callback}) => {
  const [profileLayerShow, setProfileLayerShow] = useState(false)
      , [img, setImg] = useState(img_profile_people_07);

  useEffect(()=>{
    callback && callback({fileName:img, mediaType:null, fileBase64String:null});
  }, []);

  const imgChange = useCallback((e, imgFix)=>{
    let retVal = {fileName:null, mediaType:null, fileBase64String:null};
    if(imgFix) {
      let image=/(?:\/\/.+?)(\/.+)/.exec(imgFix)
      setImg(image[1]);
      setProfileLayerShow(false);
      retVal["fileNm"]=image[1];
      callback && callback(retVal);
      return;
    }
    if(!e.target.files.length) return;
    const file=e.target.files[0]
    const reader = new FileReader();
    reader.onload=()=>{
      const result = reader.result;
      retVal = {...retVal, fileName:file.name, mediaType:/data:(.+?);/.exec(result)[1], fileBase64String: result.replace(/data.+,/,"")};
      setImg(result);
      callback && callback(retVal);
      setProfileLayerShow(false);
    }
    if(e.target.files.length>0) {
      reader.readAsDataURL(file);
    }
  }, []);

  return(
    <StyledProfileImage>
      <label>Profile Image</label>
      <span className="pic">
        <img src={img} alt="profile Image" />
      </span>
      <button type="button" className="btn-gray" onClick={()=>setProfileLayerShow(true)}>Select</button>
      <StyledProfileLayer profileLayerShow ={profileLayerShow}>
        <div className="innerbox">
          <button type="button" className="btn-close" onClick={()=>setProfileLayerShow(false)}>close</button>
          <div className="attach-file">
            <input type="file" onChange={imgChange}/>
            <label>Please upload your photo</label>
          </div>
          <div className="pic-list">
            <ul onClick={(e)=>{
              e.target.src && imgChange(e, e.target.src);
            }}>
              <li>
                <img src={`${img_profile_people_01}`} alt="profile01"/>
              </li>
              <li>
                <img src={`${img_profile_people_02}`} alt="profile02" />
              </li>
              <li>
                <img src={`${img_profile_people_03}`} alt="profile03" />
              </li>
              <li>
                <img src={`${img_profile_people_04}`} alt="profile04" />
              </li>
              <li>
                <img src={`${img_profile_people_05}`} alt="profile05" />
              </li>
              <li>
                <img src={`${img_profile_people_06}`} alt="profile06" />
              </li>
              <li>
                <img src={`${img_profile_people_07}`} alt="profile07" />
              </li>
              <li>
                <img src={`${img_profile_people_08}`} alt="profile08" />
              </li>
              <li>
                <img src={`${img_profile_people_09}`} alt="profile09" />
              </li>
              <li>
                <img src={`${img_profile_people_10}`} alt="profile10" />
              </li>
              <li>
                <img src={`${img_profile_people_11}`} alt="profile11" />
              </li>
              <li>
                <img src={`${img_profile_people_12}`} alt="profile12" />
              </li>
            </ul>
          </div>
        </div>
      </StyledProfileLayer>
    </StyledProfileImage>
  )
}

const StyledProfileImage = styled.div`
  float:left;
  margin-bottom:20px;
  > label {
    float: left;
    width: 154px;
    line-height:40px;
    font-size:16px;
    ${mixins.fontSet('#111', '16px')};
    font-weight:700; 
  }
  .pic {
    display:inline-block;
    overflow:hidden;
    width:58px;
    height:58px;
    vertical-align:bottom;
    font-size:0;
    img {
      width:100%;
      height:auto;
      min-height:100%;
    }
  }
  .btn-gray {
    ${mixins.btnGray('52px', '30px')};
    margin-left:10px;
    vertical-align:bottom;
  }

`;

const StyledProfileLayer = styled.div`  
  display:${({profileLayerShow})=> profileLayerShow? "flex":"none"};
  justify-content:center;
  align-items:center;
  z-index:1;
  ${mixins.fixed('top 0 left 0')};
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.4);
  .innerbox {
    position:relative;
    width:280px;
    padding:63px 30px 30px;
    background:#fff;
  }
  .btn-close {
    ${mixins.absolute('top 19px right 30px')};
    width:24px;
    height:24px;
    background:url(${btn_close}) no-repeat 50%;
    font-size:0;
  }
  .attach-file {
    position:relative;
    width:100%;
    height:60px;
    input[type="file"] {
      z-index:1;
      position:absolute;
      width:100%;
      height:100%;
      opacity:0;
      cursor:pointer;
      & + label {
        display:block;
        z-index:0;
        ${mixins.absolute('top 0 left 0')};
        width:100%;
        height:100%;
        padding-top:17px;
        background:#f2f5f8;
        border:1px solid #eee;
        text-align:center;
        ${mixins.fontSet('#59697a', '12px')};
        &::before {
          ${mixins.afterBefore};
          ${mixins.absolute('top 10px left 50%')};
          width:24px;
          height:24px;
          margin-left:-12px;
          border-radius:50%;
          background:url('${icon_plus_01}') #97A3AF no-repeat 50%;
        }
      }
    }
  }
  .pic-list {
    width:100%;
    ${mixins.clearfix('after')};
    ul {
      margin:-10px 0 0 -20px;
      li {
        float:left;
        position:relative;
        overflow:hidden;
        width:60px;
        height:60px;
        margin:20px 0 0 20px;
        cursor:pointer;
        img {
          ${mixins.absolute('top 50% left 50%')};
          margin:-30px 0 0 -30px;
          transform:scale(1);
          transition:transform 0.3s;
        }
        &:hover img {
          transform:scale(1.1);
        }
      } 
    }
  }
`;

export default ProfileImage;
