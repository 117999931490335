import styled from "styled-components";
import * as mixins from "../../assets/styled/Mixin.styled";
import CheckedIcon from "../../assets/svg/icon_login_check.svg";
import WarnIcon from "../../assets/svg/icon_warn.svg";

export const StyledLogin = styled.div`
  width:100%;
  .centerwrap {
    margin: 70px auto 0;
    width: 350px;
    text-align: center;

    .loginbox {

      .id-field,
      .pw-field {
        display: block;
        margin-bottom: 15px;
      }
      .pw-field {
        margin-bottom: 10px;
      }

      .remember {
        margin-bottom: 10px;
        height: 21px;
        text-align: left;

        input[type="checkbox"] + label {
          padding-left: 20px;
          line-height: 20px;
        }
      }

      input[type="checkbox"] + label::after {
        top: 7px;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #e56a4b;
        border-radius: 2px;
        background: transparent url("${CheckedIcon}") no-repeat 50%;
      }

      input[type="checkbox"] + label::before {
        margin-top: -8px;
        width: 16px;
        height: 16px;
        background: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
      }

      .error-field {
        margin-bottom: 6px;
        // height: 20px;
        .error-message {
          text-align: left;
          color: #e91313;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .button-wrapper button {
        margin-bottom: 10px;
      }

      .reset-pw {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        a {
          position: relative;

          &:after {
            ${mixins.afterBefore()}
            position:absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 1px;
            background: #59697a;
          }
        }
      }
    }
  }
`;
