import React from 'react';
import { LayoutStyle } from 'assets/styled/Layout.styled';
import { ResetStyle } from 'assets/styled/Reset.styled';
import { LandingPageHeader } from 'components/Landing/LandingPageHeader';
import { LandingPageContents } from 'components/Landing/LandingPageContents';
import { LandingPageMapContents } from 'components/Landing/LandingPageMapContents';
import { LandingPageFooter } from 'components/Landing/LandingPageFooter';
import { LandingPageAnimation } from 'components/Landing/LandingPageAnimation';

const LandingPage = () => {

  return (
    <>
      <LayoutStyle />
      <ResetStyle />
      <div className="container">
        <div className="landing_area">
          <LandingPageHeader/>
          <div className="contentsarea">
            {/* <LandingPageContents
              subtitle="The premium platform for post-analysis"
              title="SG STATS"
              button="GO TO APPLICATION"
              // width="500px" => width가 필요한경우 px까지 입력 ( default : 350px )
            /> */}
            <LandingPageMapContents />
            {/* <LandingPageAnimation /> */}
          </div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  )
}

export default LandingPage;