import React from "react";
import styled from "styled-components";
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const InputProdByRadioBox = ({value}) => {

    return (
        <StyledMapCheckItem>
            <TextField
                id="filled-read-only-input"
                value={value ? value : ''}
                disabled
                variant="filled"
                multiline
                fullWidth
                maxRows={2}
            />
        </StyledMapCheckItem>
    );
};

const StyledMapCheckItem = styled.span`
  // display: inline-block;

  .MuiInputBase-input {
    width: 100%;
    border: 1px solid #3f454b;
    height: 1.1876em;
    margin-top: 5px;
    display: block;
    padding: 5px 0px 5px 10px;
    min-width: 0;
    background: #3f454b;
    color: #a7adb4;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    font: inherit;
    font-size: 12px;
    font-weight: 800;
    transition: all 0.3s ease;
    font-family: Lato,Dotum,Arial,sans-serif;
  //input[type=text]:disabled, input[type=password]:disabled, input[type=file]:disabled, input[type=email]:disabled {
  //  
  //}
`
export default InputProdByRadioBox;
