import React from 'react';
import SignUpHeader from 'pages/signup/SignUpHeader';
import { LayoutStyle } from 'assets/styled/Layout.styled';
import { ResetStyle } from 'assets/styled/Reset.styled';
import StepAgree from './signup/StepAgree';
import StepRegister from './signup/StepRegister';
import StepFinish from './signup/StepFinish';
import { useParams } from 'react-router-dom';

const SignUpPage = () => {
  const { lnb } = useParams();
  
  const signupComponent = { 
    agree : <StepAgree/>,
    register: <StepRegister/>,
    finish : <StepFinish/>
  }
  
  return (
    <>
      <ResetStyle/>
      <LayoutStyle/>
      <SignUpHeader />
      <div className="container">
        <div className="signuparea">
          {signupComponent[lnb]}
        </div>
      </div>
    </>
  )
}

export default SignUpPage;