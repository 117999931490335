import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as baseActions from 'store/modules/base';

/**
 * 전역적으로 사용되는 컴포넌트들이 있다면 여기서 렌더링합니다.
 */
class BaseContainer extends Component {
  render() {
    // const { BaseActions } = this.props;
    return (
      <>
      </>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    BaseActions: bindActionCreators(baseActions, dispatch)
  }),
)(BaseContainer);
