import React from "react";

const TrendList = ({ coinf, trendStr, isNum, group }) => {
  let jsonList = JSON.parse(trendStr);
  return (
    <>
      {jsonList.map((period, index) => (
        <tr key={index} className={"row " + (index % 2 === 1 ? "even" : "")}>
          <th>
            <span>
              <span>{coinf}</span>
            </span>
          </th>
          {group !== "" && (
            <td>
              <span>{group}</span>
            </td>
          )}
          <td>
            <span>{period.experimentDate}</span>
          </td>
          <td>
            <span>{isNum ? period.coiftCases : period.coiftRate}</span>
          </td>
        </tr>
      ))}
    </>
  );
};

const CoinfectionTable = ({ isTrend, title, tableData, showGroup, chartType }) => {
  return (
    <div className="table-wrapper">
      {/* trend - num/per */}
      {isTrend && chartType === "line" && tableData.coinfectionByDate && (
        <>
          <strong>Co-infection ({title})</strong>
          <table>
            <thead>
              <tr>
                <th>Target</th>
                {showGroup && <th>Group</th>}
                <th>periodType</th>
                <th>Cases({title})</th>
              </tr>
            </thead>
            <tbody>{chartType === "line" && tableData.coinfectionByDate.map((row, index) => <TrendList key={index} coinf={row.coiftPathogenNames} trendStr={row.trendData} isNum={title === "n"} group={showGroup ? row.groupInfo : ""} />)}</tbody>
          </table>
        </>
      )}

      {/* Stacked Area - num/per */}
      {isTrend && chartType === "area" && tableData.coinfectionByDate && (
        <>
          <strong>Co-infection ({title})</strong>
          <table>
            <thead>
              <tr>
                <th>Target</th>
                {showGroup && <th>Group</th>}
                <th>periodType</th>
                <th>Cases({title})</th>
              </tr>
            </thead>
            <tbody>{chartType === "area" && tableData.coinfectionByDate.map((row, index) => <TrendList key={index} coinf={row.coiftPathogenNames} trendStr={row.trendData} isNum={title === "n"} group={showGroup ? row.groupInfo : ""} />)}</tbody>
          </table>
        </>
      )}

      {/* num/per */}
      {!isTrend && title !== "CT" && tableData.coinfectionByCount && (
        <>
          <strong>Co-infection ({title})</strong>
          <table>
            <thead>
              <tr>
                <th>Target</th>
                {showGroup && <th>Group</th>}
                <th>Cases({title})</th>
              </tr>
            </thead>
            <tbody>
              {tableData.coinfectionByCount.map((row, index) => (
                <tr key={index} className={"row " + (index % 2 === 1 ? "even" : "")}>
                  <th>
                    <span>{row.coiftPathogenNames}</span>
                  </th>
                  {showGroup && (
                    <td>
                      <span>{row.groupInfo}</span>
                    </td>
                  )}
                  <td>
                    <span>{title === "n" ? row.coiftCases : `PatientCase: ${row.coiftCasesRate} /  PositiveCase: ${row.coiftCasesRate_v2}`}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {/* CT */}
      {!isTrend && title === "CT" && tableData.coinfectionByCT && (
        <>
          <strong>Co-infection (Ct)</strong>
          <table>
            <thead>
              <tr>
                <th>Target</th>
                <th>Pathogen</th>
                {showGroup && <th>Group</th>}
                <th>Mean</th>
                <th>Median</th>
                <th>Max</th>
                <th>Min</th>
                <th>Q3 (75%)</th>
                <th>Q1 (25%)</th>
              </tr>
            </thead>
            <tbody>
              {tableData.coinfectionByCT.map((row, index) => (
                <tr key={index} className={"row " + (index % 2 === 1 ? "even" : "")}>
                  <th>
                    <span>{row.coiftPathogenNames}</span>
                  </th>
                  <td>
                    <span>{row.pathogenName}</span>
                  </td>
                  {showGroup && (
                    <td>
                      <span>{row.groupInfo}</span>
                    </td>
                  )}
                  <td>
                    <span>{row.average}</span>
                  </td>
                  <td>
                    <span>{row.median}</span>
                  </td>
                  <td>
                    <span>{row.maximum}</span>
                  </td>
                  <td>
                    <span>{row.minimum}</span>
                  </td>
                  <td>
                    <span>{row.upperQuartile}</span>
                  </td>
                  <td>
                    <span>{row.lowerQuartile}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default CoinfectionTable;
