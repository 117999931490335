import styled from 'styled-components';
import CHECK from '../../assets/svg/icon_mail_check.svg';

export const StyledGoMain = styled.div `
  width:100%;
  .centerwrap{
    strong {
      display:block;
      position:relative;
      padding-top: 106px;
      margin-bottom:40px;
      text-align:center;
      color:#354554;
      font-size:26px;
      font-weight:800;
      background: url('${CHECK}')no-repeat 50% 0;
    }
  }
`;
