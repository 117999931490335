import React, { Component } from 'react';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div>
            <p>
              현재 페이지를 표시할 수 없습니다.
            </p>
            <div>
              <a href="/" className="">메인페이지로</a>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorPage;
