import React from 'react';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import FinishIcon from '../../assets/svg/icon_finish.svg'
import * as mixins from 'assets/styled/Mixin.styled';

const StepFinish = () => {
  
  const history = useHistory();

  return (
    <StyledStepAgree>
      <div className="finishBox">
        <p className="text">
          {/* Membership application is finished!<br/>
          You can access the site after <em>email verification</em><br/>
          and <em>administrator approval.</em> */}
          We appreciate your registration for SG STATS.<br/>
          Your account is currently awaiting approval from the system administrator.<br/>
          <em>Upon approval, we will notify you via your registered email address.</em>
        </p>
        <button onClick={() => history.push('/member/login')} title="SIGN IN">GO TO SIGN IN</button>
      </div>
    </StyledStepAgree>
  )
}

export default StepFinish;


const StyledStepAgree = Styled.div`
  width:350px;
  .finishBox{
    padding-top:130px;
    background:url('${FinishIcon}')no-repeat 50% 30px;
    .text {
      margin: 0 -80% 40px;
      text-align:center;
      color:#354554;
      font-size:26px;
      font-weight:400;
      > em {
        font-weight:800;
        color: #E56A4B;
      }
    }
    > button {
      ${mixins.btnGray('100%', '50px')};
      font-size:16px;
      font-weight:800;
    }
  }
`;