import React from 'react';
import NotFoundPage from './NotFoundPage';
import CheckEmail from 'containers/gateway/CheckEmail';

const GatewayPage = ({match}) => {

  let container;
  
  switch (match.params.lnb) {
    
    case 'checkemail':
      container = <CheckEmail/>;
      break;
    default :
      container = <NotFoundPage/>;
  }
  
  return (
    <>
      <div className="container">
        <div className="memberarea">
          {container}
        </div>
      </div>
    </>
  )
}

export default GatewayPage;