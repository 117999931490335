import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useForm } from "react-hook-form";
import { useKeypress } from "lib/common";
import { verifyEmail } from 'lib/api';
import { StyledVerify } from './VerifyEmail.styled';

const VerifyEmail = ({lnb}) => {
  const registered = useSelector(state => state.base.get('registered'));
  const logged = useSelector(state => state.base.get('logged'));
  const [ submitting, setSubmitting] = useState(false);
  const { history } = useReactRouter();
  const { 
    getValues, setValue, 
    handleSubmit, 
    register, 
    errors, setError
  } = useForm();
  const onSubmit = async() => {
    if(submitting) {
      // console.log('submitting...');
      return;
    }
    setSubmitting(true);
    
    if(!logged) {
      setError("email", {message: "You must sign in first."})
      return;
    }

    const email = getValues("email");
    try {
      await verifyEmail(email).then(() => {
        history.push("/member/email");
      });
    } catch (err) {
      setError("email", {message: "Failed to send mail."})
    }
    setSubmitting(false);
  };

  useKeypress('Escape', () => {
    handleSkipAndRedirect();
  });

  const handleSkipAndRedirect = () => {
    // 관심상품 등록 여부 체크

    if(registered || !logged) {
      // 등록했으면 메인으로
      history.push("/"); 
    }else {
      // 등록하지 않았으면 등록화면으로
      history.push("/member/register");
    }
  }

  return(
    <>
      <StyledVerify lnb={lnb}>
        <div className="contents">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="centerwrap">
              <div className="layoutBox">
                <strong>You have to verify your email</strong>
                <p className="warn">
                  Your account has not yet been completed email verification. <br/>
                  Please enter your email and we send you a verification link.
                </p>
              </div>
              <span className="email-field">
                <label>Email</label>
                <input 
                  type="text" 
                  placeholder="Your email address" 
                  title="Write down your Email" 
                  name="email"
                  onChange={e => setValue(e.target.value)}
                  ref={register({
                    required: "Check email form. ex) username@email.com",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Check email form. ex) username@email.com"
                    }
                  })}
                />
              </span>
              <div className="error-field">
                {
                  errors.email && (
                    <p className="error-message">
                      { errors.email.message }
                    </p>
                  )
                }
              </div>
              <div className="button-wrapper">
                <button 
                  className="btn-white" 
                  type="button" 
                  title="Skip"
                  onClick={e => { 
                    // 한달 쿠키 생성...
                    handleSkipAndRedirect();
                  }}  
                  >SKIP</button>
                <button 
                  className="btn-gray" 
                  type="button" 
                  title="Send"
                  onClick={handleSubmit(onSubmit)}
                  >SEND</button>
              </div>
            </div>
          </form>
        </div>
      </StyledVerify>
    </>
  );
}

export default VerifyEmail;
