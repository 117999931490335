import axios from 'axios';
import { ACCESS_TOKEN } from '../constants';

// request interceptors
axios.interceptors.request.use(
    (config) => {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
      }
      return config;
    }, (error) => {
      if (error.response && error.response.data && !error.response.data.code && error.response.data.message) {
        console.error(error.response.data.message);
      }
      return Promise.reject(error);
    },
  );
  
  // response interceptors
  axios.interceptors.response.use(
    config => config, (error) => {
      if (error.response && error.response.data && !error.response.data.code && error.response.data.message) {
        console.error(error.response.data.message);
      }
      return Promise.reject(error);
    },
  );
  
// 로그인 요청
export const login = (userId, password) => axios({
    url: '/authenticate',
    method: 'post',
    data: {
      username: userId,
      password,
    },
  });

// request for password reset email
export const forgetPassword = (email) => axios({
  url: `/api/password`,
  method: 'post',
  data: { email }
});

// request for password reset email
export const verifyEmail = (email) => axios({
  url: `/api/sendconfirmemail`,
  method: 'post',
  data: { email }
});

// request for password reset email
export const confirmMail = (token) => axios({
  url: `/api/checkconfirmemail`,
  method: 'post',
  data: { token }
});

export const resetPassword = (token, password) => axios({
  url: `/api/password`,
  method: 'put',
  data: { 
    token, password
  }
});

/* Download Excel */
export const downloadExcel = (params) => axios({
  url: `/api/download/excel`,
  method: 'post',
  data: {
    ...params
  },
  responseType: 'blob',
  headers: {
    "Content-Type": "application/json"
  }
});