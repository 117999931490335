import styled from 'styled-components';
import * as mixins from '../../assets/styled/Mixin.styled';
import WARN from '../../assets/svg/icon_warn.svg'

export const StyledReset = styled.div `
  width:100%;
  .centerwrap{
    strong{
      display:block;
      margin-bottom:30px;
      line-height:1;
      text-align: center;
      color:#354554;
      font-size:26px;
      font-weight:800;
    }
    
    .layoutBox{  
      .warn {
        position:relative;
        margin-bottom:40px;
        text-align: center;
        color:#59697A;
        font-size:16px;
        font-weight:400;
        text-indent: 20px;

        &:before{
          ${mixins.afterBefore()};
          ${mixins.absolute('top 4px left 120px')};
          width:16px;
          height:16px;
          background:url('${WARN}')no-repeat;
        }
      }
    }

    .password-field{
      display:block;
      margin-bottom:8px;
    }

    .error-field{
      height:20px;
      .error-message{
        text-align:left;
        color:#E91313;
        font-size:14px;
        font-weight:400;
        &.case02 {
          display:none;
        }
      }
    }

    > button.btn-gray {
      margin-top:12px;
    }
  }
`;
