import React, { useState, useEffect, useMemo } from 'react';
import useReactRouter from 'use-react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { useKeypress } from "lib/common";
import _ from 'lodash';
import { 
  GET_ALL_PRODUCT_LINES, MEMBER_INTEREST_PRODUCT_INSERT
} from 'lib/gql';

import { StyledRegister } from './Reigster.styled';

// const Items = [
//   {
//     cn: "rp",
//     name: "RP",
//   },
//   {
//     cn: "gi",
//     name: "GI",
//   },
//   {
//     cn: "sti",
//     name: "STI",
//   },
//   {
//     cn: "hpv",
//     name: "HPV",
//   },
//   {
//     cn: "tb",
//     name: "TB",
//   },
//   {
//     cn: "dr",
//     name: "DR",
//   },
//   {
//     cn: "meni",
//     name: "Meningitis",
//   }
// ];

// const ItemList = Items.map((item, index) => {
//   return (
//     <span className={"checkBox" + " " + item.cn} key={index}>
//       <input type="checkbox" id={index} />
//       <label htmlFor={index}>{item.name}</label>
//     </span>
//   )
// });

const Register = ({lnb}) => {

  const { history } = useReactRouter();
  const [ items, setItems ] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_PRODUCT_LINES);
  const [ ,setCookie ] = useCookies(['skipReg']);
  const chosen = useMemo(() => {
    return items && (items.filter(i => i.checked).length > 0)
  }, [items]);
  const [ memberInterestproductInsert ] = useMutation(MEMBER_INTEREST_PRODUCT_INSERT);

  useEffect(() => {
    if(error) {
      console.log(error);
    }
  }, [error]);
  useEffect(() => {
    if(data && data.getAllProductLines) {
      const items = _(data.getAllProductLines).groupBy('prdtlnGrpNm').map((groups,prdtlnGrpNm) => {
          return {
            prdtlnGrpNm,
            ids: groups.map(g => g.id),
            checked: false
          }
      }).value();
      
      setItems(items);
    }
  }, [data]);
  
  useKeypress('Escape', () => {
    handleSkipAndRedirect();
  });
  
  const ItemList = items.map((item, index) => {
    return (
      <span className={`checkBox ${item.prdtlnGrpNm}`} key={item.prdtlnGrpNm}>
        <input 
          type="checkbox" 
          id={item.prdtlnGrpNm} 
          checked={item.checked}
          onChange={e => {
            const i = items.find(({prdtlnGrpNm})=> prdtlnGrpNm === item.prdtlnGrpNm);
            i.checked = e.target.checked;
            setItems([...items]);
          }}
          />
        <label htmlFor={item.prdtlnGrpNm}>{item.prdtlnGrpNm}</label>
      </span>
    )
  });

  const handleSkipAndRedirect = () => {
    var now = new Date();
    now.setMonth( now.getMonth() + 1 );
    
    setCookie('skipReg', 'Y', { path: '/', expires: now });
    history.push("/"); 
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(!chosen) return;

    let param = {
      prdtlnGrpNms: items.filter(i => i.checked).map(i => i.prdtlnGrpNm)
    }
    memberInterestproductInsert({variables : param})
    .then(response=>{
      if(response.data.insertMemberInterestProducts.message === 'success') {
        history.push('/member/gomain');
      }else {
        console.error('insertMemberInterestProducts failed');
      }
    }).catch(err => {
      console.error(err.message);
    });
  }


  return(
    <>
      <StyledRegister lnb={lnb}>
        <div className="contents">
          <div className="centerwrap">
            <div className="layoutBox">
              <strong>To form your global network<br/>Choose your products of interest!</strong>
              <p className="warn">
                If the field of interest is registered, <br/>
                we recommend contents based on that information.
              </p>
            </div>
            <div className="boardlist">
              {
                loading ? 'Loading...' : ItemList
              }
            </div>
            <div className="error-field">
              { !chosen && (
                <p className="error-message">Nothing was chosen</p>
              )}
            </div>
            <div className="button-wrapper">
              <button 
                className="btn-white" 
                type="button" 
                title="Skip"
                onClick={e => { 
                  // 한달 쿠키 생성...
                  handleSkipAndRedirect();
                }}
              >Skip</button>
              <button 
                className="btn-gray" 
                type="button" 
                title="Done"
                onClick={handleSubmit}
                >DONE</button>
            </div>
            <p className="skip-text">
              If you press the skip button,<br/> this message will be displayed again in a month.
            </p>
          </div>
        </div>
      </StyledRegister>
    </>
  );
};

export default Register;



