import React from 'react';
import LoginPage from './member/LoginPage';
import ChangePassword from './member/ChangePassword';
import EmailSent from './member/EmailSent';
import GoMain from './member/GoMain';
import Register from './member/Register';
import ResetPassword from './member/ResetPassword';
import VerifyEmail from './member/VerifyEmail';
import NotFoundPage from './NotFoundPage';
import { LoginHeader } from './member/LoginHeader';
import { LayoutStyle } from 'assets/styled/Layout.styled';
import { ResetStyle } from 'assets/styled/Reset.styled';

const MemberPage = ({match, history}) => {

  let container;
  
  switch (match.params.lnb) {
    
    case undefined :
      container = <LoginPage/>;
      break;
    case 'change':
      container = <ChangePassword/>;
      break;
    case 'email':
      container = <EmailSent/>;
      break;
    case 'gomain':
      container = <GoMain/>;
      break;
    case 'reset':
      container = <ResetPassword/>;
      break;
    case 'verify':
      container = <VerifyEmail/>;
      break;
    case 'login': 
      container = <LoginPage/>;
      break;
    case 'register': 
      container = <Register/>;
      break;
  }
  
  return (
    <>
      <LayoutStyle/>
      <ResetStyle/>
      <LoginHeader />
      <div className="container">
        <div className="memberarea">
          {container}
        </div>
      </div>
    </>
  )
}

export default MemberPage;