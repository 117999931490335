import React from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import {StyledGoMain} from './GoMain.styled';
import { useKeypress } from "lib/common";

const GoMain = ({lnb}) => {
  const logged = useSelector(state => state.base.get('logged'));
  const { history, location } = useReactRouter();
  const getMessage = () => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    if(type === 'confirm') {
      return <strong>Thanks. <br/>Your email has been verified.</strong>;
    }else if(type === 'reset') {
      return <strong>Thanks. <br/>Reset password is complete.</strong>;
    }else {
      return <strong>Thanks.<br/>We'll provide you with useful content<br/>through a database of interests.</strong>;
    }
  }

  useKeypress('Escape', () => {
    history.push('/');
  });

  return(
    <>
      <StyledGoMain lnb={lnb}>
        <div className="contents">
          <div className="centerwrap">
            <div className="layoutBox">
              {getMessage()}
            </div>
            <button 
              className="btn-gray" 
              type="button" 
              title="Go Main"
              onClick={e => history.push('/')}
              >{ logged ? 'GO TO MAIN PAGE' : 'GO TO SIGN IN' }</button>
          </div>
        </div>
      </StyledGoMain>
    </>
  )
}

export default GoMain;
