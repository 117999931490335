import React from 'react';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';

export const LandingPageContents = ({subtitle,title,button,width}) => {
  const history = useHistory();
  const sgstatsToken = localStorage.getItem('sgstatsToken');

  const authPath = () => {
    if (
        sgstatsToken === '' ||
        sgstatsToken === null ||
        sgstatsToken === undefined
      ) {
        history.push('/member/login');
    } else {
      history.push('/');
    }
  }
  
  return (
    <ContentsWrapper width={width}>
      <SubTitle>{subtitle}</SubTitle>
      <Title>{title}</Title>
      <Button onClick={authPath} title="GO PAGE">{button}</Button>
    </ContentsWrapper>
  )
}

const ContentsWrapper = Styled.div`
  display:inline-block;
  z-index:10;
  position:relative;
  width:1280px;
  margin:0 auto;
`
const SubTitle = Styled.p`
  margin-bottom:17px;
  line-height:1;
  color: #FFB14A;
  font-size:20px;
  font-weight:400;
`;

const Title = Styled.h2`
  margin-bottom:36px;
  line-height:1;
  color:#fff;
  font-size:70px;
  font-weight:800;
`;

const Button = Styled.button`
  width:250px;
  border-radius:80px;
  background:transparent linear-gradient(90deg, #FE5930 0%, #D91E1E 100%) 0% 0% no-repeat padding-box;
  color:#fff;
`;


