import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/svg/Logo.svg';
import LogoText from '../../assets/svg/LogoText.svg';
import * as mixins from '../../assets/styled/Mixin.styled';
// import useReactRouter from 'use-react-router';

export const LoginHeader = () => {
  // const { history } = useReactRouter();
  
  return (
      <Header>
        <LOGO/>
        <Text>Welcome Back!</Text>
      </Header>
  );
}

const Header = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  position:relative;
  flex:0 0 144px;
  background:#f2f5f8;
  border-bottom: 1px solid #dfe3e8;
`;

const LOGO = styled.h1`
  position:relative;
  margin-left:38px;
  margin-bottom:10px;
  width:151px;
  height:35px;
  background:url('${LogoText}') no-repeat 0 0;

  &:before{
    ${mixins.afterBefore()}
    ${mixins.absolute('left -38px top 3px')};
    width:30px;
    height:30px;
    background: url('${Logo}') no-repeat 0 0;
  }
`;

const Text = styled.p`
  line-height:1;
  color:#a7adb4;
  font-size:16px;
  font-weight: 400;
`;