import InputSelect from 'components/InputSelect';
import InputText from 'components/InputText';
import TermsField from 'components/TermsField';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import {Link, useLocation} from 'react-router-dom';
import axios from 'axios';
import {uniqBy as _uniqBy, cloneDeep as _cloneDeep, map as _map, filter as _filter, find as _find} from 'lodash';


const StepAgree = () => {
    const {natInfo} = useLocation()
    const [errorMsg, setErrorMsg] = useState('');
    const [nationObj, setNationObj] = useState({
        regionList:null,
        contryList:null,
        cityList:null
    });

    const [inputVal, setInputVal]=useState({
        natAreaCd: natInfo?natInfo.natAreaCd:"",
        natCd: natInfo?natInfo.natCd:"",
        regnNm: natInfo?natInfo.regnNm:"",
        custNm: natInfo&&natInfo.custNm,
        agree: natInfo&&natInfo.agree ? true : false
    });
    useEffect(() => {
        setNationObj(nationObj=>({...nationObj, contryList:getChildSelectDatas(nationObj.regionList, inputVal.natAreaCd, "natAreaCd")}));
    }, [inputVal.natAreaCd, nationObj.regionList]);

    const handleChange = e =>{
        const {value, id} = e.target;
        setInputVal({...inputVal, [id]:value});
    }

    function getChildSelectDatas(selectData,selectedVal,keyString) {
        const retValObj=_find(selectData, o => o[keyString]==selectedVal);
        return retValObj&&retValObj.child;
    }
    const submit=(e)=>{
        const validKeys={
            agree: {msg:"Please agree to the terms of service.", target:null}
            ,natAreaCd:{msg:"Enter your Region.", target:null}
            ,natCd:{msg:"Enter your Country.", target:null}
            //,regnNm:{msg:"Enter your State.", target:null}
            ,custNm:{msg:"Enter your Affiliation.", target:null}
        };
        let sucFlag=true;
        for (const [key, valObj] of Object.entries(validKeys)) {
            if(sucFlag && !inputVal[key]) {
                setErrorMsg(valObj.msg);
                sucFlag=false;
            }
        }
        sucFlag|| e.preventDefault();
    };
    useEffect(()=> {
        axios.post('/api/getNationCombos', null, {
            headers: { "Content-Type": `application/json`}
        }).then(response=>{
            if(!response.data) return;
            const allList=response.data
              , regionList = _cloneDeep(_uniqBy(allList, "natAreaCd")).filter(o=>o.natAreaCd);
            regionList.map(o=>{o.child=allList.filter(nat=>nat.natAreaCd==o.natAreaCd)});
            setNationObj({...nationObj, regionList: regionList});

        });
    },[]);

    return (
        <StyledStepAgree>
            <div className="termsbox">
                <label>User Terms</label>
                <TermsField agreeState={(checked)=> {setInputVal({...inputVal,agree:checked});}} value={inputVal.agree} errorMsg={errorMsg=="Please agree to the terms of service."}/>
            </div>
            <div className="boardwrite">
                <ul>
                    <li>
                        <InputSelect id="natAreaCd" name="Region" onChange={
                            e=>{setInputVal({...inputVal, natAreaCd: e.target.value, natCd:"", regnNm:""});}
                        } value={inputVal.natAreaCd}>
                            <option value="">Select</option>
                            {nationObj.regionList && nationObj.regionList.map((o)=>(
                                <option key={o.natAreaCd} value={o.natAreaCd}>{o.natAreaNm}</option>
                            ))}
                        </InputSelect>
                    </li>
                    <li>
                        <InputSelect id="natCd" name="Country" statNm="Country" onChange={
                            e=>{setInputVal({...inputVal,natCd:e.target.value, regnNm:""});}
                        } value={inputVal.natCd}>
                            <option value="">Select</option>
                            {nationObj.contryList && nationObj.contryList.map((o)=>(
                                <option key={o.id} value={o.id}>{o.natNm}</option>
                            ))}
                        </InputSelect>
                    </li>
                    <li>
                        <InputText type="text" id="Affiliation" name="Affiliation" placeholder="Insert" value={inputVal.custNm} onChange={e=>{
                            const value=e.target.value.toUpperCase();
                            setInputVal(inputVal=>({...inputVal, custNm:value}));
                        }}/>
                    </li>
                </ul>
            </div>
            <p className="error">{errorMsg}</p>
            <div className="btnset">
                <Link to="/landing" className="btn-cancel">CANCEL</Link>
                <Link to={{pathname:"/signup/register"
                    ,natInfo:inputVal
                }} className="btn-next" onClick={submit}>NEXT</Link>
            </div>
        </StyledStepAgree>
    )
};

const StyledStepAgree = styled.div`
  width:350px;
  ul {
    li {
      margin-top:15px;
      &:first-child {
        margin-top:0
      }
    }
  }
  label {
    display:block;
    ${mixins.fontSet('#111', '14px')};
    font-weight:400;
  }
  .error {
    margin:25px 0 8px;
    ${mixins.fontSet('#E91313', '14px')};
    font-weight:400;
  }
  .btnset {
    > button, a {
      margin-left:12px;
      font-size:16px !important;
      &:first-child {
        margin-left:0;
      }
      &.btn-cancel {
        ${mixins.btnWhite('169px', '50px')};
      }
      &.btn-next {
        ${mixins.btnGray('169px', '50px')};
      }
    }
  }
`;


export default StepAgree;