import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as mixins from '../../assets/styled/Mixin.styled';

const SignUpHeader = () => {
  const { lnb } = useParams();
  
  return (
    <Header>
      <h1>SIGN UP</h1>
      <div className="stepbox">
        <span className={lnb && lnb === 'agree' ? "on" : ""}>1</span>
        <span className={lnb && lnb === 'register' ? "on" : ""}>2</span>
        <span className={lnb && lnb === 'finish' ? "on" : ""}>3</span>
      </div>
    </Header>
  );
}

const Header = styled.div`
  ${mixins.flex('center', 'center')};
  flex-direction:column;
  flex:0 0 144px;
  height:144px;
  background:#f2f5f8;
  border-bottom:1px solid #dfe3e8;
  h1 {
    text-align:center;
    ${mixins.fontSet('#354554', '30px')};
    font-weight:700;
  }
  .stepbox {
    position:relative;
    margin-top:5px;
    font-size:0;
    > span {
      display:inline-block;
      z-index:1;
      position:relative;
      width:28px;
      height:28px;
      margin-left:40px;
      border-radius:50%;
      background:#dde0e5;
      text-align:center;
      line-height:28px;
      ${mixins.fontSet('#59697a', '14px')};
      font-weight:700;
      &:first-child {
        margin-left:0;
      }
      &.on {
        background:#E56A4B;
        color:#fff;
        box-shadow:2px 2px 5px rgba(123, 33, 11, 0.3);
      }
    }
    &::before {
      ${mixins.afterBefore};
      z-index:0;
      ${mixins.absolute('top 50% left 0')};
      width:100%;
      height:4px;
      margin-top:-2px;
      background:#dde0e5;
    }
  }
`;

export default SignUpHeader;