import React, {useState ,useEffect} from "react";
import styled from "styled-components";
import ArrowIcon from "assets/svg/icon_arrow_left.svg";
import moment from 'moment';
const { REACT_APP_APP_HOST: appHost } = process.env;

const InputSelect = ({setSearchMonth, value, onChange}) => {
  const [yearOption, setYearOptions] = useState([]);

  useEffect(() => {
    let nowYear = moment().format('YYYY') - 21,
        years = [],
        month = [];

    for(let i=0; i<=21; i++) {
      let obj = {}
      obj.value = nowYear+i;
      obj.name = nowYear+i;
      years.push(obj);
    }

    for(let i=1; i<=12; i++) {
      let obj = {}
      obj.month = `${i}`;
      obj.exptDate = value+0+i+0+1;
      if(i > 9) {
        obj.exptDate = value+i+0+1;
      }
      obj.count = 0;
      month.push(obj);
    }
    setSearchMonth(month)
    setYearOptions(years)
  }, [value])

  return (
    <MapChartSelectBox>
      <select name="mapChartSelect" id="mapChartSelect" value={value} onChange={onChange}>
        {
          yearOption?.map((year, index) => (
            <option key={index} value={year.value}>{year.name}</option>
          ))
        }
      </select>
    </MapChartSelectBox>
  );
};

export default InputSelect;

const MapChartSelectBox = styled.div`
  > select {
    position: relatvie;
    padding: 0 15px;
    width: 90px;
    height: 32px;
    background: #161e26 url("${appHost}${ArrowIcon}") no-repeat 90% 50%;
    border: 0;
    border-radius: 90px;
    box-shadow: 2px 2px 3px #0000002e;
    color: #fff;
    font-size: 12px;
    font-weight: 800;
    cursor: pointer;
  }
`;
