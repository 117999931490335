import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';

const StyledInputRadio = styled.span`
  display:inline-block;
  cursor: pointer;
  > label {
    display:block;
    color:#111;
  }
`;

const InputRadio = ({id, name, label, className, disabled, value, checkValue, onChangeCallback}) => {
  const [isChecked, setChecked] = useState([value]);

  const handleChange = useCallback((e) => {
    setChecked([e.target.value])
    if (onChangeCallback){
      onChangeCallback(e);
    }
  }, [checkValue])

  return (
    <StyledInputRadio className={className}>
      <input type="radio" id={id} name={name} value={value} checked={isChecked.includes(checkValue)} disabled={disabled} onChange={(e) => handleChange(e)}/>
      <label htmlFor={id}>{label}</label>
    </StyledInputRadio>
  );
}

export default InputRadio;