import React, { useRef, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as options from "./options";
import { CoinfectionTable } from "components/amchart";

//다크모드
// am4core.useTheme(am4themes_dark);

//animated
am4core.useTheme(am4themes_animated);

//license
am4core.options.commercialLicense = true;

const CoinfectionChart = ({ data, caseVersion, header, showTitle, showLegend, showScroll, type, width, height, xMinGridDistance, yMinGridDistance, limitLineValue, baseInterval, xFormat, toolbarData, isIndividualGroup, exportMenuRef, exportFileName, viewTable, handleLoading, numberFlag, dotFlag, toggleFlag, productFlag, exportFilter, sizeChanged, grayFlag, nameFlag, sortFlag }) => {
  const chartRef = useRef(null);

  const [seriesArray, setSeriesArray] = useState([]);

  useEffect(() => {
    if (seriesArray.length > 0) {
      if (toggleFlag) {
        seriesArray.forEach((s) => {
          s.show();
        });
      } else {
        seriesArray.forEach((s) => {
          s.hide();
        });
      }
    }
  }, [toggleFlag, type, seriesArray]);

  useEffect(() => {
    //필수체크 하자...
    if (!data || data.length === 0) {
      return;
    }

    if (!header || !header.x || !header.y) {
      //TODO
      // console.error("'header' is required")
    }

    let chart;
    let chartData;
    let coinfectionData = [];
    if (type === "candle") {
      const tempData = data.coinfectionByCT;
      tempData && tempData.length > 0 &&
      tempData.forEach((data, index) => {
        coinfectionData.push({
          ...data,
        });
      });
    } else if (type === "line" || type === "area") {
      const tempData = data.coinfectionByDate;
      if (caseVersion === "patient") {
        tempData && tempData.length > 0 &&
        tempData.forEach((data, index) => {
          if (Object.keys(!data.coiftCasesRate)) {
            coinfectionData.push({
              ...data,
            });
          }
        });
      } else if (caseVersion === "positive") {
        tempData && tempData.length > 0 &&
        tempData.forEach((data, index) => {
          const jsonConvertedTrendData = [];
          if (Object.keys(!data.coiftCasesRate)) {
            if(data.trendData) {
              //trenddata -> json
              const jsonTrendData = JSON.parse(data.trendData);
              jsonTrendData.forEach((data) => {
                jsonConvertedTrendData.push({
                  ...data,
                  coiftRate: data.coiftRate_v2
                });
              });

            }
            coinfectionData.push({
              ...data,
              trendData: JSON.stringify(jsonConvertedTrendData),
            });
          }
        });
      }
    }
    else {
      const tempData = data.coinfectionByCount;
      if (caseVersion === "patient") {
        tempData && tempData.length > 0 &&
        tempData.forEach((data, index) => {
          if (Object.keys(!data.coiftCasesRate)) {
            coinfectionData.push({
              ...data,
            });
          }
        });
      } else if (caseVersion === "positive") {
        tempData && tempData.length > 0 &&
        tempData.forEach((data, index) => {
          if (Object.keys(!data.coiftCasesRate)) {
            coinfectionData.push({
              ...data,
              coiftCasesRate: data.coiftCasesRate_v2,
              validCases: data.validCases_v2,

            });
          }
        });
      }
    }
    chartData = coinfectionData;
    chartData && chartData.length > 0 &&
      chartData.forEach((coInfection) => {
        if (
          nameFlag &&
          (coInfection.coiftPathogenNames === "HV69/70 del|N501Y|RdRP gene" ||
            coInfection.coiftPathogenNames === "HV69/70 del|N501Y" ||
            coInfection.coiftPathogenNames === "E484K|N501Y|RdRP gene" ||
            coInfection.coiftPathogenNames === "E484K|N501Y" ||
            coInfection.coiftPathogenNames === "E484K|K417N|N501Y|RdRP gene" ||
            coInfection.coiftPathogenNames === "E484K|K417N|N501Y" ||
            coInfection.coiftPathogenNames === "E484K|K417T|N501Y|RdRP gene" ||
            coInfection.coiftPathogenNames === "E484K|K417T|N501Y" ||
            coInfection.coiftPathogenNames === "L452R|RdRP gene" ||
            coInfection.coiftPathogenNames === "L452R|P681R" ||
            coInfection.coiftPathogenNames === "L452R|P681R|RdRP gene" ||
            coInfection.coiftPathogenNames === "P681R|RdRP gene" ||
            coInfection.coiftPathogenNames === "L452R|RdRP gene|W152C" ||
            coInfection.coiftPathogenNames === "L452R|W152C" ||
            coInfection.coiftPathogenNames === "E484K|RdRP gene" ||
            coInfection.coiftPathogenNames === "E484K|HV69/70 del|RdRP gene" ||
            coInfection.coiftPathogenNames === "E484K|HV69/70 del" ||
            coInfection.coiftPathogenNames === "HV69/70 del|RdRP gene" ||
            coInfection.coiftPathogenNames === "HV69/70 del|L452R|RdRP gene" ||
            coInfection.coiftPathogenNames === "HV69/70 del|L452R" ||
            coInfection.coiftPathogenNames === "K417N|RdRP gene" ||
            coInfection.coiftPathogenNames === "K417T|RdRP gene" ||
            coInfection.coiftPathogenNames === "K417N|L452R" ||
            coInfection.coiftPathogenNames === "K417N|L452R|RdRP gene" ||
            coInfection.coiftPathogenNames === "K417N|L452R|P681R" ||
            coInfection.coiftPathogenNames === "K417N|L452R|P681R|RdRP gene" ||
            coInfection.coiftPathogenNames === "HV69/70 del|K417N|N501Y|RdRP gene" ||
            coInfection.coiftPathogenNames === "F490S|L452Q")
        ) {
          coInfection.coiftPathogenNamesOrg = coInfection.coiftPathogenNames;
          if (coInfection.coiftPathogenNames === "HV69/70 del|N501Y|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|N501Y|RdRP gene", "HV69/70 del|N501Y|RdRP gene\n[bold]B.1.1.7(α)[/]");
          } else if (coInfection.coiftPathogenNames === "HV69/70 del|N501Y") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|N501Y", "HV69/70 del|N501Y\n[bold]B.1.1.7(α)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|N501Y|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|N501Y|RdRP gene", "E484K|N501Y|RdRP gene\n[bold]P.1(γ)|B.1.351(β)|P.3(θ)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|N501Y") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|N501Y", "E484K|N501Y\n[bold]P.1(γ)|B.1.351(β)|P.3(θ)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|K417N|N501Y|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|K417N|N501Y|RdRP gene", "E484K|K417N|N501Y|RdRP gene\n[bold]B.1.351(β)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|K417N|N501Y") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|K417N|N501Y", "E484K|K417N|N501Y\n[bold]B.1.351(β)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|K417T|N501Y|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|K417T|N501Y|RdRP gene", "E484K|K417T|N501Y|RdRP gene\n[bold]P.1(γ)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|K417T|N501Y") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|K417T|N501Y", "E484K|K417T|N501Y\n[bold]P.1(γ)[/]");
          } else if (coInfection.coiftPathogenNames === "L452R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("L452R|RdRP gene", "L452R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3|C.36.3[/]");
          } else if (coInfection.coiftPathogenNames === "L452R|P681R") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("L452R|P681R", "L452R|P681R\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3[/]");
          } else if (coInfection.coiftPathogenNames === "L452R|P681R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("L452R|P681R|RdRP gene", "L452R|P681R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3[/]");
          } else if (coInfection.coiftPathogenNames === "P681R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("P681R|RdRP gene", "P681R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3|AY.1&2(δ+)[/]");
          } else if (coInfection.coiftPathogenNames === "L452R|RdRP gene|W152C") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("L452R|RdRP gene|W152C", "L452R|RdRP gene|W152C\n[bold]B.1.427/429(ε)[/]");
          } else if (coInfection.coiftPathogenNames === "L452R|W152C") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("L452R|W152C", "L452R|W152C\n[bold]B.1.427/429(ε)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|HV69/70 del|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|HV69/70 del|RdRP gene", "E484K|HV69/70 del|RdRP gene\n[bold]B.1.525(ή)[/]");
          } else if (coInfection.coiftPathogenNames === "E484K|HV69/70 del") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|HV69/70 del", "E484K|HV69/70 del\n[bold]B.1.525(ή)[/]");
          } else if (coInfection.coiftPathogenNames === "HV69/70 del|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|RdRP gene", "HV69/70 del|RdRP gene\n[bold]C.36.3[/]");
          } else if (coInfection.coiftPathogenNames === "HV69/70 del|L452R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|L452R|RdRP gene", "HV69/70 del|L452R|RdRP gene\n[bold]C.36.3(ή)[/]");
          } else if (coInfection.coiftPathogenNames === "HV69/70 del|L452R") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|L452R", "HV69/70 del|L452R\n[bold]C.36.3(ή)[/]");
          } else if (coInfection.coiftPathogenNames === "K417N|L452R") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("K417N|L452R", "K417N|L452R\n[bold]AY.1&2(δ+)[/]");
          } else if (coInfection.coiftPathogenNames === "K417N|L452R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("K417N|L452R|RdRP gene", "K417N|L452R|RdRP gene\n[bold]AY.1&2(δ+)[/]");
          } else if (coInfection.coiftPathogenNames === "K417N|L452R|P681R") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("K417N|L452R|P681R", "K417N|L452R|P681R\n[bold]AY.1&2(δ+)[/]");
          } else if (coInfection.coiftPathogenNames === "K417N|L452R|P681R|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("K417N|L452R|P681R|RdRP gene", "K417N|L452R|P681R|RdRP gene\n[bold]AY.1&2(δ+)[/]");
          } else if (coInfection.coiftPathogenNames === "HV69/70 del|K417N|N501Y|RdRP gene") {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("HV69/70 del|K417N|N501Y|RdRP gene", "HV69/70 del|K417N|N501Y|RdRP gene\n[bold]B.1.1.529(ο)[/]");
          } else {
            coInfection.coiftPathogenNames = coInfection.coiftPathogenNames.replace("E484K|RdRP gene", "E484K|RdRP gene\n[bold]P.2(ζ)|B.1.526|B.1.1.318|B.1.618|AV.1[/]").replace("K417N|RdRP gene", "K417N|RdRP gene\n[bold]B.1.351(β)[/]").replace("K417T|RdRP gene", "K417T|RdRP gene\n[bold]P.1(γ)[/]").replace("F490S|L452Q", "F490S|L452Q\n[bold]C.37(λ)[/]");
          }
        } else {
          coInfection.coiftPathogenNames = coInfection.coiftPathogenNames
            .replace("HV69/70 del|N501Y|RdRP gene\n[bold]B.1.1.7(α)[/]", "HV69/70 del|N501Y|RdRP gene")
            .replace("HV69/70 del|N501Y\n[bold]B.1.1.7(α)[/]", "HV69/70 del|N501Y")
            .replace("E484K|N501Y|RdRP gene\n[bold]P.1(γ)|B.1.351(β)|P.3(θ)[/]", "E484K|N501Y|RdRP gene")
            .replace("E484K|N501Y\n[bold]P.1(γ)|B.1.351(β)|P.3(θ)[/]", "E484K|N501Y")
            .replace("E484K|K417N|N501Y|RdRP gene\n[bold]B.1.351(β)[/]", "E484K|K417N|N501Y|RdRP gene")
            .replace("E484K|K417N|N501Y\n[bold]B.1.351(β)[/]", "E484K|K417N|N501Y")
            .replace("E484K|K417T|N501Y|RdRP gene\n[bold]P.1(γ)[/]", "E484K|K417T|N501Y|RdRP gene")
            .replace("E484K|K417T|N501Y\n[bold]P.1(γ)[/]", "E484K|K417T|N501Y")
            .replace("L452R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3|C.36.3[/]", "L452R|RdRP gene")
            .replace("L452R|P681R\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3[/]", "L452R|P681R")
            .replace("L452R|P681R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3[/]", "L452R|P681R|RdRP gene")
            .replace("P681R|RdRP gene\n[bold]B.1.617.1(κ)|B.1.617.2(δ)|B.1.617.3|AY.1&2(δ+)[/]", "P681R|RdRP gene")
            .replace("L452R|RdRP gene|W152C\n[bold]B.1.427/429(ε)[/]", "L452R|RdRP gene|W152C")
            .replace("L452R|W152C\n[bold]B.1.427/429(ε)[/]", "L452R|W152C")
            .replace("E484K|RdRP gene\n[bold]P.2(ζ)|B.1.526|B.1.1.318|B.1.618|AV.1[/]", "E484K|RdRP gene")
            .replace("E484K|HV69/70 del|RdRP gene\n[bold]B.1.525(ή)[/]", "E484K|HV69/70 del|RdRP gene")
            .replace("E484K|HV69/70 del\n[bold]B.1.525(ή)[/]", "E484K|HV69/70 del")
            .replace("HV69/70 del|RdRP gene\n[bold]C.36.3[/]", "HV69/70 del|RdRP gene")
            .replace("HV69/70 del|L452R|RdRP gene\n[bold]C.36.3[/]", "HV69/70 del|L452R|RdRP gene")
            .replace("HV69/70 del|L452R\n[bold]C.36.3[/]", "HV69/70 del|L452R")
            .replace("K417N|RdRP gene\n[bold]B.1.351(β)[/]", "K417N|RdRP gene")
            .replace("K417T|RdRP gene\n[bold]P.1(γ)[/]", "K417T|RdRP gene")
            .replace("K417N|L452R\n[bold]AY.1&2(δ+)[/]", "K417N|L452R")
            .replace("K417N|L452R|RdRP gene\n[bold]AY.1&2(δ+)[/]", "K417N|L452R|RdRP gene")
            .replace("K417N|L452R|P681R\n[bold]AY.1&2(δ+)[/]", "K417N|L452R|P681R")
            .replace("K417N|L452R|P681R|RdRP gene\n[bold]AY.1&2(δ+)[/]", "K417N|L452R|P681R|RdRP gene")
            .replace("HV69/70 del|K417N|N501Y|RdRP gene\n[bold]B.1.1.529(ο)[/]", "HV69/70 del|K417N|N501Y|RdRP gene")
            .replace("F490S|L452Q\n[bold]C.37(λ)[/]", "F490S|L452Q");
        }
      });
    chart = options.createXYChart("chartdiv", chartData, null, null, handleLoading);

    // X축
    let xAxis;
    if (type === "line" || type === "area") {
      xAxis = options.createDateAxis(chart, header.periodType, xMinGridDistance, showTitle ? header.x.title : null);
    } else {
      xAxis = options.createCategoryAxis(chart, header.x.key, xMinGridDistance, showTitle ? header.x.title : null, header.x.disabled, header.x.labelName, sizeChanged);
      // if(!header.x.disabled)
      xAxis.renderer.labels.template.adapter.add("textOutput", (text) => {
        return typeof text !== typeof undefined ? text.replace(/(\|)/g, "\n") : text;
      });
    }

    // Range
    // let groupRangePixelWidth = 0;
    if (header.r) {
      if (header.r.groupKey) {
        options.createCategoryRanges(chart, xAxis, header.x.key, header.r.groupKey, header.r.dy, header.r.groupLabel, false, 0, false, nameFlag, type, header.r.key);
      } else {
        options.createCategoryRanges(chart, xAxis, header.x.key, header.r.key, header.r.dy ? header.r.dy * 2 : 20, nameFlag ? header.r.key : false, false, 0, nameFlag ? false : type === "candle", nameFlag, type);
      }
    }

    // Y축
    let createdAxisY = {};
    header.y.axis.forEach((ax, i) => {
      let createdY = options.createValueAxisY(chart, ax.title, ax.opposite);
      if (i > 0) createdY.renderer.grid.template.disabled = true; //첫번째 축만 그리드 표시
      createdAxisY[ax.name] = createdY;
    });

    /*
        let valueAxis = createdAxisY['coiftNumAxis'];
        let valueAxis2 = createdAxisY['coiftRateAxis'];
        // valueAxis2.syncWithAxis = valueAxis; //https://www.amcharts.com/docs/v4/reference/valueaxis/#syncWithAxis_property
        valueAxis2.numberFormatter = new am4core.NumberFormatter();
        valueAxis2.numberFormatter.numberFormat = "#'%'"
     */
    // let valueAxis = options.createValueAxisY(chart, header.y.title);
    // let breakAxis = options.addAxisBreak(valueAxis, 1, 2);
    // options.createGuideY(valueAxis, limitLineValue);

    let tooltipDataRange = toolbarData === "number" ? "n" : toolbarData === "percent" ? "%" : "";
    /* 시리즈 설정 */
    let createdSeries = {};
    let createdLineSeries = [];
    header.y.series.forEach((s, i) => {
      let createdS;
      switch (s.type) {
        case "COLUMN":
          if (grayFlag) {
            chart.colors.list = [am4core.color("#828282")];
          }

          // createdS = options.createCategoryColumnAndColumnLegend(chart, header.x.key, s.key, true, s.name, createdAxisY[s.axisName], xAxis, numberFlag);
          // createdS = options.createCategoryColumnSeries(chart, header.x.key, s.key, true, s.name, createdAxisY[s.axisName], `[bold]{categoryX} (${tooltipDataRange})[/] : {valueY.value}`);
          if (isIndividualGroup) {
            createdS = options.createCategoryColumnSeries(chart, header.x.key, s.key, true, s.name, createdAxisY[s.axisName], `[bold]{${header.r.key}} (${tooltipDataRange})[/] : {valueY.value}`, `{${header.x.labelName}}`, numberFlag);
          } else {
            createdS = options.createCategoryColumnSeries(chart, header.x.key, s.key, true, s.name, createdAxisY[s.axisName], `[bold]{categoryX} (${tooltipDataRange})[/] : {valueY.value}`, null, numberFlag);
          }

          options.setColorForEachItem(chart, createdS, "stroke");
          options.setColorForEachItem(chart, createdS, "fill");

          // if(showLegend) //ColumnSeries DataItem Legend 추가
          //   options.createCustomItemLegend(chart, createdS, header.x.key, xAxis, 'right', true);

          break;
        case "LINE":
          if (grayFlag) {
            chart.colors.list = [am4core.color("#828282")];
          }

          let groupKeyset = [];
          let pathogenKeyset = [];
          chart.data.forEach((line, i) => {
            let trendTooltipDate = `{experimentDate${xFormat ? '.formatDate("' + xFormat + '")' : ""}}`;
            let trendTooltipText = `[bold]{name} (${tooltipDataRange})[/] : {valueY}`;
            let l_series = options.createDateLineSeries(chart, line[s.column], JSON.parse(line.trendData), s.key, "ymd", false, null, trendTooltipText, trendTooltipDate);

            if (isIndividualGroup) {
              //동일 coinfection끼리 같은 색으로
              let lineColorIdx = pathogenKeyset.indexOf(line[s.coiftColumn]);
              if (lineColorIdx < 0) {
                pathogenKeyset.push(line[s.coiftColumn]);
                lineColorIdx = pathogenKeyset.length - 1;
              }
              l_series.stroke = chart.colors.getIndex(lineColorIdx);

              //동일 그룹끼리 같은 모양의 bullet으로
              let bulletPathIdx = groupKeyset.indexOf(line[s.groupColumn]);
              if (bulletPathIdx < 0) {
                groupKeyset.push(line[s.groupColumn]);
                bulletPathIdx = groupKeyset.length - 1;
              }
              // let l_bullet = options.createSpriteBullet(l_series, l_series.stroke, bulletPathIdx);
            } else {
              // let l_bullet = options.createBullet(l_series, am4core.Circle, 10, 10, null, null, am4core.color("#fff"), 0, 0);
            }

            createdLineSeries.push(l_series);
          });
          setSeriesArray([...createdLineSeries]);
          break;
        case "AREA": //Stacked Area Chart
          if (grayFlag) {
            chart.filters.push(new am4core.DesaturateFilter());
          }

          let allTrendDates = {};
          chart.data.forEach((line, i) => {
            let _tdatas = JSON.parse(line.trendData);
            _tdatas.forEach((t, i) => {
              if (!Object.keys(allTrendDates).includes(t.ymd)) {
                allTrendDates[t.ymd] = { ymd: t.ymd, experimentDate: t.experimentDate, coiftCases: 0, coiftRate: 0, disabled: true };
              }
            });
          });
          chart.data.forEach((line, i) => {
            let trendTooltipDate = `{experimentDate${xFormat ? '.formatDate("' + xFormat + '")' : ""}}`;
            let trendTooltipText = `[bold]{name} (${tooltipDataRange})[/] : {valueY}`;
            let l_series = options.createDateStackedAreaLineSeries({ ...allTrendDates }, chart, line[s.column], JSON.parse(line.trendData), s.key, "ymd", false, null, trendTooltipText, trendTooltipDate);
            createdLineSeries.push(l_series);
          });
          setSeriesArray([...createdLineSeries]);
          break;
        case "CANDLE":
          if (grayFlag) {
            chart.colors.list = [am4core.color("#828282")];
          }

          let candleTooltip = "{coiftPathogenNames}";
          let candleBulletTooltip = "{column.coiftPathogenNames}";
          if (isIndividualGroup) {
            candleTooltip = `{groupInfo}
                            {coiftPathogenNames}`;
            candleBulletTooltip = `{column.groupInfo}
                                  {column.coiftPathogenNames}`;
          }
          createdS = options.createCategoryCandlestickSeries(chart, "categoryIdx", "minimumChart", "lowerQuartile", "upperQuartile", "maximumChart", "[bold]{pathogenName} ({detctRsltDvsnCode})[/]", candleTooltip);
          options.setColorForEachItem(chart, createdS, "stroke");
          // let topSeries = options.createCategoryStepLineSeries(chart, "categoryIdx", "maximumChart", am4core.color("#aaa"));
          // let medianaSeries = options.createCategoryStepLineSeries(chart, "categoryIdx", "medianChart", am4core.color("#aaa"));
          // let meanSeries = options.createCategoryStepLineSeries(chart, "categoryIdx", "averageChart", am4core.color("#aaa"), true);
          // let bottomSeries = options.createCategoryStepLineSeries(chart, "categoryIdx", "minimumChart", am4core.color("#aaa"));
          options.createCircleBulletForCTchart(chart, "pathogenValuesArray", "[bold]{column.pathogenName} ({column.detctRsltDvsnCode})[/] : ", candleBulletTooltip, null, dotFlag);
          break;
        default:
          break;
      }
      createdSeries[s.name] = createdS;
    });

    // Legend
    if (showLegend && type === "line") {
      let legend = options.createLegend(chart, "right", true);
      // let legend = options.createCustomLegend(chart, 'right', true);

      //legend에 hover시 line 두껍게 (현재 라인차트만 레전드를 표시한다.)
      let toggleSeries = (hoverSeries, over) => {
        hoverSeries.toFront();
        hoverSeries.segments.each((segment) => {
          segment.setState(over);
        });
      };
      legend.itemContainers.template.events.on("over", (ev) => {
        ev.target.dataItem.dataContext.bulletsContainer.zIndex = 10;
        toggleSeries(ev.target.dataItem.dataContext, "hover");
      });
      legend.itemContainers.template.events.on("out", (ev) => {
        ev.target.dataItem.dataContext.bulletsContainer.zIndex = 1;
        toggleSeries(ev.target.dataItem.dataContext, "default");
      });
      legend.itemContainers.template.events.on("toggled", (ev) => {
        ev.target.dataItem.marker.children.getIndex(1).setState("default");
      });
    } else if (showLegend && type === "area") {
      // Stacked Area Chart Legend
      let legend = options.createStackedAreaLegend(chart, "right", true, null, null, true, false);
    } else if (showLegend && type === "bar") {
      let legend = options.createCustomLegend(chart, "right", true, null, null, true, true);
      legend.itemContainers.template.tooltipText = "{name}";
      let coinfectionSeries = createdSeries["coiftSeries"];
      coinfectionSeries.events.on("dataitemsvalidated", function (ev) {
        var legenddata = [];
        coinfectionSeries.columns.each(function (column) {
          legenddata.push({
            // name: `${nameFlag && column.dataItem.dataContext.coiftPathogenNamesOrg!==undefined?column.dataItem.dataContext.coiftPathogenNamesOrg:column.dataItem.dataContext.coiftPathogenNames}${isIndividualGroup ? '('+column.dataItem.dataContext.groupInfo+')' : ''}`,
            name: `${column.dataItem.dataContext.coiftPathogenNames}${isIndividualGroup ? "(" + column.dataItem.dataContext.groupInfo + ")" : ""}`,
            fill: column.fill,
            seriesDataItem: column.dataItem,
          });
        });
        legend.data = legenddata;
      });
    }

    if (showScroll) {
      // 스크롤바
      if (type === "line" || type === "area") {
        options.createScrollbarX(chart, false, 0, 1);
      } else {
        options.createScrollbarX(chart, false);
      }
      options.createScrollbarY(chart, false, 0, 1);
    }

    // 차트영역 드래그 액션 (스크롤 안만들면 가이드라인 X, 드래그 X)
    options.createCursor(chart, showScroll ? "zoomX" : "none", !showScroll, true);

    options.createExportChart(chart, exportMenuRef.current, exportFileName, handleLoading, exportFilter);

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data, caseVersion, type, toolbarData, viewTable, numberFlag, dotFlag, sizeChanged, grayFlag, nameFlag, sortFlag]);

  //Legend를 만들 수 없는 경우 show/hide 필터
  /* const toggleDataItem = (index, item) => {
    let _chart = chartRef.current;
    let new_dataprovider = _chart.data.slice();

    let hasIndex = new_dataprovider.indexOf(item);
    if(hasIndex > -1) {
      new_dataprovider.splice(hasIndex, 1); //hide
    } else {
      new_dataprovider.push(item);
      new_dataprovider.sort((a,b) => (a.coiftCases < b.coiftCases) ? 1 : ((b.coiftCases < a.coiftCases) ? -1 : 0));
    }
    _chart.data = new_dataprovider;
    _chart.validateData();
  } */
  return (
    <>
      <div id="chartdiv" style={{ width: width, height: height }}></div>
      {viewTable && data &&
      <CoinfectionTable
        isTrend={type === "line" || type === "area"}
        title={toolbarData === "number" ? "n" : toolbarData === "percent" ? "%" : "CT"}
        tableData={data}
        showGroup={isIndividualGroup}
        chartType={type}
        />
        }
      {/* <div>
          {data && data.coinfectionByCount && (
            data.coinfectionByCount.map((coinft, index) => (
              <span key={index}>
              <button type="button" onClick={() => toggleDataItem(index, coinft)}>{coinft.coiftPathogenCodes}</button><br/>
              </span>
            ))
          )}
        </div> */}
    </>
  );
};

CoinfectionChart.defaultProps = {
  showTitle: false,
  showLegend: false,
  isStepLine: false,
};

export default CoinfectionChart;
