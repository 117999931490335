import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Styled from 'styled-components';
import LogoIcon from 'assets/svg/icon_landingLogo.svg';
import TextIcon from 'assets/svg/icon_seegene.svg';
import LoginIcon from 'assets/svg/icon_login.svg';
import SignIcon from 'assets/svg/icon_sign up.svg';
import * as mixins from 'assets/styled/Mixin.styled';
import * as baseActions from 'store/modules/base';


export const LandingPageHeader = () => {
  const history = useHistory();
  const BaseActions = bindActionCreators(baseActions, useDispatch());
  const [sgstatsToken, setSgstatsToken] = useState(localStorage.getItem('sgstatsToken'));

  const appPath = () => {
    if (
        sgstatsToken === '' ||
        sgstatsToken === null ||
        sgstatsToken === undefined
      ) {
        history.push('/member/login');
    } else {
      history.push('/');
    }
  }

  const authPath = () => {
    if (
        sgstatsToken === '' ||
        sgstatsToken === null ||
        sgstatsToken === undefined
      ) {
        history.push('/member/login');
    } else {
      BaseActions.logout();
    }
  }

  const logout = () => {
    BaseActions.logout();
    setSgstatsToken(null)
  }

  return (
    <LandingHeaderWrapper>
      <Logo onClick={() => history.push('/landing')} title="GO MAIN"/>
      <Gnb>
        <Button onClick={appPath} title="GO PAGE">GO TO APPLICATION</Button>
        <Nav>
          {
             sgstatsToken === '' ||
             sgstatsToken === null ||
             sgstatsToken === undefined ? (
              <>
                <NavItem onClick={authPath} title="LOGIN">LOGIN</NavItem>
                <NavItem onClick={() => history.push('/signup/agree')} title="SIGN UP" style={{marginLeft:'25px'}}>SIGN UP</NavItem>
              </>
             ) : (
              <NavItem onClick={logout} title="LOGIN">LOGOUT</NavItem>
             )
          }
        </Nav>
      </Gnb>
    </LandingHeaderWrapper>
  )
}

const LandingHeaderWrapper = Styled.div`
  display:flex;
  justify-content:space-between;
  position:relative;
  z-index:10;
  height:24px;
`;

const Logo = Styled.h1`
  position:relative;
  margin-left:28px;
  width:92px;
  height:24px;
  background:url('${TextIcon}')no-repeat center;
  cursor:pointer;

  &:before {
    ${mixins.afterBefore};
    position:absolute;
    left:-28px;
    top:1px;
    width:22px;
    height:22px;
    background:url('${LogoIcon}')no-repeat center;
  }
`;

const Gnb = Styled.nav`
  display:flex;
  align-items:center;
`;

const Nav = Styled.ul`

`;

const NavItem = Styled.li`
  display:inline-block;
  padding-left:28px;
  height:20px;
  color:#fff;
  font-size:14px;
  font-weight:800;
  background:url('${SignIcon}')no-repeat left center;
  cursor:pointer;
  &:first-child {
    background-image: url('${LoginIcon}');
  }
`;




const Button = Styled.button`
  width:250px;
  border-radius:80px;
  background:transparent linear-gradient(90deg, #FE5930 0%, #D91E1E 100%) 0% 0% no-repeat padding-box;
  color:#fff;
  margin-right:30px;
`;