import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useForm } from "react-hook-form";
import { resetPassword } from 'lib/api';
import { isEmpty } from 'lodash';
import { useKeypress } from "lib/common";
import {StyledReset} from './ResetPassword.styled';

const ResetPassword = ({lnb}) => {
  const { history, location } = useReactRouter();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const [ errorMessage, setErrorMessage ] = useState('');
  const { handleSubmit, getValues, register, watch, errors, setError, clearErrors } = useForm();
  const watchNewPassword = watch("newPassword");
  const watchRetypePassword = watch("retypePassword");
  const onSubmit = () => {
    if(!validateForm()) {
      return;
    }

    const password = getValues("newPassword");
    (async() => {
      try {
        await resetPassword(token, password).then(() => {
          history.push("/member/gomain?type=reset");
        });
      } catch (err) {
        alert(err.response.data);
      }
    })();
  };

  useEffect(() => {
    if(!isEmpty(errors)) {
      const message = errors[Object.keys(errors)[0]].message;
      if(errorMessage !== message) {
        setErrorMessage(message);
      }
    }else {
      if(errorMessage) {
        setErrorMessage('');
      }
    }
  }, [errors.newPassword, errors.retypePassword]);

  useEffect(() => {
    validateForm();
  }, [watchNewPassword, watchRetypePassword]);

  useKeypress('Escape', () => {
    history.push("/"); 
  });

  const validateForm = () => {
    return checkpassword(watchNewPassword) && comparePassword();
  }

  const checkpassword = (pass="") => {
    let chk = 0;
    let text = ''
    let ruleCheck= false;
    let pattern1 = /[0-9]/;
    let pattern2 = /[a-zA-Z]/;
    let pattern3 = /[!@#$%^&*()?_~]/;
    if(pass.search(/[0-9]/g) !== -1 ) chk ++;
    if(pass.search(/[a-zA-Z]/ig)  !== -1 ) chk ++;
    if(pass.search(/[!@#$%^&*()?_~]/g)  !== -1  ) chk ++;
    if(pass === "" ){
      text = "";
    } else if(pass.length < 8 ){
        text = "Please enter at least 8 digits.";
    } else if(chk < 2){
        text = "at least two numbers, English, special characters.";
    } else if(pattern1.test(pass) && pattern2.test(pass) && pattern3.test(pass) && pass.length < 8) {
        text = "alphabet+number+special character, 8 digits or more.";
    } else if(pattern1.test(pass) && pattern2.test(pass) && !pattern3.test(pass) && pass.length < 10) {
        text = "alphabet characters, 10 or more digits.";
    } else {
        text = "";
        ruleCheck= true;
    }

    if(!ruleCheck) {
      setError("newPassword", {
        type: "validate",
        message: text
      })
    }else {
      clearErrors("newPassword")
    }

    return ruleCheck;
  }

  const comparePassword = () => {
    if(watchNewPassword !== watchRetypePassword) {
      setError("retypePassword", {
        type: "validate",
        message: "Passwords do not match."
      })

      return false;
    }else {
      clearErrors("retypePassword")
    }

    return true;
  }

  return(
    <>
      <StyledReset lnb={lnb}>
        <div className="contents">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="centerwrap">
              <strong>Enter a new password</strong>
              <div className="layoutBox">
                <p className="warn">
                  Please enter at least 8 characters including letters and numbers.
                </p>
              </div>
              <span className="password-field">
                <label>New Password</label>
                <input 
                  type="password" 
                  placeholder="*************" 
                  title="Write down your Password" 
                  name="newPassword"
                  ref={register()}
                  />
              </span>
              <span className="password-field">
                <label>Retype Password</label>
                <input 
                  type="password" 
                  placeholder="*************" 
                  title="Write down your Password" 
                  name="retypePassword"
                  ref={register()}
                  />
              </span>
              <div className="error-field">
                  <p className="error-message case01">
                    {errorMessage}
                  </p>
                  {/* <p className="error-message case02">
                    Passwords do not match.
                  </p> */}
                </div>
              <button 
                className="btn-gray" 
                type="button" 
                title="Send"
                onClick={handleSubmit(onSubmit)}
                >RESET PASSWORD</button>
            </div>
          </form>
        </div>
      </StyledReset>
    </>
  )
}

export default ResetPassword;
