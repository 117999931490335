import { css } from "styled-components";

export const mq = (view) => {
	const breakpoints = [
		['mobile', 'max-width: 1023px'],
		['tablet', 'max-width:1279px'],
		['desk', 'min-width: 1280px'],
	];
	const mediaQuery = new Map(breakpoints);
	let viewSize = mediaQuery.get(view);
	let styles='';
	styles+=`
		@media (${viewSize})
	`;
	return css`${styles}`;
}

const positionParms = (args) => {
	const position = [];
	const value = [];
	let offset = args.split(" ");
	const direction = ['top', 'bottom', 'left', 'right'];
	offset.forEach(item => {
		if(direction.indexOf(item) > -1) {
			position.push(item);
		}else {
			value.push(item);
		}
	});
	let TopBottom = position[0] + ":" + value[0];
	let LeftRight = position[1] + ":" + value[1];
	return [TopBottom, LeftRight];
}

export const absolute = (args) => {
	let position = positionParms(args);	
	return (
		css`
			position:absolute;
			${position[0]};
			${position[1]};
		`
	)
}

export const fixed = (args) => {
	let position = positionParms(args);	
	return (
		css`
			position:fixed;
			${position[0]};
			${position[1]};
		`
	)
}

export const fontSet = (fontColor, fontSize) => css`
	color:${fontColor};
	font-size:${fontSize};
`
export const afterBefore = () => css`
	display:inline-block;
	content:'';
`
export const textHidden = () => css`
	font-size:0;
	text-indent:-99999px;
`
export const ellipsis = () => css`
	display:inline-block;
	overflow:hidden;
	vertical-align:middle;
	white-space:nowrap;
	word-break:break-all;
	text-overflow:ellipsis;
	-o-text-overflow: ellipsis;
`
export const clearfix = (selector) => css`
	&::${selector} {
		content:'';
		clear:both;
		display:block;
		overflow:hidden;
	}
`
export const flex = (justify, align) => css`
	display:-webkit-flex;
	display:-moz-flex;
	display:-ms-flex;
	display:flex;
	justify-content:${justify};
	align-items:${align};
`
export const placeHolder = () => css`
	color:#A7ADB4; 
	font-size:14px; 
	font-weight:200;
	line-height:40px;
`

// @mixin setBgColor {
//   $target: ( 
//     "erdrpn": #3dc9ba,
//     "en": #ff7781,
//     "erdrp": #51a7ff,
//     "rdrpn": #ffb02c,
//     "rdrp": #ff92ca,
//     "n": #7d86fc,
//     "e": #b180d0,
//     "negative": #999999,
//     "flua": #ffceb0,
//     "flub": #a6de60,
//     "rsv": #f87efa,
//     "mpv":#3597cb,
//     "hrv": #7d39bb,
//     "adv": #fce148,
//     "piv": #f04b67,
//   );
//   @content;
//   @each $name, $color in $target {
//     &.pro-#{$name}:before {
//       background-color:$color
//     }
//   }
// }

export const btnOrange = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#e56a4b;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.1s;
	&:hover {
		background:#f29179;
	}
	&:active {
		background:#d46143;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnGray = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#b3b4c1;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#d0d1dc;
	}
	&:active {
		background:#9a9baa;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnDarkGray = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#8c8e9b;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#acaebb;
	}
	&:active {
		background:#707281;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnLgihtGray = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#ebeef1;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#354554;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#eef1f3;
	}
	&:active {
		background:#d7dbde;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnNavy = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#354554;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#4E6275;
	}
	&:active {
		background:#24313e;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnBlueGray = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#a7adb4;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#bdc3c9;
	}
	&:active {
		background:#8d96a1;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnWhite = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#fff;
	border:1px solid #e4e7ec;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#354554;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#f2f5f8;
	}
	&:active {
		background:#e0e7ee;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`
export const btnBlue = (width, height) => css`
	display:inline-block;
	width:${width? width:"90px"};
	height:${height? height:"30px"};
	background:#6D8398;
	border:1px solid #e4e7ec;
	border-radius:2px;
	text-align:center; 
	line-height:${height? height:"30px"};
	vertical-align:middle;
	color:#fff;
	font-size:12px;
	font-weight:800;
	transition:background 0.2s;
	&:hover {
		background:#94a8bb;
	}
	&:active {
		background:#54697d;
	}
	&.disable {
		background:#e7edf3;
		color:#c3cbd4;
	}
`