import React from 'react';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useKeypress } from "lib/common";
import {StyledEmailSent} from './EmailSent.styled';

const EmailSent = ({lnb}) => {
  const logged = useSelector(state => state.base.get('logged'));
  const { history } = useReactRouter();
  
  useKeypress('Escape', () => {
    history.push('/');
  });

  return(
    <>
      <StyledEmailSent lnb={lnb}>
        <div className="contents">
          <div className="centerwrap">
            <strong>We sent the mail, check it out.</strong>
            <button 
              className="btn-gray" 
              type="button" 
              title="Go Sign"
              onClick={e => {
                history.push('/');
              }}
              >{ logged ? 'GO TO MAIN PAGE' : 'GO TO SIGN IN' }</button>
          </div>
        </div>
      </StyledEmailSent>
    </>
  )
};

export default EmailSent;
