import styled from 'styled-components';
import * as mixins from '../../assets/styled/Mixin.styled';
import WARN from '../../assets/svg/icon_warn.svg'

export const StyledChange = styled.div `
  width:100%;
  .centerwrap{
    .layoutBox{
      strong{
        display:block;
        margin-bottom:20px;
        line-height:1;
        text-align: center;
        color:#354554;
        font-size:26px;
        font-weight:800;
      }
    }
      
    .warn {
      position:relative;
      margin-bottom:40px;
      text-align: center;
      color:#59697A;
      font-size:16px;
      font-weight:400;
      text-indent: 20px;

      &:before{
        ${mixins.afterBefore()};
        ${mixins.absolute('top 4px left 85px')};
        width:16px;
        height:16px;
        background:url('${WARN}')no-repeat;
      }
    }
    
    .email-field{
      display:block;
      margin-bottom:8px;
    }
    .error-field{
      margin-bottom:20px;
      height:20px;
      .error-message{
        text-align:left;
        color:#E91313;
        font-size:14px;
        font-weight:400;
      }
    }
  }

`;
