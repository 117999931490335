import React, { useState, useEffect} from 'react';
import { InputText, InputRadio } from 'components';
import ProfileImage from './ProfileImage';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import _ from 'lodash';
import Plus from '../../assets/svg/icon_interest_add.svg';
import Checked from '../../assets/svg/icon_check.svg';
import { Link } from 'react-router-dom';
import {useLocation, useHistory} from "react-router-dom";
import axios from "axios";


function getBackground() {
  const colorList = {
    RP: "#98E3FF", GI: "#FFCA7D", STI: "#FFCDCD", HPV: "#E4C7F9", TB: "#CAD3FE", DR: "#B6F4E4", Meningitis: "#BCEDA1"
  }
  let background = '';
  for (let i in colorList) {
      background += `
        &.${i} input[type=checkbox]:checked + label{
          background: ${colorList[i]};
      }
    `
  }
  return background;
}

const StepRegister = (props) => {
  const [errDetMsg, setErrDetMsg] = useState({mbrId:null, mbrPwd:null, reTypeMbrPwd:null, email:null});
  const [errCheckMsg, setErrCheckMsg] = useState(''); // Check for unfilled areas
  const {natInfo} = useLocation(), history=useHistory();
  const [checkFlag, setCheckFlag] = useState(false);
  natInfo || history.replace("/signup/agree");
  const [inputVal, setInputVal] = useState({
    mbrId:null
    ,mbrNm:null
    ,mbrPwd:null
    ,email:null
    ,emailCertDt:null
    ,emailCertNo:null
    ,roleCd:null
    ,sgvVerCd:null
    ,useLangCd:"KO"
    ,useYn:true
    ,natAreaCd:natInfo&&natInfo.natAreaCd
    ,natCd:natInfo&&natInfo.natCd
    ,regnNm:natInfo&&natInfo.regnNm
    ,custNm:natInfo&&natInfo.custNm
    ,reTypeMbrPwd:null
    ,tempIntrstPrdts:[]})
    ,[positionRadio, setPositionRadio]=useState(null), [productLines, setProductLines]=useState(null)
    ,[inputImg, setInputImg] = useState({
    fileName:null, mediaType:null, fileBase64String:null
  });
  useEffect(() => {
    setCheckFlag(false);
    setErrDetMsg({ ...errDetMsg, mbrId: null });
    if (!/^[A-Za-z\d_\-.]{5,}$/.test(inputVal.mbrId)) {
      if (inputVal.mbrId && inputVal.mbrId.length < 5) {
        setErrDetMsg({ ...errDetMsg, mbrId: "ID must contain at least 5 digits." });
      } else if (/[`~!@#$%^&*|\\'";:/?^=^+()<>]/.test(inputVal.mbrId)) {
        setErrDetMsg({ ...errDetMsg, mbrId: "Only dot(.), underscore(_), and hyphen(-) special characters are allowed for ID." });
      } else {
        setErrDetMsg({ ...errDetMsg, mbrId: "Only English alphabet characters are allowed for ID." });
      }
    } 
    
    else {
      axios.post("/api/getMbr", { mbrId: inputVal.mbrId }).then((response) => {
        console.log('response', response);
        if (response.data.length > 0) {
          setErrDetMsg({ ...errDetMsg, mbrId: "ID already exists" });
        } if(response.data.length === 0) {
          setErrDetMsg({ ...errDetMsg, mbrId: null });
          setCheckFlag(true);
        }
      });
    }
  }, [inputVal.mbrId]);

  useEffect(()=>{
    setErrDetMsg({...errDetMsg, mbrPwd:null});
    if(!/(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(inputVal.mbrPwd))
      setErrDetMsg({...errDetMsg, mbrPwd:"Please choose a stronger password. Try a mix of letters and numbers. at least 8 characters."});
    else if(inputVal.reTypeMbrPwd!=null && inputVal.mbrPwd!=inputVal.reTypeMbrPwd)
      setErrDetMsg({...errDetMsg, mbrPwd:"Password does not match."});
  }, [inputVal.mbrPwd]);

  useEffect(()=>{
    setErrDetMsg({...errDetMsg, mbrPwd:null});
    if(inputVal.mbrPwd!=inputVal.reTypeMbrPwd) setErrDetMsg({...errDetMsg, mbrPwd:"Password does not match."});
  }, [inputVal.reTypeMbrPwd]);

  useEffect(()=>{
    setCheckFlag(false);
    setErrDetMsg({...errDetMsg, email:null});
    // const exp= /^([a-zA-Z0-9]+?)(@[a-zA-Z0-9]+?)(\.[a-zA-Z0-9]+?)*(\.[a-zA-]+)$/;
    const exp= /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if(inputVal.email) {
      if(!exp.test(inputVal.email)) {
        setErrDetMsg({...errDetMsg, email:"You have entered an invalid e-mail address. Please try again. ex)abc123@sgstats.com"});
      } else {
        axios.post('/api/memberEmailCheck', null,{params:{email:inputVal.email}}).then((response)=>{
          console.log('response', response);
          if(response.data > 0) {
            setErrDetMsg({...errDetMsg, email: "Email already exists."});
          } else {
            setErrDetMsg({...errDetMsg, email: null });
            setCheckFlag(true);
          }
        });
      }
    }
  },[inputVal.email])

  const set=(e)=>{setParse(e, inputVal, setInputVal);}
  , setParse=(e, setObj, setFn, fixNm) =>{
    const {target:{value, name}}=e;
    setFn&&setFn(setObj=>({...setObj, [fixNm||name]:value}));
  }, submit=async(event)=>{
    event.preventDefault();
    if(!inputVal.mbrId || !inputVal.mbrNm || !inputVal.mbrPwd || !inputVal.email) {
      setErrCheckMsg("Check for unfilled areas");
      return;
    } else if(errDetMsg.mbrId || errDetMsg.mbrPwd|| errDetMsg.reTypeMbrPwd ||errDetMsg.email || !checkFlag){
      return;
    }
    try {
      await axios.post("/api/setMbr", {...inputVal, thumbNail:inputImg});
      history.push("/signup/finish")
    } catch (e){alert("failed.");}
  };

  useEffect(()=>{
    axios.post('/api/getCommonCodeDetail',{cdGrpKey:"CM011"}).then(response=>{
      setPositionRadio(response.data);
      setInputVal(inputVal=>({...inputVal,roleCd: "02"}));
    });

    axios.post('/api/getAllProductLines').then(response=>{
      if(!response.data) return;
      setProductLines(
          _(response.data).groupBy('prdtlnGrpNm').map((groups,prdtlnGrpNm) => {
            return {prdtlnGrpNm, ids: groups.map(g => g.id), checked:false}
          }).value()
      );
    });
    /*return () =>{
      setPositionRadio(null);
    }*/
  },[]);
  useEffect(()=>{
    setInputVal(inputVal=>({...inputVal,tempIntrstPrdts:_(productLines).filter("checked").map("ids").flatten().map(o=>{return {prdtlnInfoNo:o}}).value()}));
  },[productLines])
  // 로그인 하지않으면 ItemList 데이터가 들어오지 않습니다 확인 부탁드리겠습니다 => 전병길
  return (
    <StyledStepRegister>
      <div className="user-info">
        <ul>
          <li>
            <ProfileImage callback={(fileObj)=>{
              setInputImg(fileObj);
            }}/>
            <InputText className="item-list" type="text" id="user-info01" label="ID" name="mbrId" errormsg={errDetMsg.mbrId} value={inputVal.mbrId} onChange={set}/>
          </li>
          <li>
            <InputText className="item-list" type="password" id="user-info02" label="Password" name="mbrPwd" autocomplete={false} errormsg={errDetMsg.mbrPwd} value={inputVal.mbrPwd} onChange={set}/>
            <InputText className="item-list" type="password" id="user-info03" label="Retype Password" name="reTypeMbrPwd" autocomplete={false} errormsg={errDetMsg.reTypeMbrPwd} value={inputVal.reTypeMbrPwd} onChange={set}/>
          </li>
          <li>
            <InputText className="item-list" type="email" id="user-info04" label="E-mail" name="email" errormsg={errDetMsg.email} value={inputVal.email} onChange={set}/>
            <InputText className="item-list" type="text" id="user-info05" label="Full Name" name="mbrNm" value={inputVal.mbrNm} onChange={set}/>
          </li>
          <li>
            <span className="radio-field">
              <strong>Position</strong>
              {
                positionRadio&& positionRadio.map((o,i)=>(
                  <InputRadio key={i} className={i==0&&"first-radio"} id={"roleCd_"+i} name="roleCd" label={o.cdVal1} value={o.cdKey1} checkValue={inputVal.roleCd} onChangeCallback={e=>{
                    setParse(e, inputVal, setInputVal, "roleCd");
                  }}/>
                ))}
            </span>
          </li>
        </ul>
      </div>
      <div className="user-interest">
        {productLines&&productLines.map((item, index) => (
          <span className={`checkBox ${item.prdtlnGrpNm}`} key={index}>
          <input
              type="checkbox"
              id={item.prdtlnGrpNm}
              checked={item.checked}
              value={item.prdtlnGrpNm}
              onChange={e=>{
                const _tmp=_.cloneDeep(productLines);
                _.find(_tmp, {prdtlnGrpNm:e.target.value}).checked = e.target.checked;
                setProductLines(_tmp);
              }}
          />
          <label htmlFor={item.prdtlnGrpNm}>{item.prdtlnGrpNm}</label>
          </span>
        ))}
      </div>
      <div className="btnset">
        <p className={`error-message {isSuccess && 'success'}`}>
          <strong>{errCheckMsg}</strong>
        </p>
        <Link
            to={{pathname:"/signup/agree"
              ,natInfo:natInfo&&natInfo
            }} className="btn-previous" title="PREVIOUS" >PREVIOUS</Link>
        <Link to='/signup/finish' className="btn-next" title="NEXT" onClick={submit}>NEXT</Link>
      </div>
    </StyledStepRegister>
  )
};

const StyledStepRegister = styled.div`
  .user-info{
    margin-bottom:30px;
    width:1080px;
    ${mixins.clearfix('after')};
    >ul > li {
      clear:both;
      ${mixins.clearfix('after')};
      > * {
        overflow:hidden;
        width:46.4212%;
        
        > label {
          float: left;
          width: 154px;
          line-height:40px;
          font-size:16px;
        }

        input[type=text],
        input[type=email],
        input[type=password]{
          float: none;
          width:66.0792%;
          height:40px;
        }

        &:nth-child(odd){
          float: left;
        }
        &:nth-child(even){
          float: right;
        }
        &:not(:last-child){
          margin-bottom: 20px;
        }
      }
      &:first-child{
        height:58px;
        line-height:58px;
        label {
          height:58px;
          line-height:58px;
        }
        > span input{
          margin-top:10px;
        }
      }
    }
    
    

    .radio-field{
      position:relative;
      width:100%;
      > strong {
        display: inline-block;
        width: 154px;
        height:40px;
        line-height:40px;
        color: #111;
        font-size: 16px;
        font-weight: 800;
      }

      > span:not(:first-of-type) {
        margin-left:20px;
      }

      input[type=radio] + label::after{
        left: 7px;
        margin-top:-3px;
      }

      input[type=radio] + label::before {
        left:2px;
        margin-top:-8px;
        width:16px;
        height:16px;
      }

      input[type=radio]:checked + label::before{
        border:0;
        background:#e56a4b;
      }

      input[type=radio]:checked + label::after{
        width:6px;
        height:6px;
        background:#fff;
      }
    }
  }

  .user-interest {
    margin-bottom: 30px;
    .checkBox{
        display:inline-block;
        margin-right:10px;
        font-size:16px;
        font-weight:400;

        input[type=checkbox] + label{
          z-index:0;
          padding-left:17px;
          width:132px;
          height:40px;
          background:#fff;
          border: 1px solid #eee;
          border-radius:30px;
          vertical-align:top;
          line-height:40px;
          text-align:left;
          color:#a7adb4;
          
          
          &::before{
            top:50%;
            left:100px;
            transform:translateY(-50%);
            margin-top:0;
            width:16px;
            height:16px;
            background:url('${Plus}')no-repeat;
            border:none;
          }

          &::after{
            display:none;
          }
        }

        input[type=checkbox]:checked + label{
          color:#000;
        }

        input[type=checkbox]:checked + label::before{
          background:url('${Checked}')no-repeat;
        }
        ${getBackground()};
      }
  }

  .error-message {
    display: block;
    width:350px;
    height: 18px;
    margin:0 auto 8px;
    text-align:left;
    strong {
      display:inline-block;
      line-height:18px;
      color: #E91313;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .error-message.success {
    strong {
      color: #30d63e;
    }
  }

  .btnset {
    text-align:center;
    .btn-previous{
      ${mixins.btnWhite('170px', '50px')};
      font-size:16px;
      font-weight:800;
    }
    .btn-next{
      ${mixins.btnGray('170px', '50px')};
      margin-left:12px;
      font-size:16px;
      font-weight:800;
    }
  }
  
`;

export default StepRegister;