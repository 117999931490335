import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from '@apollo/client/utilities';
import { ACCESS_TOKEN } from './constants';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          //toast.error(message);
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        });
    }
  
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'include'
});


const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(ACCESS_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
    }
  }
});


const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies : {
        Query : {
          fields : {
            getMemberNotifications : relayStylePagination()
          }
        }
      }
    })
});


export default client;