import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as mixins from 'assets/styled/Mixin.styled';
import Icon_add from 'assets/svg/icon_add.svg';
import Icon_check from 'assets/svg/icon_check.svg';
const { REACT_APP_APP_HOST: appHost} = process.env;

const StyledInputCheck = styled.span`
  display:inline-block;
  position:relative;
  overflow:hidden;
  margin: 0 3px 5px 0;
  height:24px;
  border: 1px solid #eee;
  border-radius:30px;
  vertical-align:middle;
  
  input[type=checkbox] + label {
    display:inline-block;
    width:100%;
    padding:0 22px 0 10px; 
    background:${({theme}) => theme.popup.grouppop.buttonBackground};
    border-radius:30px;
    line-height:24px;
    ${mixins.fontSet('#A7ADB4', '12px')};
    font-weight:800; 
    &:before {
      ${mixins.afterBefore()};
      ${mixins.absolute('top 50% right 5px')};
      left:auto;
      bottom:auto;
      width:14px;
      height:14px;
      margin:-7px 0 0 0;
      border:0;
      background:url('${appHost}${Icon_add}') no-repeat 50% / 12px auto;
    }
    &:hover {
      background-color:#f2f5f8;
    }
    &:active {
      background-color:#e6eaef;
    }
  }
  input[type=checkbox]:checked + label{
    background:${({theme}) => theme.popup.grouppop.buttonActive};
    color:#fff;
    &:before {
      background-image:url('${appHost}${Icon_check}');
    }
  }
`;

const InputCheck = ({id, name, checked, className, onChangeCallback, analysisType}) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(()=>{
    setChecked(false);
  }, [analysisType]);

  useEffect(()=>{
    setChecked(checked);
  }, [checked]);

  const handleChange = (e) => {
    if (onChangeCallback) {
      onChangeCallback(e);
    }      
    setChecked(e.target.checked);  
  }

  return (
    
    <StyledInputCheck className={className}>
      <input type="checkbox" name={name} id={id} checked={isChecked} onChange={(e) => handleChange(e)} />
      <label htmlFor={id}>{name}</label>
    </StyledInputCheck>
  ); 
}

export default InputCheck;
