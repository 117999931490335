import React, { useRef, useEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useState } from 'react';

am4core.useTheme(am4themes_animated);
//license
am4core.options.autoDispose = true;
am4core.options.commercialLicense = true;

const MapClusterChart = ({id, data, size, bgColor, loading}) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    /*
    * Total bgColor
    * 
    * RV :#E91313
    * GI: #F3B508
    * STI : #72C124
    * HPV : #F53280
    * Meni : #0C9EFF;
    */
    
    /*
    * Positive bgColor
    * 
    * RV : #F9C5C5
    * GI: #FCEDC3
    * STI : #DCEFC9
    * HPV #FCCDE0
    * Meni : #C4E7FF
    */
    if(!data || data.length == 0) {
      return;
    }

    const subBg = (bgColor) => {
      switch (bgColor) {
        case '#E91313' :
          return '#F9C5C5'
        case '#F3B508' :
          return '#FCEDC3'
        case '#72C124' :
          return '#DCEFC9'
        case '#F53280' :
          return '#FCCDE0'
        case '#0C9EFF' :
          return '#C4E7FF'
      }
    }
    // Create chart instance
    let chart = am4core.create(id, am4charts.PieChart);

    // Data percent value
    let percentValue = data?.positiveCases / data?.totalTestCases * 100;

    // Add data
    chart.data = [{
      "category": "Total",
      "count": (100 - percentValue),
      "color": am4core.color(subBg(bgColor))
    }, {
      "category": "Positive",
      "count": percentValue,
      "color": am4core.color(bgColor)
    }]

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "category";
    pieSeries.startAngle = 270;
    pieSeries.endAngle = -90;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = '';
    // pieSeries.labels.template.radius = am4core.percent(-25);
    // pieSeries.labels.template.fill = am4core.color("white");
    
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.fillOpacity = 1;
    pieSeries.slices.template.tooltipText = '';

    // This creates initial animation
    // pieSeries.hiddenState.properties.opacity = 1;
    // pieSeries.hiddenState.properties.startAngle = 270;
    // pieSeries.hiddenState.properties.endAngle = -90;
    chart.hiddenState.properties.radius = am4core.percent(0);

    // pieSeries Slice Hover Event
    pieSeries.slices.template.fillOpacity = 1;
    let hovered = pieSeries.slices.template.states.getKey("hover");
    hovered.properties.scale = 1;
    hovered.properties.fillOpacity = 0.8;

    chartRef.current = chart;

  }, [data])
  
  return (
    <>
      <div id={id} style={{width: size, height: size}}></div>
    </>
  )
}

MapClusterChart.defaultProps = {
  showTitle: false
};

export default MapClusterChart;