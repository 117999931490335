import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_LINES = gql`
  query getAllProductLines {
    getAllProductLines {
      id
      prdtlnNm
      prdtlnAbbrvNm
      prdtlnGrpNm
    }
  }
`;

export const MEMBER_INTEREST_PRODUCT_INSERT =  gql`
  mutation insertMemberInterestProducts($prdtlnGrpNms: [String]!) {
    insertMemberInterestProducts(prdtlnGrpNms: $prdtlnGrpNms) {
      message
    }
  }
`;




export const WORLD_MAP_DATA = gql`
  query mapChart($searchYear: String, $groupCode: String = "RV", $pathogenCode: String = "FLUA") {
    worldMapChartData(searchYear: $searchYear, groupCode: $groupCode, pathogenCode: $pathogenCode) {
      nationalAreaCode
      nationalCode
      nationalName
      latitude
      longitude
      pathogenCode
      pathogenName
      totalTestCases
      positiveCases
      positiveRate
    }
    worldTrendChartData(searchYear: $searchYear, groupCode: $groupCode, pathogenCode: $pathogenCode) {
      exptDate
      positiveCases
    }
  }
`;

export const WORLD_PATHOGEN_LIST = gql`
  query worldPathogenList {
    worldPathogenList {
      tabGroupCode # Tab 구분
      pathogenList # Tab별 Pathogen 항목 JSON
    }
  }
`;