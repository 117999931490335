import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from "ramda/src/isEmpty";

import * as baseActions from 'store/modules/base';
import { validateAccessTokenExp } from 'lib/common';
import { ACCESS_TOKEN } from './constants';

import MicroFrontend from 'MicroFrontendHook';
import { ErrorPage, NotFoundPage, MemberPage, LandingPage, GatewayPage, SignUpPage } from 'pages';
import { BaseContainer } from 'containers/common';

const {
  REACT_APP_APP_HOST: appHost
} = process.env;

const withAuth = WrappedComponent => props => {
  const BaseActions = bindActionCreators(baseActions, useDispatch());
  //storage에 token이 있을 경우
  if (!isEmpty(localStorage.getItem(ACCESS_TOKEN))) {
    if (validateAccessTokenExp(localStorage.getItem(ACCESS_TOKEN))) {
      BaseActions.tempLogin({
        [ACCESS_TOKEN]: localStorage.getItem(ACCESS_TOKEN),
      });
    } else {
      BaseActions.logout();
    }
  }
  return <WrappedComponent {...props}/>
}

const withApp = WrappedComponent => props => {
  let new_props = {...props, refresh:Date.now()}
  return <WrappedComponent {...new_props}/>
}

const App = ({ history, refresh }) => {
  const BaseActions = bindActionCreators(baseActions, useDispatch());
  const handleContainerPage = (path) => {
    BaseActions.logout();
    history.push(path);
    window.location.reload();
  }
  return <MicroFrontend history={history} refresh={refresh} host={appHost} name="App" handleContainerPage={handleContainerPage}/>
}

const PrivateRoute = ({ logged, ...props }) => {
  if (!isEmpty(localStorage.getItem(ACCESS_TOKEN))) {
    if (validateAccessTokenExp(localStorage.getItem(ACCESS_TOKEN))) {
      return <Route { ...props } />
    } else {
      return <Redirect to="/landing" />
    }
  }
}


const ContainerApp = ({history}) => {

  const logged = useSelector(state => state.base.get('logged'));

  return (
    <ErrorPage>
      <Router history={history}>
        <Switch>
          <Route exact path="/"><Redirect to="/app/analysis/prevalence"/></Route>
          <Route exact path="/gateway/:lnb?" component={GatewayPage} />
          <Route exact path="/member/:lnb?" component={MemberPage} />
          <Route exact path="/signup/:lnb?" component={SignUpPage} />
          <Route exact path="/landing" component={LandingPage} />
          <PrivateRoute logged={logged} exact path="/app*" component={withApp(App)} />
          {/* 404 page */}
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
      <BaseContainer />
    </ErrorPage>
  )
};
 
export default withAuth(ContainerApp);
