import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import useReactRouter from 'use-react-router';
import { forgetPassword } from 'lib/api';
import { useKeypress } from "lib/common";
import {StyledChange} from './ChangePassword.styled';

const ChangePassword = ({lnb}) => {

  const { history } = useReactRouter();
  const { getValues, setValue, handleSubmit, register, errors } = useForm();
  const [ submitting, setSubmitting] = useState(false);
  const [ errorMessage, setErrorMessage] = useState('');
  const onSubmit = () => {
    if(submitting) {
      setErrorMessage('');
      console.log('submitting...');
      return;
    }
    setSubmitting(true);
    (async() => {
      const email = getValues("email");
      try {
        await forgetPassword(email).then(() => {
          //alert("We have sent you an e-mail containing your password reset link");
          history.push("/member/email");
        });
      } catch (err) {
        setErrorMessage(err.response.data.message);
      }
      setSubmitting(false);
    })();
  };

  useKeypress('Escape', () => {
    history.push('/');
  });

  return(
    <>
      <StyledChange lnb={lnb}>
        <div className="contents">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="centerwrap">
              <div className="layoutBox">
                <strong>Do you want to reset your password?</strong>
              </div>
              <p className="warn">
                Please enter your email<br/>
                And we send you a email verification link
              </p>
              <span className="email-field">
                <label>Email</label>
                <input 
                  type="text" 
                  placeholder="Your email address" 
                  title="Write down your Email" 
                  name="email"
                  onChange={e => setValue(e.target.value)}
                  ref={register({
                    required: "Check email form. ex) username@email.com",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Check email form. ex) username@email.com"
                    }
                  })}
                  />
              </span>
              <div className="error-field">
                {
                  errorMessage ? (
                    <p className="error-message">
                      { errorMessage }
                    </p>
                  ) : (
                    errors.email && (
                      <p className="error-message">
                        { errors.email.message }
                      </p>
                    )
                  )
                }
              </div>
              <button 
                className="btn-gray" 
                type="button" 
                title="Send" 
                onClick={handleSubmit(onSubmit)}
                >SEND</button>
            </div>
          </form>
        </div>
      </StyledChange>
    </>
  )
}

export default ChangePassword;
