import React from 'react';
import Styled from 'styled-components';
import CloseIcon from 'assets/svg/icon_popup_close.svg';
import { PolicyPopupData } from 'components/LandingPopup/PolicyPopup';
import { TermsPopupData } from 'components/LandingPopup/TermsPopup';

export const LandingPagePopup = ({PopState, PopOpen, handlePopOpen}) => {

  const PopComponent = {
    polisy: <PolicyPopupData />,
    terms: <TermsPopupData />
  }

  return (
    <PopupContainer PopOpen={PopOpen}>
      <PopupWrapper>
        <PopupContents>
          {PopComponent[PopState]}
        </PopupContents>
        <CloseButton onClick={() => handlePopOpen("")} />
      </PopupWrapper>
    </PopupContainer>
  )
}

const PopupContainer = Styled.div`
  display:${({PopOpen}) => PopOpen ? "flex" : "none" };
  justify-content:center;
  align-items:center;
  position:fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.7);
`;

const PopupWrapper = Styled.div`
  position:relative;
  padding: 25px 40px 40px 40px;
  max-width:440px;
  max-height:600px;
  overflow-y: scroll;
  background:#222222;
`;

const PopupContents = Styled.div`
  h2{
    margin-bottom:40px;
    color:#fff;
    text-align:center;
    font-size:18px;
    font-weight:800;
  }

  ul li {
    margin-bottom:14px;
    color:#ccc;
    font-size:12px;
    font-weight:400;
  }
`;

const CloseButton = Styled.div`
  position:absolute;
  top:25px;
  right:40px;
  width:24px;
  height:24px;
  background:url('${CloseIcon}')no-repeat center;
  cursor:pointer;
`;