import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';

export const validateAccessTokenExp = (accessToken) => {
  if (accessToken) {
    try {
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken.exp) {
        if (new Date().getTime() <= decodedToken.exp * 1000) {
          return true;
        }
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const setLS = (key, value) => {
  if (global.localStorage) {
    global.localStorage.setItem(key,value);
  }
};

export const getLS = (key) => {
  let ls = '';
  if (global.localStorage) {
    try {
      ls = global.localStorage.getItem(key);
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
};

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export function useKeypress(key, action) {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key) action()
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);
}