import React,{ useState, useEffect, useRef } from 'react';
import styled from 'styled-components'; 
import _ from 'lodash';
import * as mixins from 'assets/styled/Mixin.styled';
import MapBoxGL, {Marker} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { MapClusterChart } from 'components/amchart';
import { useCallback } from 'react';
import Markers from './Markers';
import { CircularProgress } from "@material-ui/core";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapBox = ({viewport, setViewport, clusterData, tabColor, loading, noResult}) => {
  const MapChartRef = useRef(null);
  const MAP_TOKEN = "pk.eyJ1IjoiamVvbmJnIiwiYSI6ImNra3czd3l6aTBjeWsyeG80bzNpYTZlNTgifQ.ICvbGd6tj0r9j8Tsv3KS1A";
  const [combineChart, setCombineChart] = useState(null);
  const [cluster, setCluster] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const mapResize = _.throttle(() => {
      setViewport(Object.assign({}, {
        ...viewport,
        width:"100%",
        height:"100%",
      }))
    }, 800);

    window.addEventListener('resize', mapResize);
    return () => window.removeEventListener('resize', mapResize)
  }, [viewport])
  
  const handleLoading = () => setIsLoading(() => !isLoading);
  
  const handleCombineChart = useCallback((data) => {
    let positiveCases,
        positiveRate,
        totalTestCases,
        filterData,
        pathogenName = data[0]?.pathogenName,
        firstLat,
        firstLng,
        obj = {};

    if(data.length > 0) {
      let filtered = data.map(i => i.nationalAreaCode)
          .reduce((acc, curr) => acc.includes(curr) ? acc : [...acc, curr],[]);
      if(filtered.length > 0) {
        filtered.forEach((el)=>{
          filterData = data.filter((item) => item.nationalAreaCode === el);
          positiveCases = filterData.map(i => i.positiveCases).reduce((prev, curr) => prev + curr );
          totalTestCases = filterData.map(i => i.totalTestCases).reduce((prev, curr) => prev + curr );
          positiveRate = positiveCases / totalTestCases * 100;
          firstLat = filterData[0].latitude;
          firstLng = filterData[0].longitude;
          obj[el] = {positiveCases, positiveRate, totalTestCases, el, pathogenName, firstLat, firstLng};
          handleLoading();
          setCombineChart(obj)
        });
      } else {
        setCombineChart(null)
      }
    } else {
      setCombineChart(null)
    }
  },[combineChart, isLoading])

  const calcStyle = (clusterDataPositive) => {
    // positiveCases 양에 따른 사이즈 스타일
    // totalCases 양에 따른 사이즈 스타일
    let size;

    if(clusterDataPositive > 500) size = 90;
    else if (clusterDataPositive > 400) size = 80;
    else if (clusterDataPositive > 300)  size = 70;
    else if (clusterDataPositive > 200) size = 60;
    else if (clusterDataPositive > 100) size = 50;
    else size = 50;

    return size;
  }

  useEffect(() => {
    if(clusterData) {
      let filter = clusterData?.filter(i => i.nationalAreaCode !== 'TOTAL')
      setCluster([...filter]);
      handleCombineChart([...filter]);
    }
    handleLoading();
  }, [clusterData])

  const changeViewPort = _.throttle((viewport) => setViewport(viewport), 500)

  return (
    <Container>
      <MapBoxGL
        {...viewport}
        mapboxApiAccessToken={MAP_TOKEN}
        minZoom={1}
        dragRotate={false}
        touchRotate={false}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        ref={MapChartRef}
        onViewportChange={(viewport) => changeViewPort(viewport)}
      >
      {
        !isLoading &&
        !noResult &&
        viewport.zoom > 2.2 &&
        cluster?.filter(cluster => cluster.positiveCases > 0).map((cluster, index) => (
          <Markers 
            key={index}
            cluster={cluster}
            size={calcStyle(cluster?.totalTestCases)}
            tabColor={tabColor}
            loading={loading}
            // handleMove={handleMove}
          />
        ))
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.AS?.positiveCases > 0 &&
        <Marker
          key="marker-AS"
          longitude={combineChart?.AS?.firstLng}
          latitude={combineChart?.AS?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-AS"
              data={combineChart && combineChart?.AS}
              size={calcStyle(combineChart?.AS?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>ASIA<span>{combineChart?.AS?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.AS?.totalTestCases ? combineChart?.AS?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.AS?.positiveCases ? combineChart?.AS?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{combineChart?.AS?.positiveRate ? `${combineChart?.AS?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.EU?.positiveCases > 0 &&
        <Marker
          key="marker-EU"
          longitude={combineChart?.EU?.firstLng}
          latitude={combineChart?.EU?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-EU"
              data={combineChart && combineChart?.EU}
              size={calcStyle(combineChart?.EU?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>EU<span>{combineChart?.EU?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.EU?.totalTestCases ? combineChart?.EU?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.EU?.positiveCases ? combineChart?.EU?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{`${combineChart?.EU?.positiveRate}` ? `${combineChart?.EU?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.NA?.positiveCases > 0 &&
        <Marker
          key="marker-NA"
          longitude={combineChart?.NA?.firstLng}
          latitude={combineChart?.NA?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-NA"
              data={combineChart && combineChart?.NA}
              size={calcStyle(combineChart?.NA?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>NORTH AMERICA<span>{combineChart?.NA?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.NA?.totalTestCases ? combineChart?.NA?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.NA?.positiveCases ? combineChart?.NA?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{`${combineChart?.NA?.positiveRate}` ? `${combineChart?.NA?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.OC?.positiveCases > 0 &&
        <Marker
          key="marker-OC"
          longitude={combineChart?.OC?.firstLng}
          latitude={combineChart?.OC?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-OC"
              data={combineChart && combineChart?.OC}
              size={calcStyle(combineChart?.OC?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>OC<span>{combineChart?.OC?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.OC?.totalTestCases ? combineChart?.OC?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.OC?.positiveCases ? combineChart?.OC?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{`${combineChart?.OC?.positiveRate}` ? `${combineChart?.OC?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.AF?.positiveCases > 0 &&
        <Marker
          key="marker-AF"
          longitude={combineChart?.AF?.firstLng}
          latitude={combineChart?.AF?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-AF"
              data={combineChart && combineChart?.AF}
              size={calcStyle(combineChart?.AF?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>AFRICA<span>{combineChart?.AF?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.AF?.totalTestCases ? combineChart?.AF?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.AF?.positiveCases ? combineChart?.AF?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{combineChart?.AF?.positiveRate ? `${combineChart?.AF?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      {
        !isLoading &&
        !noResult &&
        viewport.zoom <= 2.2 &&
        combineChart !== null && combineChart?.SA?.positiveCases > 0 &&
        <Marker
          key="marker-SA"
          longitude={combineChart?.SA?.firstLng}
          latitude={combineChart?.SA?.firstLat}
        >
          <div className="marker-item">
            <MapClusterChart
              id="chart-SA"
              data={combineChart && combineChart?.SA}
              size={calcStyle(combineChart?.SA?.totalTestCases)}
              bgColor={tabColor}
              loading={loading}
            />
            <div className="nat-marker-desc">
              <div className="desc-title">
                <strong>SOUTH AMERICA<span>{combineChart?.SA?.pathogenName}</span></strong>
              </div>
              <ul>
                <li><strong>Cases : </strong>{combineChart?.SA?.totalTestCases ? combineChart?.SA?.totalTestCases : 0 }</li>
                <li><strong>Positive : </strong>{combineChart?.SA?.positiveCases ? combineChart?.SA?.positiveCases : 0 }</li>
                <li><strong>Rate : </strong>{combineChart?.SA?.positiveRate ? `${combineChart?.SA?.positiveRate.toFixed(1)}%` : 0}</li>
              </ul>
            </div>
          </div>
        </Marker>
      }
      </MapBoxGL>
      {loading &&
        <ProgressBg>
          <CircularProgress style={{'color': 'white'}} />
        </ProgressBg>
      }
    </Container>
  )
}

export default MapBox;

const Container = styled.div`
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  .marker-item {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    z-index:0;
    cursor: pointer;
    .marker-desc, .nat-marker-desc {
      position:absolute;
      padding:10px;
      left:50%;
      top:-105px;
      width:100px;
      transform:translateX(-50%);
      background:#161E26;
      border-radius:10px;
      visibility:hidden;
      opacity:0;
      transition:all .5s ease;
      .desc-title > strong {
        ${mixins.fontSet('#fff',"10px")};
        display:block;
        margin-bottom: 7px;
        font-weight:400;
        > span {
          position:relative;
          display:inline-block;
          margin-left:10px;
          line-height:1;
          font-weight:800;
          &::before{
            ${mixins.afterBefore()};
            position:absolute;
            left:-5px;
            top:1px;
            width:1px;
            height:10px;
            background:#444444;
          }
        }
      }

      > ul li {
        font-size:8px;
        > strong {
          ${mixins.fontSet("#899097","8px")};
          display:inline-block;
          margin-right:4px;
          vertical-align:top;
          font-weight:800;
        }
        &:not(:first-child){
          margin-top:2px;
        }
      }
    &::before{
        ${mixins.afterBefore()};
        z-index:10;
        position:absolute;
        left: 50%;
        bottom:0;
        transform:rotate(-45deg) translateX(-50%);
        width:10px;
        height:10px;
        background:#161E26;
      }
    }

    &:hover .marker-desc{
      visibility:visible;
      opacity:1;
    }

    .nat-marker-desc{
      visibility:visible;
      opacity:1;
    }
    
  }
  .mapboxgl-control-container {
    // 로고 영역 지우면 잡혀갈까요?
    display:none;
  }
`;


const ProgressBg = styled.div`
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: #00000022;
    display: flex;
    align-items: center;
    justify-content: center;
`;