import React from 'react';
import {Marker} from 'react-map-gl';
import { MapClusterChart } from 'components/amchart';

const Markers = ({cluster, size, tabColor, loading, searchCode}) => {

  return (
    
    <Marker
      key={cluster.nationalName}
      longitude={cluster.longitude}
      latitude={cluster.latitude}
    >
      <div className="marker-item">
        <MapClusterChart
          id={cluster.nationalName}
          size={size}
          data={cluster}
          bgColor={tabColor}
          loading={loading}
          searchCode={searchCode}
        />
        <div className="marker-desc"
          // onClick={() => handleMove({lng:cluster.longitude, lat:cluster.latitude, zoom:8})}
        >
          <div className="desc-title">
            <strong>{cluster.nationalName}<span>{cluster.pathogenName}</span></strong>
          </div>
          <ul>
            <li><strong>Cases : </strong>{cluster.totalTestCases}</li>
            <li><strong>Positive : </strong>{cluster.positiveCases}</li>
            <li><strong>Rate : </strong>{`${cluster.positiveRate}%`}</li>
          </ul>
        </div>
      </div>
    </Marker>
  )
}

export default Markers