import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as baseActions from 'store/modules/base';

import { toast } from 'components/toast';

import { downloadExcel } from 'lib/api';

const FONT_SIZE_LABEL = 9;
const FONT_SIZE = 12;
const FONT_SIZE_TITLE = 18;
const FONT_SIZE_DATA_LABEL = 12;

const SCROLLBAR_POSITION_BOTTOM = false; // false=top, true=bottom
const SCROLLBAR_COLOR_FILL = am4core.color("#cfd7df");
const SCROLLBAR_FILL_OPACITY = 1;
const SCROLLBAR_COLOR_GRIP_START = am4core.color("#8a98a7");
const SCROLLBAR_COLOR_GRIP_END = am4core.color("#8a98a7");

const TOOLTIP_POSITION = 'vertical';
const TOOLTIP_OFFSET = -20;
const TOOLTIP_BACKGROUND_FILL = am4core.color('#FFFFFF');
const TOOLTIP_BACKGROUND_FILL_OPACITY = 1;
const TOOLTIP_TEXT_COLOR = am4core.color('#111');
const TOOLTIP_FONT_SIZE = 10;
const TOOLTIP_SHADOW_DX = 0;
const TOOLTIP_SHADOW_DY = 0;
const TOOLTIP_SHADOW_BLUR = 5;
const TOOLTIP_SHADOW_COLOR = am4core.color('rgba(0, 0, 0, 0.3)');

const LEGEND_WIDTH = 200;

const LINE_BULLETS = [
  "M3.76.11a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,3.76.11Z",
  "M5,9.27a4.5,4.5,0,0,1,0-9H5a4.49,4.49,0,0,1,4.5,4.5h0A4.51,4.51,0,0,1,5,9.27Zm0-7a2.5,2.5,0,0,0-2.5,2.5h0A2.5,2.5,0,0,0,5,7.27H5a2.5,2.5,0,0,0,2.5-2.5h0A2.5,2.5,0,0,0,5,2.27Z",
  "M.26.11h7v7h-7Z",
  "M9.45,9.27h-9v-9h9Zm-7-7v5h5v-5Z",
  "M5.77,1.2l4.5,8h-9Z",
  "M11.34,10.76H.34l5.5-10Zm-5.5-6h0l-2,4h4Z",
  "M5.77.7l4.5,3.43L8.55,9.7H3L1.27,4.13Z",
  "M9.24,11.26H2.44L.34,4.46,5.84.26l5.5,4.2-2.1,6.8Zm-3.4-8.7h0L2.34,5.23,3.68,9.56H8L9.34,5.23Z",
  "M5.84.26,7.49,4l3.85.51L8.51,7.25l.73,4-3.4-2-3.4,2,.73-4L.34,4.46,4.19,4Z",
  "M.34,5.76a5.5,5.5,0,1,1,5.5,5.5A5.5,5.5,0,0,1,.34,5.76ZM5.55,9.25,8.71,3.77A3.2,3.2,0,0,0,8,3L4.54,9A3.47,3.47,0,0,0,5.55,9.25Zm1.23-.12A3.51,3.51,0,0,0,9.34,5.76a3.58,3.58,0,0,0-.11-.87Zm-3.1-.62,3.46-6a3.53,3.53,0,0,0-1-.24L3,7.75a3.93,3.93,0,0,0,.71.76ZM2.34,5.76a3.7,3.7,0,0,0,.11.87L4.9,2.39A3.5,3.5,0,0,0,2.34,5.76Z",
  "M5.77,6.61,2.94,9.44,1.52,8,4.35,5.2,1.52,2.37,2.94,1,5.77,3.78,8.6,1,10,2.37,7.18,5.2,10,8,8.6,9.44Z",
  "M.61,6.88,7,.52l6.37,6.36L7,13.25Z",
  "M6.91.32c.25.13,4.5,5,4.5,7.5a4.5,4.5,0,0,1-9,0C2.41,5.34,7,.32,6.91.32Z",
  "M11.34,11.26H.34V.26h11Zm-2-2V4.69L6.7,9.26Zm-3.79,0L9.34,2.7V2.26h-.9l-4,7Zm-3.21,0h.9l4-7H6.13L2.34,8.82Zm0-2.44L5,2.26H2.34Z",
  "M5.84,11.26a5.5,5.5,0,1,1,5.5-5.5A5.51,5.51,0,0,1,5.84,11.26Zm-.5-5v3h1v-3h3v-1h-3v-3h-1v3h-3v1Z",
];

const createTooltipTemplate = () => {
  let tooltipTemplate = new am4core.Tooltip();
  tooltipTemplate.pointerOrientation = TOOLTIP_POSITION;
  tooltipTemplate.getFillFromObject = false; //툴팁 배경색 지정시 해당 옵션에 false를 주어야 적용된다.
  tooltipTemplate.background.fill = TOOLTIP_BACKGROUND_FILL; //툴팁 배경색 지정하기
  tooltipTemplate.background.fillOpacity = TOOLTIP_BACKGROUND_FILL_OPACITY; //별도 지정하지 않으면 bar의 옵션값을 따라간다.
  tooltipTemplate.autoTextColor = false;
  tooltipTemplate.label.fill = TOOLTIP_TEXT_COLOR;
  tooltipTemplate.label.fontSize = TOOLTIP_FONT_SIZE;
  // tooltipTemplate.getStrokeFromObject = false; //defalut=false
  // tooltipTemplate.strokeWidth = 1;
  // tooltipTemplate.strokeOpacity = 1;
  // tooltipTemplate.stroke = am4core.color('#000');

  //툴팁에 쉐도우 효과 커스텀
  // tooltipTemplate.background.filters.clear(); //쉐도우 효과 제거
  let tooltipShadow = tooltipTemplate.background.filters.getIndex(0);
  tooltipShadow.dx = TOOLTIP_SHADOW_DX;
  tooltipShadow.dy = TOOLTIP_SHADOW_DY;
  tooltipShadow.blur = TOOLTIP_SHADOW_BLUR;
  tooltipShadow.color = TOOLTIP_SHADOW_COLOR;
  return tooltipTemplate;
}


/**
 * XY Chart 생성 
 * @param {string} chartElementId 
 * @param {*} chartData 
 * @param {string} title 
 */
export const createXYChart = (chartElementId, chartData, title, toolbarData, handleLoading) => {
  // loading start
  handleLoading('start');

  let chart = am4core.create(chartElementId, am4charts.XYChart);
  chart.data = chartData;
  // chart.height = am4core.percent(100); //차트 높이(차트 영역의 퍼센테이지로)
  // chart.width = am4core.percent(100); //차트 너비
  // chart.height = 500; //차트 높이
  // chart.paddingRight = 20;
  // chart.paddingTop = 50;
  chart.yAxesAndPlotContainer.paddingTop = 30;

  if(title) {
    let chartTitle = chart.titles.create();
    chartTitle.text = title;
    chartTitle.fontSize = FONT_SIZE_TITLE;
    chartTitle.fontWeight = "bold"; //"normal" | "bold" | "bolder" | "lighter" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900"
  }

  if(toolbarData === 'percent'){
    chart.numberFormatter.numberFormat = "#,###.0";
  }else{
    chart.numberFormatter.numberFormat = "#,###";
  }
  // loading end
  chart.events.on('ready', () => {
    handleLoading('end');
  });

  // 흑백모드 필터
  // chart.filters.push(new am4core.DesaturateFilter());

  return chart;
}


/* 막대(Bar) 그리기 */
const createColumnSeries = (chart, categoryColumnName, dateColumnName, valueColumnName, showDataLabel, seriesName, yAxis, stacked = false, customTooltipText, numberFlag) => {
  let series = chart.series.push(new am4charts.ColumnSeries());
  if(seriesName) {
    series.name = seriesName;
    series.toggleName = seriesName.replace(/\((.*?)\)/g, '');
  }
  if(categoryColumnName) {
    series.dataFields.categoryX = categoryColumnName;
  } else if(dateColumnName) {
    series.dataFields.dateX = dateColumnName;
  }
  if(yAxis) series.yAxis = yAxis;
  series.stacked = stacked;
  series.dataFields.valueY = valueColumnName;
  if(seriesName === 'qcSeries' && valueColumnName === 'positiveCount'){
    series.dataFields.valueYRun = 'runCount';
  }
  series.dataFields.valueYShow = false;
  series.columns.template.showTooltipOn = true;
  series.columns.template.width = am4core.percent(50); //막대 너비
  series.columns.template.fillOpacity = 1;

  if(showDataLabel) {
    let text = seriesName === 'qcSeries' && valueColumnName === 'positiveCount' ? '{valueY}/{valueYRun}' : '{valueY}';
    createLabelBullet(series, text, stacked, numberFlag);
  }
  
  series.columns.template.column.tooltipText =  customTooltipText || "{valueY.value}";
  series.columns.template.column.tooltipY = TOOLTIP_OFFSET; //tooltip 노출 위치 조정
  series.tooltip = createTooltipTemplate();
  return series;
}

/**
 * Column Series 생성(Category X축)
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} valueColumnName 
 * @param {boolean} showDataLabel 데이터 레이블 표시 여부(LabelBullet 생성 여부)
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} yAxis 
 */
export const createCategoryColumnSeries = (chart, categoryColumnName, valueColumnName, showDataLabel, seriesName, yAxis, tooltipText, groupTooltipText, numberFlag) => {
  let series =  createColumnSeries(chart, categoryColumnName, null, valueColumnName, showDataLabel, seriesName, yAxis, false, tooltipText, numberFlag);
  series.columns.template.column.adapter.add('tooltipText', (text, target) => {
    if(groupTooltipText) {
      return `${groupTooltipText}
        [${chart.colors.getIndex(target.dataItem.index)}]●[/] ${text}`;
    } else {
      return `[${chart.colors.getIndex(target.dataItem.index)}]●[/] ${text}`;
    }
  });
  return series;
}


/**
 * 예외차트 Column Series 생성(Category X축)
 * Prevalence - 일반 차트 + BV 차트 Multi Axis 사용 시 Tooltip의 색상이 다르게 표현되는 문제로 추가
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} valueColumnName 
 * @param {boolean} showDataLabel 데이터 레이블 표시 여부(LabelBullet 생성 여부)
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} yAxis 
 */
export const createMultipleAxisCategoryColumnSeries = (chart, categoryColumnName, valueColumnName, showDataLabel, seriesName, yAxis, tooltipText, groupTooltipText, numberFlag) => {
  let series =  createColumnSeries(chart, categoryColumnName, null, valueColumnName, showDataLabel, seriesName, yAxis, false, tooltipText, numberFlag);
  // series.toBack(); // series 겹침시 앞으로, 뒤로 보내기 (toFront(), toBack())
  
  series.columns.template.column.adapter.add('tooltipText', (text, target) => {
    let targetField = target.dataItem.component.dataFields;
    let color;
    
    chart.series.each(function(series) {
      if(series.dataFields.valueY === targetField.valueY){
        color = series.fill;
      }
    });

    if(groupTooltipText) {
      return `${groupTooltipText}
        [${color}]●[/] ${text}`;
    } else {
      return `[${color}]●[/] ${text}`;
    }
  });
  return series;
}



/**
 * ColumnSeries 생성시 Column을 Legend 항목으로 지정한다.
 */
export const createCategoryColumnAndColumnLegend = (chart, categoryColumnName, valueColumnName, showDataLabel, seriesName, yAxis, xAxis, numberFlag) => {
  
  // let toggleBreaks = {};

  let series = createColumnSeries(chart, categoryColumnName, null, valueColumnName, showDataLabel, seriesName, yAxis, numberFlag);
  let legend = new am4charts.Legend();
    // legend.parent = chart.chartContainer;
    legend.itemContainers.template.togglable = false;
    legend.marginTop = 20;
    series.events.on("dataitemsvalidated", function(ev) {
      var legenddata = [];
      series.columns.each(function(column) {
        legenddata.push({
          name: column.dataItem.dataContext[categoryColumnName],
          fill: column.fill,
          fillOpacity: column.fillOpacity,
          seriesDataItem: column.dataItem
        });
        
        /* let axisBreak = xAxis.axisBreaks.create();
        console.log(column.dataItem.dataContext[categoryColumnName]);
        axisBreak.startCategory = column.dataItem.dataContext[categoryColumnName];
        axisBreak.endCategory = column.dataItem.dataContext[categoryColumnName];
        axisBreak.startLocation = 0;
        axisBreak.endLocation = 1;
        axisBreak.breakSize = 1;
        // axisBreak.fillShape.disabled = true;
        axisBreak.fillShape.fillOpacity = 0.7;
        axisBreak.fillShape.width = 0;
        // axisBreak.startLine.disabled = true;
        // axisBreak.endLine.disabled = true;
        // axisBreak.defaultState.transitionDuration = 1000;
        toggleBreaks[column.dataItem.dataContext[categoryColumnName]] = axisBreak; */

      });
      legend.data = legenddata;
      legend.itemContainers.template.events.on("toggled", function(event) {
        let _name = event.target.dataItem.dataContext.name;
        
        var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
        if (event.target.isActive) {
          // toggleBreaks[_name].animate({property:"breakSize", to:0}, 1000, am4core.ease.cubicOut);
          xAxis.dataItems.each((dataItem) => { //X축의 레이블 hide
            if(dataItem.dataContext.coiftPathogenCodes === _name) {
              dataItem.hide();
            }
          })
          seriesDataItem.hide(); //column hide
          // seriesDataItem.hide(series.interpolationDuration, 0, 0, ["valueY"]);
        }
        else {
          //Column은 break시에도 최소 column 하나의 너비를 갖는다.
          // toggleBreaks[_name].animate({property:"breakSize", to:1}, 1000, am4core.ease.cubicOut);
          xAxis.dataItems.each((dataItem) => { //X축의 레이블 show
            if(dataItem.dataContext.coiftPathogenCodes === _name) {
              dataItem.show();
            }
          })
          seriesDataItem.show(); //column show
          // seriesDataItem.show(series.interpolationDuration, 0, ["valueY"]);
        }
      })
    });
    chart.legend = legend;
      return series;
}
/**
 * Stacked Column Series 생성(Category X축)
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} valueColumnName 
 * @param {boolean} showDataLabel 데이터 레이블 표시 여부(LabelBullet 생성 여부)
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} yAxis 
 * @param {boolean} stacked 
 */
export const createCategoryColumnStackSeries = (chart, dataType, showTotalLabel, categoryColumnName, valueColumnName, showDataLabel, seriesName, yAxis, stacked, tooltipText, numberFlag) => {
  let series = createColumnSeries(chart, categoryColumnName, null, valueColumnName, showDataLabel, seriesName, yAxis, stacked, null, numberFlag);
  series.name = seriesName;
  series.toggleName = seriesName.replace(/\((.*?)\)/g, '');
  series.columns.template.column.adapter.add('tooltipText', (text, target) => {
    let targetData = target.dataItem.dataContext;
    let targetField = target.dataItem.component.dataFields;

    let color;
    let eachText = '';
    chart.series.each(function(series) {
      if(series.dataFields.valueY === targetField.valueY){
        color = series.fill;
      }
    });
    
    if(typeof targetData.group !== 'undefined' && targetData.group !== 'all'){
      eachText = `[${color.lighten(1)}]●[/] {group} \n`;
    //   for (let i in targetData.keys){
    //     if('value_'.concat(targetData.keys[i].key) === targetField.valueY){
    //       eachText = (targetData.group === "Single" ? "  Single \n" : "  Co-Infection \n");
    //     } 
    //   }
    }
    return `${eachText}[${color}]●[/] ${tooltipText}`;
  });

  // !TODO Total 표현시 사용한다. 참고.https://www.amcharts.com/demos/stacked-waterfall-chart/
  if(showTotalLabel){
    var sumBullet = series.bullets.push(new am4charts.LabelBullet());
    sumBullet.interactionsEnabled = false;
    sumBullet.verticalCenter = "bottom";
    sumBullet.dy = -8;
    sumBullet.label.fontSize = 12
    sumBullet.label.fontWeight = 900;
    sumBullet.label.fill = am4core.color('#000000'); //font color
    sumBullet.label.truncate = false;      // 텍스트가 길때 자르기? (...표시)
    if(dataType === 'number'){
      sumBullet.label.adapter.add("text", function(text, target) {
        return target.dataItem.dataContext.total;
      });
    }else if(dataType === 'percent'){
      // sumBullet.label.text = '{valueY}';
      sumBullet.label.adapter.add("text", function(text, target) {
        return Number(target.dataItem.dataContext.total).toFixed(1);
      });
    }

    sumBullet.disabled = !numberFlag;
  }
  

  // 같은 Series내에서만 동작하므로 Stack에선 사용할 수 없다?
  // createdS.dataFields.value = 'value_'.concat(key,'_index');
  // createdS.heatRules.push({
  //   "target": createdS.columns.template, // l_bullet
  //   "property": "fillOpacity",
  //   "min": 1,
  //   "max": 0.1,
  //   "dataField": "value"
  // });
  
  return series;
}

/**
 * Column Series 생성(DATE X축)
 * @param {*} chart 
 * @param {string} dateColumnName 
 * @param {string} valueColumnName 
 * @param {boolean} showDataLabel 데이터 레이블 표시 여부(LabelBullet 생성 여부)
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} yAxis 
 */
export const createDateColumnSeries = (chart, dateColumnName, valueColumnName, showDataLabel, seriesName, yAxis, numberFlag) => {
  return createColumnSeries(chart, null, dateColumnName, valueColumnName, showDataLabel, seriesName, yAxis, numberFlag);
}


/* 데이터 레이블 표시 */
export const createLabelBullet = (series, text, stacked ,numberFlag) => {
  let bullet = series.bullets.push(new am4charts.LabelBullet());
  bullet.interactionsEnabled = false;
  
  bullet.label.text = text;
  bullet.label.fontWeight = 900;
  bullet.label.fontSize = FONT_SIZE_DATA_LABEL;
  bullet.label.fill = am4core.color('#000000'); //font color
  if(stacked){
    bullet.label.hideOversized = true;  // 영역이 작을때 라벨 자동 숨김
    bullet.label.verticalCenter = "center";
    bullet.locationY = 0.5;
    bullet.dy = -4;
  }else{
    bullet.label.verticalCenter = "bottom";
  }
  bullet.label.truncate = false;      // 텍스트가 길때 자르기? (...표시)

  bullet.disabled = !numberFlag;

  return bullet;
}


/**
 * Line Series 생성(DATE X축)
 * @param {*} chart 
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} seriesData chart.data와 Line을 그릴 데이터가 다른 경우
 * @param {string} valueYColumnName valueY로 사용할 컬럼명
 * @param {string} dateXColumnName dateX로 사용할 컬럼명
 * @param {boolean} disabledTooltip 
 * @param {string} tooltipHtml 
 * @param {string} tooltipText 
 */
export const createDateLineSeries = (chart, seriesName, seriesData, valueYColumnName, dateXColumnName, disabledTooltip, tooltipHtml, tooltipText, tooltipDate) => {
  let series = chart.series.push(new am4charts.LineSeries());
  series.smoothing = 'monotoneX'; //"bezier" | "monotoneX" | "monotoneY"
  series.name = seriesName;
  series.toggleName = seriesName.replace(/\((.*?)\)/g, '');
  if(seriesData) series.data = seriesData;
  series.dataFields.valueY = valueYColumnName;
  series.dataFields.dateX = dateXColumnName;
  series.strokeWidth = 1;
  series.strokeOpacity = 1;
  series.minBulletDistance = 5;
  if(disabledTooltip) {
    series.tooltip.disabled = disabledTooltip;
  } else {
    //날짜 포맷팅이 필요한 경우 {날짜컬럼명.formatDate("yyyy.MM.dd")}
    // series.tooltip.dy = TOOLTIP_OFFSET; //tooltip 노출 위치 조정
    series.tooltip = createTooltipTemplate();

    if(tooltipHtml) { //custom tooltip
      series.tooltipHTML = tooltipHtml;
    } else if(tooltipText) {
      series.tooltipText = tooltipText;
    }
  }
  series.bulletsContainer.parent = chart.seriesContainer;
  //hover시 두껍게
  series.segments.template.interactionsEnabled = true;
  let hoverState = series.segments.template.states.create('hover');
  hoverState.properties.strokeWidth = 2;
  
  series.adapter.add("tooltipText", (text, target, ev) => {
    // chart.colors.getIndex(target.dataItem.index)
    // let dot = ``;
    // chart.series.each((item) => {
    //   dot += `[${item.stroke.hex}]●[/] ${item.name}
    //   `;
    // });
    // return dot;
    return `${tooltipDate}
            [${series.stroke.hex}]●[/] ${text}`;
  });

  return series;
}

/**
 * Line Series 생성(DATE X축)
 * @param {*} chart 
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} seriesData chart.data와 Line을 그릴 데이터가 다른 경우
 * @param {string} valueYColumnName valueY로 사용할 컬럼명
 * @param {string} dateXColumnName dateX로 사용할 컬럼명
 * @param {boolean} disabledTooltip 
 * @param {string} tooltipHtml 
 * @param {string} tooltipText 
 */
export const createCategoryLineSeries = (chart, seriesName, seriesData, valueYColumnName, dateXColumnName, disabledTooltip, tooltipHtml, tooltipText, tooltipDate) => {
  let series = chart.series.push(new am4charts.LineSeries());
  //series.smoothing = 'monotoneX'; //"bezier" | "monotoneX" | "monotoneY"
  series.name = seriesName;
  series.toggleName = seriesName.replace(/\((.*?)\)/g, '');
  if(seriesData) series.data = seriesData;
  series.dataFields.valueY = valueYColumnName;
  series.dataFields.categoryX = dateXColumnName;
  series.strokeWidth = 1;
  series.strokeOpacity = 1;
  series.minBulletDistance = 5;
  if(disabledTooltip) {
    series.tooltip.disabled = disabledTooltip;
  } else {
    //날짜 포맷팅이 필요한 경우 {날짜컬럼명.formatDate("yyyy.MM.dd")}
    // series.tooltip.dy = TOOLTIP_OFFSET; //tooltip 노출 위치 조정
    series.tooltip = createTooltipTemplate();

    if(tooltipHtml) { //custom tooltip
      series.tooltipHTML = tooltipHtml;
    } else if(tooltipText) {
      series.tooltipText = tooltipText;
    }
  }
  series.bulletsContainer.parent = chart.seriesContainer;
  //hover시 두껍게
  series.segments.template.interactionsEnabled = true;
  let hoverState = series.segments.template.states.create('hover');
  hoverState.properties.strokeWidth = 2;
  
  series.adapter.add("tooltipText", (text, target, ev) => {
    // chart.colors.getIndex(target.dataItem.index)
    // let dot = ``;
    // chart.series.each((item) => {
    //   dot += `[${item.stroke.hex}]●[/] ${item.name}
    //   `;
    // });
    // return dot;
    return `${tooltipDate}
            [${series.stroke.hex}]●[/] ${text}`;
  });

  return series;
}

/**
 * Follow up에서만 사용(다른 경우는 고려되지 않음) 210113
 * 
 * Line Series 생성(Category X축)
 * @param {*} chart 
 * @param {*} yAxis series에 적용할 Y축
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} seriesData chart.data와 Line을 그릴 데이터가 다른 경우
 * @param {string} valueYColumnName valueY로 사용할 컬럼명
 * @param {string} cateXColumnName categoryX로 사용할 컬럼명
 * @param {string} tooltipText 
 */
export const createCategoryLineSeriesForFollowup = (chart, yAxis, seriesName, seriesData, valueYColumnName, cateXColumnName, prevTooltipText, tooltipText) => {
  let series = chart.series.push(new am4charts.LineSeries());
  series.yAxis = yAxis;
  series.smoothing = 'monotoneX'; //"bezier" | "monotoneX" | "monotoneY"
  series.name = seriesName;
  series.toggleName = seriesName.replace(/\((.*?)\)/g, '');

  if(seriesData) series.data = seriesData;
  series.dataFields.valueY = valueYColumnName;
  series.dataFields.categoryX = cateXColumnName;
  series.strokeWidth = 1;
  series.strokeOpacity = 1;
  series.minBulletDistance = 5;

  series.tooltip = createTooltipTemplate();
  series.tooltipText = tooltipText;

  series.bulletsContainer.parent = chart.seriesContainer;
  series.adapter.add("tooltipText", (text, target, ev) => {
    return `${prevTooltipText}
            [${series.stroke.hex}]●[/] ${text}`;
  });

  //Hover EVENT
  let segment = series.segments.template;
  segment.interactionsEnabled = true;

  let dimmedState = segment.states.create("dimmed");
  dimmedState.properties.strokeOpacity = 0.2;

  return series;
}


/**
 * Bullet 생성
 * //https://www.amcharts.com/docs/v4/concepts/bullets/
 * @param {*} series 
 * @param {*} bulletShape am4core.Circle | am4core.Rectangle | am4core.Triangle | am4core.Line | am4core.Image
 * @param {number} width 
 * @param {number} height 
 * @param {string} horizontalCenter "none" | "left" | "middle" | "right"
 * @param {string} verticalCenter "none" | "top" | "middle" | "bottom"
 * @param {*} strokeColor am4core.color('color code')
 * @param {number} strokeWidth 
 * @param {number} strokeOpacity 
 * @param {*} fillColor am4core.color('color code')
 * @param {number} fillOpacity 
 * @param {number} rotation 
 * @param {string} direction (am4core.Triangle) "left" | "right" | "top" | "bottom"
 * @param {string} href (am4core.Image) image path
 * @param {string} hrefColumnName (am4core.Image) image path를 담고 있는 컬럼명
 */
export const createBullet = (series, bulletShape, width, height, horizontalCenter, verticalCenter, strokeColor, strokeWidth, strokeOpacity, fillColor, fillOpacity, rotation, direction, href, hrefColumnName) => {
  let bullet = series.bullets.push(new am4charts.Bullet());
  bullet.setStateOnChildren = true;
  createBulletChild(bullet, bulletShape, width, height, horizontalCenter, verticalCenter, strokeColor, strokeWidth, strokeOpacity, fillColor, fillOpacity, rotation, direction, href, hrefColumnName);
  let bullethover = bullet.states.create("hover");
  bullethover.properties.scale = 1.3;
  return bullet;
}


export const createSpriteBullet = (series, fillColor, path) => {
  let bullet = series.bullets.push(new am4charts.Bullet());
  bullet.setStateOnChildren = true;

  let bulletChild = bullet.createChild(am4core.Sprite);
  // if(path) {
    bulletChild.path = LINE_BULLETS[path];
  // } else {
  //   bulletChild.path = "M105.572,101.811c9.889-6.368,27.417-16.464,28.106-42.166c0.536-20.278-9.971-49.506-49.155-50.878C53.041,7.659,39.9,28.251,36.071,46.739l-0.928-0.126c-1.932,0-3.438,1.28-5.34,2.889c-2.084,1.784-4.683,3.979-7.792,4.308c-3.573,0.361-8.111-1.206-11.698-2.449c-4.193-1.431-6.624-2.047-8.265-0.759c-1.503,1.163-2.178,3.262-2.028,6.226c0.331,6.326,4.971,18.917,16.016,25.778c7.67,4.765,16.248,5.482,20.681,5.482c0.006,0,0.006,0,0.006,0c2.37,0,4.945-0.239,7.388-0.726c2.741,4.218,5.228,7.476,6.037,9.752c2.054,5.851-27.848,25.087-27.848,55.01c0,29.916,22.013,48.475,56.727,48.475h55.004c30.593,0,70.814-29.908,75.291-92.48C180.781,132.191,167.028,98.15,105.572,101.811z M18.941,77.945C8.775,71.617,4.992,58.922,5.294,55.525c0.897,0.24,2.194,0.689,3.228,1.042c4.105,1.415,9.416,3.228,14.068,2.707c4.799-0.499,8.253-3.437,10.778-5.574c0.607-0.509,1.393-1.176,1.872-1.491c0.87,0.315,0.962,0.693,1.176,3.14c0.196,2.26,0.473,5.37,2.362,9.006c1.437,2.761,3.581,5.705,5.646,8.542c1.701,2.336,4.278,5.871,4.535,6.404c-0.445,1.184-4.907,3.282-12.229,3.282C30.177,82.591,23.69,80.904,18.941,77.945zM56.86,49.368c0-4.938,4.001-8.943,8.931-8.943c4.941,0,8.942,4.005,8.942,8.943c0,4.931-4.001,8.942-8.942,8.942C60.854,58.311,56.86,54.299,56.86,49.368z M149.159,155.398l-20.63,11.169l13.408,9.293c0,0-49.854,15.813-72.198-6.885c-11.006-11.16-13.06-28.533,4.124-38.84c17.184-10.312,84.609,3.943,84.609,3.943L134.295,147.8L149.159,155.398z";
  // }
  // bullet.adapter.add("fill", (fill, target) => {
  //   return "#679adc";
  // })
  bulletChild.width = 15;
  bulletChild.height = 15;
  
  bulletChild.horizontalCenter = "middle";
  bulletChild.verticalCenter = "middle";
  if(fillColor){
    // 임의의 Color값 지정시 Stroke & Fill 색상 적용 or 아래 옵션 적용
    //  bullet.strokeWidth = 0.1;
    //  bullet.strokeOpacity = 5;
    // strok , fill 컬러를 분리하여 표현해야 할 경우 분리한다.
     bulletChild.stroke = am4core.color(fillColor);
     bulletChild.fill = am4core.color(fillColor);
  }

  let bullethover = bullet.states.create("hover");
  bullethover.properties.scale = 1.3;
  return bullet;
}

/**
 * 지정된 데이터에만 Bullet 생성
 * //https://www.amcharts.com/docs/v4/concepts/bullets/
 * @param {*} selectedColumnName Bullet의 disabled 정보를 담고 있는 컬럼명(해당 컬럼의 값이 false이면 bullet 적용)
 * @param {*} series 
 * @param {*} bulletShape am4core.Circle | am4core.Rectangle | am4core.Triangle | am4core.Line | am4core.Image | am4core.Sprite
 * @param {number} width 
 * @param {number} height 
 * @param {string} horizontalCenter "none" | "left" | "middle" | "right"
 * @param {string} verticalCenter "none" | "top" | "middle" | "bottom"
 * @param {*} strokeColor am4core.color('color code')
 * @param {number} strokeWidth 
 * @param {number} strokeOpacity 
 * @param {*} fillColor am4core.color('color code')
 * @param {number} fillOpacity 
 * @param {number} rotation 
 * @param {string} direction (bulletShape = am4core.Triangle) "left" | "right" | "top" | "bottom"
 * @param {string} href (bulletShape = am4core.Image) image path
 * @param {string} hrefColumnName (bulletShape = am4core.Image) image path를 담고 있는 컬럼명 
 */
export const createSelectedColumnBullet = (selectedColumnName, series, bulletShape, width, height, horizontalCenter, verticalCenter, strokeColor, strokeWidth, strokeOpacity, fillColor, fillOpacity, rotation, direction, href, hrefColumnName) => {
  let bullet = series.bullets.push(new am4charts.Bullet());
  if(selectedColumnName) {
    //하나의 시리즈에서도 다른 bullet을 적용할 수 있다.
    bullet.disabled = true; //기본 설정을 disable로 두고 선택한 컬럼의 값에 따라 abled
    bullet.propertyFields.disabled = selectedColumnName; //column명 - false이면 bullet이 적용된다.
  }
  createBulletChild(bullet, bulletShape, width, height, horizontalCenter, verticalCenter, strokeColor, strokeWidth, strokeOpacity, fillColor, fillOpacity, rotation, direction, href, hrefColumnName);
  let bullethover = bullet.states.create("hover"); //적용시 Legend의 토글 이벤트시 레전드 마커에 적용되는 버그 있음.
  bullethover.properties.scale = 1.3;
  return bullet;
}

const checkedValidValue = (value) => {
  if(typeof value === 'undefiend' || value === null) {
    return false;
  }
  return true;
}

const createBulletChild = (bullet, bulletShape, width, height, horizontalCenter, verticalCenter, strokeColor, strokeWidth, strokeOpacity, fillColor, fillOpacity, rotation, direction, href, hrefColumnName) => {
  if(!bulletShape) bulletShape = am4core.Circle;
  if(!checkedValidValue(width)) width = 15;
  if(!checkedValidValue(height)) height = 15;
  let bulletChild = bullet.createChild(bulletShape);

  if(bulletShape === am4core.Image) {
    if(href) {
      bulletChild.href = href;
    } else if(hrefColumnName) {
      bulletChild.propertyFields.href = hrefColumnName;
    } else {
      bulletChild.href = "https://www.amcharts.com/lib/images/star.svg";
    }
  }
  if(bulletShape === am4core.Sprite) {
    if(href) {
      bulletChild.path = href;
    } else {
      bulletChild.path = "M105.572,101.811c9.889-6.368,27.417-16.464,28.106-42.166c0.536-20.278-9.971-49.506-49.155-50.878C53.041,7.659,39.9,28.251,36.071,46.739l-0.928-0.126c-1.932,0-3.438,1.28-5.34,2.889c-2.084,1.784-4.683,3.979-7.792,4.308c-3.573,0.361-8.111-1.206-11.698-2.449c-4.193-1.431-6.624-2.047-8.265-0.759c-1.503,1.163-2.178,3.262-2.028,6.226c0.331,6.326,4.971,18.917,16.016,25.778c7.67,4.765,16.248,5.482,20.681,5.482c0.006,0,0.006,0,0.006,0c2.37,0,4.945-0.239,7.388-0.726c2.741,4.218,5.228,7.476,6.037,9.752c2.054,5.851-27.848,25.087-27.848,55.01c0,29.916,22.013,48.475,56.727,48.475h55.004c30.593,0,70.814-29.908,75.291-92.48C180.781,132.191,167.028,98.15,105.572,101.811z M18.941,77.945C8.775,71.617,4.992,58.922,5.294,55.525c0.897,0.24,2.194,0.689,3.228,1.042c4.105,1.415,9.416,3.228,14.068,2.707c4.799-0.499,8.253-3.437,10.778-5.574c0.607-0.509,1.393-1.176,1.872-1.491c0.87,0.315,0.962,0.693,1.176,3.14c0.196,2.26,0.473,5.37,2.362,9.006c1.437,2.761,3.581,5.705,5.646,8.542c1.701,2.336,4.278,5.871,4.535,6.404c-0.445,1.184-4.907,3.282-12.229,3.282C30.177,82.591,23.69,80.904,18.941,77.945zM56.86,49.368c0-4.938,4.001-8.943,8.931-8.943c4.941,0,8.942,4.005,8.942,8.943c0,4.931-4.001,8.942-8.942,8.942C60.854,58.311,56.86,54.299,56.86,49.368z M149.159,155.398l-20.63,11.169l13.408,9.293c0,0-49.854,15.813-72.198-6.885c-11.006-11.16-13.06-28.533,4.124-38.84c17.184-10.312,84.609,3.943,84.609,3.943L134.295,147.8L149.159,155.398z";
    }
    // bullet.adapter.add("fill", (fill, target) => {
    //   return "#679adc";
    // })
  }
  if(bulletShape === am4core.Line) {
    bulletChild.x1 = (width/2) * -1;
    bulletChild.y1 = (height/2) * -1;
    bulletChild.x2 = width/2;
    bulletChild.y2 = height/2;
  } else {
    bulletChild.width = width;
    bulletChild.height = height;
  }

  bulletChild.horizontalCenter = horizontalCenter || "middle";
  bulletChild.verticalCenter = verticalCenter || "middle";
  if(!checkedValidValue(rotation)) bulletChild.rotation = rotation;
  if(bulletShape === am4core.Triangle) bulletChild.direction = direction || "top";
  if(strokeColor) bulletChild.stroke = strokeColor;
  if(!checkedValidValue(strokeWidth)) bulletChild.strokeWidth = strokeWidth;
  if(!checkedValidValue(strokeOpacity)) bulletChild.strokeOpacity = strokeOpacity;
  if(fillColor) bulletChild.fill = fillColor;
  if(!checkedValidValue(fillOpacity)) bulletChild.fillOpacity = fillOpacity;

  return bulletChild;
}




/**
 * X축 생성(카테고리)
 * @param {*} chart 
 * @param {string} categoryColumnName 카테고리로 지정할 컬럼명
 * @param {*} xMinGridDistance 지정값에 따라 레이블을 생략한다.
 * @param {string} title 
 * @param {boolean} disabledLabels 레이블 숨김 여부. 레이블 영역 자체를 숨긴다.
 * @param {string} categoryViewText 레이블로 노출할 컬럼명. 지정하지 않으면 categoryColumnName으로 노출한다.
 */
export const createCategoryAxis = (chart, categoryColumnName, xMinGridDistance, title, disabledLabels, categoryViewText = '', sizeChanged=false) => {
  let axis = chart.xAxes.push(new am4charts.CategoryAxis());
  axis.dataFields.category = categoryColumnName; //카테고리가 동일한 값이면 막대 하나로 표현하는데 stack이나 grouped 하지는 않는다
  axis.renderer.minGridDistance = xMinGridDistance || 50;
  axis.renderer.grid.template.location = 0; //차트의 보조선으로 0으로 설정시 첫번째 막대의 왼쪽부터 보조선이 생성된다.
  axis.renderer.grid.template.disabled = true; //차트의 보조선. 사용 안함(세로선) 
  // axis.renderer.grid.template.strokeOpacity = 0; //range의 그리드까지 숨겨진다

  //레이블 관련 ----
  // axis.renderer.inside = true; //레이블이 차트 안의 영역에 표현됨
  axis.renderer.labels.template.valign = "top";
  // axis.renderer.labels.template.align = "center";
  // axis.renderer.labels.template.horizontalCenter = "middle";
  axis.renderer.labels.template.verticalCenter = "top";
  // axis.renderer.labels.template.rotation = 310;
  axis.renderer.labels.template.fontSize = FONT_SIZE_LABEL;
  axis.renderer.labels.template.textAlign = "middle"; //카테고리명 중앙정렬
  axis.renderer.labels.template.disabled = disabledLabels; //레이블 숨김. 레이블 영역 자체를 숨김
  // axis.renderer.labels.template.dy = -100; //레이블 y 위치 수정
  // axis.renderer.labels.template.visible = false; //레이블 숨김. 레이블 영역은 남음. => range가 함께 사라져서 여기선 사용 안함.

  // axis.renderer.inside = true; //데이터 레이블이 차트 안의 영역에 표현됨
  axis.renderer.cellStartLocation = 0.1; //한 카테고리 안의 시작 막대 시작 위치
  axis.renderer.cellEndLocation = 0.9; //한 카테고리 안의 마지막 막대 끝 위치

  axis.dataItems.template.text = ('{'+ (categoryViewText === '' ? categoryColumnName : categoryViewText) +'}');
  // axis.tooltipText = "{pathogen}";
  
  // 카테고리가 같으면 자동으로 겹치는 막대(스택 아님)로 표현하는데 정렬 설정시 오류 발생
  // axis.sortBySeries = customseries; //해당 series의 값으로 내림차순 정렬한다.
  // axis.renderer.inversed = true; //오름차순 정렬하려면 설정
  
  /* 툴팁 커스텀 */
  axis.tooltip.disabled = true; //해당 옵션을 주지 않고 cursor 생성시 툴팁이 보여진다. 툴팁 노출하고 싶지 않다면 disabled=true
  // axis.tooltip.pointerOrientation = "vertical";
  // axis.tooltipText = `[bold]{category}[/] :{count}`;
  // axis.tooltipText = "{coiftPathogenCodes}";


  axis.renderer.minGridDistance = 5;
  let label = axis.renderer.labels.template;
  label.wrap = true;
  label.maxWidth = 90;

  /* sizechanged 사선처리 */
  axis.events.on("sizechanged", function(ev) {
    let axis = ev.target;
    var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    
    // console.log('axis.renderer.labels.template.maxWidth', axis.renderer.labels.template.maxWidth);
    // console.log('cellWidth',cellWidth);
    // axis.renderer.labels.values.forEach(a=> console.log(a.currentText))

    let isLong = false;
    // if (cellWidth < axis.renderer.labels.template.maxWidth || sizeChanged) {
    if (sizeChanged) {
      axis.renderer.labels.template.rotation = -45;
      axis.renderer.labels.template.location = 0.5;
      axis.renderer.labels.template.horizontalCenter = "middle";
      axis.renderer.labels.template.verticalCenter = "middle";
      isLong = true
    }
    else {
      axis.renderer.labels.template.rotation = 0;
      axis.renderer.labels.template.location = 0.5;
      axis.renderer.labels.template.horizontalCenter = "middle";
      axis.renderer.labels.template.verticalCenter = "top";
    }
    //if(axis.renderer.labels.length > 1){ // prevalence each
      axis.axisRanges.values.forEach(o=>{
        if(!o.tick.disabled){
          if(sizeChanged){
            o.label.rotation=-45;
          }else{
            o.label.rotation=0;
          }
          o.label.location=0.55;
        }
      });
    //}
  });

  if(title) axis.title.text = title; //어디에 노출되는지 모르겠다....?

  return axis;
}


const rangeTemplate = (cateAxis, labelDy=0) => {
  //TODO 데이터가 많은 경우 Range Label 생략 필요
  let temp = cateAxis.axisRanges.template;
  temp.label.truncate = true;
  temp.label.fontSize = FONT_SIZE;
  // temp.label.fontWeight = "bold";
  // temp.label.dx = 130;
  // temp.label.dy = 30;
  // temp.grid.stroke = am4core.color('#e2e4e6');
  temp.grid.strokeOpacity = 1;
  temp.grid.location = 1;
  // temp.tick.disabled = tickDisabled;
  temp.tick.location = 1;
  temp.tick.length = (20 + Number(labelDy));
  // temp.tick.stroke = am4core.color("#e2e4e6");
  temp.tick.strokeOpacity = 1;
  // temp.axisFill.fill = am4core.color("#FFF"); //차트 영역 색상
  // temp.axisFill.fillOpacity = 0;
  // temp.axisFill.stroke = am4core.color("#0FF"); //차트 영역의 테두리 및 그리드 색상
  // temp.axisFill.strokeOpacity = 0;
  temp.axisFill.interactionsEnabled = true;
  temp.axisFill.isMeasured = true;
  return temp;
}

const createRange = (chart, cateAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText) => {
  let rangeAxis = cateAxis.axisRanges.create();
  rangeAxis.category = start;
  rangeAxis.endCategory = end;
  // rangeAxis.label.horizontalCenter = 'middle';
  // rangeAxis.label.verticalCenter = 'top';
  let nameFlagText = typeof label.split("\n")[1] !== "undefined" ? label.split("\n")[1]:(typeof nameText !== "undefined" && typeof nameText.split("\n")[1] !== "undefined") ? nameText.split("\n")[1] + "\n\n\n" + label:"";
  rangeAxis.label.text = (nameFlag && type==='candle')? nameFlagText:label;
  rangeAxis.label.tooltipText = label;
  rangeAxis.label.disabled = labelDisabled;
  if(maxWidth) {
    rangeAxis.label.maxWidth = maxWidth;
    rangeAxis.label.truncate = true; //maxWidth를 넘기면 ...으로
    // rangeAxis.label.wrap = true; //maxWidth를 넘기면 자동 줄바꿈
    rangeAxis.label.tooltip = new am4core.Tooltip();
    rangeAxis.label.tooltipText = label;
  }
  rangeAxis.label.fontSize = FONT_SIZE_LABEL;
  // rangeAxis.label.dy = labelDy+15;
  if(labelDy) {
    rangeAxis.label.paddingTop = (nameFlag && type==='candle')? 25:labelDy+25;
  }
  rangeAxis.grid.strokeOpacity = 1;
  rangeAxis.grid.location = 1;
  rangeAxis.tick.location = 1;
  // rangeAxis.tick.length = (20 + Number(labelDy));
  rangeAxis.tick.strokeOpacity = 1;
  rangeAxis.tick.disabled = tickDisabled;
  rangeAxis.axisFill.interactionsEnabled = true;
  rangeAxis.axisFill.isMeasured = true;
  if(labelDy){
    rangeAxis.grid.stroke = am4core.color('#8A98A7');
    rangeAxis.tick.stroke = am4core.color("#8A98A7");
  } else {
    rangeAxis.grid.stroke = am4core.color('#e2e4e6');
    rangeAxis.tick.stroke = am4core.color("#e2e4e6");
  }
  // TODO *** 툴팁
  // rangeAxis.axisFill.tooltip = new am4core.Tooltip();
  // rangeAxis.axisFill.tooltip.fontSize = 12;
  // rangeAxis.axisFill.tooltipPosition = "pointer";
  // rangeAxis.axisFill.tooltipText = "Range:\n[bold]{category}[\] to [bold]{endCategory}[/]";
  // rangeAxis.axisFill.tooltipHTML = createRangeTooltipHTML(chart, startIndex, endIndex);

  return rangeAxis;  
}

/**
 * 대분류 카테고리 표현을 위해 사용 axisRange
 * *** categoryColumnName으로 정렬되어 있어야한다
 *     바로 뒤의 category 값과 같은 경우 하나의 range로 표현하도록 함.
 *     예시 - A, A, B, C => A, B, C (O) / A, B, A, C => A, B, A, C (X)
 * @param {*} chart
 * @param {*} categoryAxis
 * @param {string} categoryColumnName 각 로우의 구분값을 가진 컬럼명
 * @param {string} rangeColumnName range로 표현할 컬럼명(값이 동일한 경우 하나의 range로 표현)
 * @param labelDy
 * @param labelColumnName
 * @param tickDisabled
 * @param {number} maxWidth 0이상의 값을 주면 레이블이 maxWidth를 넘어갈 경우 말줄임 처리 및 hover시 툴팁 제공
 * @param labelDisabled
 * @param nameFlag
 * @param type
 * @param name
 */
export const createCategoryRanges = (chart, categoryAxis, categoryColumnName, rangeColumnName, labelDy, labelColumnName, tickDisabled=false, maxWidth=0, labelDisabled=false, nameFlag=false, type=null, name=null) => {
  let start, end, label, nameText;
  let startIndex = 0;
  let endIndex = 0;

  // 수정필요
  let useLabelColumnName = labelColumnName ? labelColumnName : rangeColumnName;

  //range를 여러개 생성하는 경우 제대로 적용되지 않아서 생성시 각 설정하도록 수정함. 1104
  // rangeTemplate(categoryAxis, labelDy); //range template 적용
  let createdRangeSize = 0;
  chart.data.map((d, index) => {
    if(index == 0) {
      start = d[categoryColumnName];
      end = d[categoryColumnName];
      label = d[useLabelColumnName];
      nameText = d[name];
      startIndex = index;
      endIndex = index;
    } else if(chart.data.length-1 == index) {
      if(chart.data[index-1][rangeColumnName] == d[rangeColumnName]) {
        end = d[categoryColumnName];
        endIndex = index;
        createRange(chart, categoryAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText);
        createdRangeSize++;
      } else {
        createRange(chart, categoryAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText);
        createdRangeSize++;
        start = d[categoryColumnName];
        end = d[categoryColumnName];
        label = d[useLabelColumnName];
        nameText = d[name];
        startIndex = index;
        createRange(chart, categoryAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText);
        createdRangeSize++;
      }
    } else {
      if(chart.data[index-1][rangeColumnName] == d[rangeColumnName]) {
        endIndex = index;
        end = d[categoryColumnName];
      } else {
        createRange(chart, categoryAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText);
        createdRangeSize++;
        start = d[categoryColumnName];
        end = d[categoryColumnName];
        label = d[useLabelColumnName];
        nameText = d[name];
        startIndex = index;
      }
    }

    if(chart.data.length == 1 || chart.data.length == index) {
      createRange(chart, categoryAxis, start, end, label, startIndex, endIndex, labelDy, tickDisabled, maxWidth, labelDisabled, nameFlag, type, nameText);
      createdRangeSize++;
    }
    
  })
  return createdRangeSize;
}


const DATE_FORMATS_KEYS = {day: "yyyy-MM-dd", week: "yyyy-MM-'W'ww", month: "yyyy-MM", quarter: "yyyy-'Q'q", year: "yyyy"};
const dateAxisDateFormatting = (dateAxis, periodType) => {
  let sPeriodType = periodType.toLowerCase();
  for(const key of Object.keys(DATE_FORMATS_KEYS)) {
    dateAxis.periodChangeDateFormats.setKey(key, DATE_FORMATS_KEYS[sPeriodType]);
    dateAxis.dateFormats.setKey(key, DATE_FORMATS_KEYS[sPeriodType]);
    if(key === sPeriodType) break;
  };
  let _tu = sPeriodType==='quarter' ? 'month' : sPeriodType;
  dateAxis.baseInterval = {
    timeUnit: _tu, //quarter가 없음
    count: 1
  };

  let gridIntervals = [
    { timeUnit: _tu, count: 1 },
    { timeUnit: _tu, count: 2 },
    { timeUnit: _tu, count: 3 },
    { timeUnit: _tu, count: 4 },
    { timeUnit: _tu, count: 5 },
    { timeUnit: _tu, count: 10 },
    { timeUnit: _tu, count: 20 },
    { timeUnit: _tu, count: 30 },
    { timeUnit: _tu, count: 40 },
    { timeUnit: _tu, count: 50 },
    { timeUnit: _tu, count: 60 },
    { timeUnit: _tu, count: 70 },
    { timeUnit: _tu, count: 80 },
    { timeUnit: _tu, count: 90 },
    { timeUnit: _tu, count: 100 },
    { timeUnit: _tu, count: 300 },
    { timeUnit: _tu, count: 500 },
    { timeUnit: _tu, count: 1000 },
    { timeUnit: _tu, count: 10000 }
  ];

  if(sPeriodType==='quarter') {
    gridIntervals.splice(0, 2);
  }

  dateAxis.gridIntervals.setAll(gridIntervals);
}


/**
 * X축 생성(DATE)
 * @param {*} chart 
 * @param {string} periodType "DAY" | "WEEK" | "MONTH" | "QUARTER" | "YEAR"
 * @param {number} xMinGridDistance 지정값에 따라 레이블을 생략한다.
 * @param {string} title 
 */
export const createDateAxis = (chart, periodType, xMinGridDistance, title) => {
  let axis = chart.xAxes.push(new am4charts.DateAxis());
  axis.tooltip.disabled = true;
  axis.renderer.labels.template.location = 0.5;
  axis.renderer.grid.template.location = 0.5;
  // axis.skipEmptyPeriods = true; //데이터가 있는 레이블만 표시
  // 입력받는 date string 의 format
  axis.dateFormatter.inputDateFormat = 'yyyyMMdd';
  dateAxisDateFormatting(axis, periodType ? periodType : 'DAY');
  axis.renderer.minGridDistance = xMinGridDistance || 100;
  if(title) axis.title.text = title;
  return axis;
}

/**
 * Y축 생성(value)
 * @param {*} chart 
 * @param {string} title 
 * @param {boolean} opposite false면 왼쪽에 true면 오른쪽에 생성
 */
export const createValueAxisY = (chart, title, opposite, setMaxY) => {
  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  /* 
  if(yMinGridDistance) {
    valueAxis.renderer.minGridDistance=yMinGridDistance;
  }
  if(minY) {
    valueAxis.min = minY;
  }
  if(maxY) {
    valueAxis.max = maxY;
  }
  // custom 값 세팅
  if(customValueAxisData){
    valueAxis.renderer.labels.template.adapter.add("text", (text) => {
      let rtnVal = customValueAxisData.series.filter(e => e.value == text).map(e =>(e.customValue));
      
      return rtnVal;
    });
  } */
  
  valueAxis.renderer.opposite = opposite;
  valueAxis.renderer.labels.template.fontSize = FONT_SIZE;
  valueAxis.tooltip.disabled = true;
  valueAxis.min = 0;
  if(setMaxY) {
    valueAxis.max = setMaxY;
  } else {
    valueAxis.extraMax = 0.2; //설정된 min, max보다 20% 확장
  }
  valueAxis.strictMinMax = true; //min, max 설정을 따르도록 강제하려면 true

  if(title) {
    // valueAxis.layout = 'absolute';
    // valueAxis.paddingLeft = 10;
    // valueAxis.paddingRight = 10;
    valueAxis.title.text = title;
    // valueAxis.title.rotation = 0;
    valueAxis.title.align = 'center';
    valueAxis.title.valign = 'middle';
    valueAxis.title.fontSize = 14;
    valueAxis.title.fontWeight = "800";
    // valueAxis.title.dy = -25; //TODO 위치 조정 필요
  }
  
  // valueAxis.renderer.minWidth = 35;
  valueAxis.maxPrecision = 0; //차트가 확대되도 y축 레이블을 정수만 표현
  // valueAxis.renderer.grid.template.disabled = true; //차트의 보조선. 사용 안함(가로선) 
  // valueAxis.renderer.grid.template.strokeDasharray = '3,5'; //차트의 보조선. 점선으로
  return valueAxis;
}

/**
 * 차트 영역 일부 생략
 * @param {*} valueAxis 
 * @param {number} breakStartValue 생략할 영역의 시작값
 * @param {number} breakEndValue 생략할 영역의 마지막값
 */
export const addAxisBreak = (valueAxis, breakStartValue, breakEndValue) => {
  let axisBreak = valueAxis.axisBreaks.create();
  axisBreak.startValue = breakStartValue;
  axisBreak.endValue = breakEndValue;
  axisBreak.breakSize = 0.1; //생략한 영역을 보여줄 크기(높이)
  // fixed axis break - breakSize를 생략한 y 범위가 전체에서 차지하는 비율을 고려해 지정.
  // let depth = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min); // max를 설정하지 않은 상태에서 오류 발생.
  // axisBreak.breakSize = 0.05 * (1 - depth) / depth; // 0.05 means that the break will take 5% of the total value axis height
  axisBreak.defaultState.transitionDuration = 1000;
  let hoverState = axisBreak.states.create("hover"); // 생략한 영역에 마우스오버시 생략된 차트를 보여준다.
  hoverState.properties.breakSize = 1;
  hoverState.properties.opacity = 0.1;
  hoverState.transitionDuration = 1500;
  return axisBreak;
}


const initLegend = (position, scrollable, maxHeight, maxWidth) => {
  let legend = new am4charts.Legend();
  legend.fontSize = FONT_SIZE;
  legend.position = position || 'bottom';
  legend.maxHeight = maxHeight || undefined;
  legend.maxWidth = maxWidth || LEGEND_WIDTH;
  legend.width = LEGEND_WIDTH;
  legend.scrollable = scrollable;
  legend.labels.template.truncate = false;
  legend.labels.template.wrap = true;
  legend.itemContainers.template.paddingTop = 1;
  legend.itemContainers.template.paddingBotton = 1;

  // legend.labels.template.adapter.add("textOutput", (text) => { 
  //   return typeof text !== typeof undefined ? text.replace(/(\|)/g, "\n") : text; 
  // });
  return legend;
}

/**
 * 범례 생성
 * @param {*} chart 
 * @param {string} position "top" | "bottom" | "left" | "right"
 * @param {boolean} scrollable 스크롤 사용 여부
 * @param {number} maxWidth 제한을 두고 싶지 않으면 undefined
 * @param {number} maxHeight 제한을 두고 싶지 않으면 undefined
 */
export const createLegend = (chart, position, scrollable, maxHeight, maxWidth, productFlag=true) => {
  let legend = initLegend(position, scrollable, maxHeight, maxWidth);
  legend.background.fill = am4core.color("#fff");
  legend.labels.template.text = productFlag? '{name}' :'{toggleName}';
  chart.legend = legend;
  return legend;
}


/**
 * 커스텀 범례 생성(범례의 마커를 Serise와 관계없이 별도 정의한다)  
 * *마커의 커스텀이 가능 하도록 수정이 필요할지도...
 * @param {*} chart 
 * @param {string} position "top" | "bottom" | "left" | "right"
 * @param {boolean} scrollable 스크롤 사용 여부
 * @param {number} maxHeight 제한을 두고 싶지 않으면 undefined
 * @param {number} maxWidth 제한을 두고 싶지 않으면 undefined
 */
export const createCustomLegend = (chart, position, scrollable, maxHeight, maxWidth, useTooltip, disabledCursor, productFlag=true) => {
  let legend = createLegend(chart, position, scrollable, maxHeight, maxWidth, productFlag);
  legend.useDefaultMarker = true;

  // 2020.11.12.Line Chart는 Legend 관련하여 아직 정의 되지 않아 이쪽에 먼저 설정. 
  if(useTooltip){
    // legend.maxWidth = 168;
    // legend.width = 168;
    // legend.labels.template.truncate = true;
    legend.tooltip = new am4core.Tooltip();
    legend.tooltip.background.fill = TOOLTIP_BACKGROUND_FILL; //툴팁 배경색 지정하기
    legend.tooltip.background.fillOpacity = TOOLTIP_BACKGROUND_FILL_OPACITY; //별도 지정하지 않으면 bar의 옵션값을 따라간다.
    legend.tooltip.getFillFromObject = false; //툴팁 배경색 지정시 해당 옵션에 false를 주어야 적용된다.
    legend.tooltip.label.fill = TOOLTIP_TEXT_COLOR;
    legend.tooltip.dy = -8
    // legend.tooltip.label.fontSize = TOOLTIP_FONT_SIZE;

    //툴팁에 쉐도우 효과 커스텀
    let tooltipShadow = legend.tooltip.background.filters.getIndex(0);
    tooltipShadow.dx = TOOLTIP_SHADOW_DX;
    tooltipShadow.dy = TOOLTIP_SHADOW_DY;
    tooltipShadow.blur = TOOLTIP_SHADOW_BLUR;
    tooltipShadow.color = TOOLTIP_SHADOW_COLOR;
    
    legend.itemContainers.template.adapter.add("tooltipText", function(text, target) {
      if (!target.dataItem.label.isOversized) {
           // Legend label is NOT truncated, disable the tooltip
           return "";
      }
      // Legend label is truncated, display the tooltip
        return "{name}";
    })
  }
  
  if(disabledCursor){
    // 툴팁 설정시 togglable 부분이 true로 바뀌는듯??
    legend.itemContainers.template.togglable = false;
    legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
 }
  
  //Legend의 marker 설정
  let marker = legend.markers.template;
  marker.width = 15;
  marker.height = 15;
  marker.fillOpacity = 0.9;

  let markerChild = marker.children.getIndex(0);
  markerChild.cornerRadius(10, 10, 10, 10);
  // markerChild.paddingTop = 10;
  markerChild.strokeWidth = 1;
  markerChild.strokeOpacity = 1;
  markerChild.stroke = am4core.color("#FFF");

  return legend;
}


/**
 * column으로 범례 생성
 * @param {*} chart 
 * @param {*} series ColumnSeries
 * @param {*} categoryColumnName 
 * @param {*} xAxis CategoryAxis
 */
export const createCustomItemLegend = (chart, series, categoryColumnName, xAxis, position, scrollable, maxHeight, maxWidth) => {
  let legend = initLegend(position, scrollable, maxHeight, maxWidth);
  // legend.parent = chart.chartContainer;
  legend.itemContainers.template.togglable = false;
  legend.marginTop = 20;
  series.events.on("dataitemsvalidated", function(ev) {
    var legenddata = [];
    series.columns.each(function(column) {
      legenddata.push({
        name: column.dataItem.dataContext[categoryColumnName],
        fill: column.fill,
        fillOpacity: column.fillOpacity,
        seriesDataItem: column.dataItem
      });
      
      /* let axisBreak = xAxis.axisBreaks.create();
      console.log(column.dataItem.dataContext[categoryColumnName]);
      axisBreak.startCategory = column.dataItem.dataContext[categoryColumnName];
      axisBreak.endCategory = column.dataItem.dataContext[categoryColumnName];
      axisBreak.startLocation = 0;
      axisBreak.endLocation = 1;
      axisBreak.breakSize = 1;
      // axisBreak.fillShape.disabled = true;
      axisBreak.fillShape.fillOpacity = 0.7;
      axisBreak.fillShape.width = 0;
      // axisBreak.startLine.disabled = true;
      // axisBreak.endLine.disabled = true;
      // axisBreak.defaultState.transitionDuration = 1000;
      toggleBreaks[column.dataItem.dataContext[categoryColumnName]] = axisBreak; */

    });
    legend.data = legenddata;
    legend.itemContainers.template.events.on("toggled", function(event) {
      let _name = event.target.dataItem.dataContext.name;
      
      var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
      if (event.target.isActive) {
        // toggleBreaks[_name].animate({property:"breakSize", to:0}, 1000, am4core.ease.cubicOut);
        xAxis.dataItems.each((dataItem) => { //X축의 레이블 hide
          if(dataItem.dataContext[categoryColumnName] === _name) {
            dataItem.hide();
          }
        })
        seriesDataItem.hide(); //column hide
        // seriesDataItem.hide(series.interpolationDuration, 0, 0, ["valueY"]);
      }
      else {
        //Column은 break시에도 최소 column 하나의 너비를 갖는다.
        // toggleBreaks[_name].animate({property:"breakSize", to:1}, 1000, am4core.ease.cubicOut);
        xAxis.dataItems.each((dataItem) => { //X축의 레이블 show
          if(dataItem.dataContext[categoryColumnName] === _name) {
            dataItem.show();
          }
        })
        seriesDataItem.show(); //column show
        // seriesDataItem.show(series.interpolationDuration, 0, ["valueY"]);
      }
    })
  });
  chart.legend = legend;
  return legend;
}



/**
 * 특정 값의 가이드 라인 생성
 * @param {*} valueAxis 
 * @param {*} limitLineValue 
 */
export const createGuideY = (valueAxis, limitLineValue) => {
  let range = valueAxis.axisRanges.create();
  range.value = limitLineValue;
  range.grid.stroke = am4core.color('red');
  range.grid.strokeWidth = 1;
  range.grid.strokeOpacity = 1;
  range.grid.strokeDasharray = '3,3';
}



/**
 * X축 스크롤바 생성
 * @param {*} chart  
 * @param {boolean} disabledGrip grip 사용여부
 * @param {number} start start grip 위치 지정(Relative position (0-1))
 * @param {number} end end grip 위치 지정(Relative position (0-1))
 */
export const createScrollbarX = (chart, disabledGrip, start, end) => {
  let scrollbarX = new am4core.Scrollbar();
  scrollbarX.animationDuration = 500;
  scrollbarX.minHeight = 5;
  scrollbarX.background.fill = am4core.color("#F2F5F8");
  scrollbarX.background.fillOpacity = 1;
  scrollbarX.thumb.background.fill = SCROLLBAR_COLOR_FILL;
  scrollbarX.thumb.background.fillOpacity = SCROLLBAR_FILL_OPACITY;
  scrollbarX.startGrip.width=15;
  scrollbarX.startGrip.height=15;
  scrollbarX.startGrip.background.fill = am4core.color("#8A98A7");
  scrollbarX.startGrip.icon.width=3;
  scrollbarX.startGrip.icon.height=5;
  scrollbarX.endGrip.width=15;
  scrollbarX.endGrip.height=15;
  scrollbarX.endGrip.background.fill = am4core.color("#8A98A7");
  scrollbarX.endGrip.icon.width=3;
  scrollbarX.endGrip.icon.height=5;

  scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#8A98A7");
  scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#8A98A7");
  scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#8A98A7");

  if(disabledGrip) {
    scrollbarX.startGrip.disabled = disabledGrip;
    scrollbarX.endGrip.disabled = disabledGrip;
  } else {
    /* scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    scrollbarX.endGrip.icon.stroke = am4core.color("#fff"); */
    scrollbarX.startGrip.icon.disabled=true;
    scrollbarX.endGrip.icon.disabled=true;
  }
  if(!start && !end) {
    //TODO 데이터가 많은 경우 초기에 보여줄 범위 조정 필요
    if(chart.data.length > 18){ // 18개로 갯수 조정
      scrollbarX.end = (18 / chart.data.length).toFixed(2);
    }
  } else {
    if(start) scrollbarX.start = start;
    if(end) scrollbarX.end = end;
  }
  chart.scrollbarX = scrollbarX;

  chart.scrollbarX.exportable = false; //export시 scroll bar 제외

  if(SCROLLBAR_POSITION_BOTTOM) chart.scrollbarX.parent = chart.bottomAxesContainer; //스크롤바를 차트 하단에 넣을 경우

  return scrollbarX;
}

/**
 * Y축 스크롤바 생성
 * @param {*} chart  
 * @param {boolean} disabledGrip grip 사용여부
 * @param {number} start start grip 위치 지정(Relative position (0-1))
 * @param {number} end end grip 위치 지정(Relative position (0-1))
 */
export const createScrollbarY = (chart, disabledGrip, start, end) => {
  let scrollbarY = new am4core.Scrollbar();
  scrollbarY.animationDuration = 500;
  scrollbarY.thumb.background.fill = SCROLLBAR_COLOR_FILL;
  scrollbarY.thumb.background.fillOpacity = SCROLLBAR_FILL_OPACITY;
  scrollbarY.startGrip.background.fill = am4core.color("#8A98A7");
  scrollbarY.endGrip.background.fill = am4core.color("#8A98A7");
  scrollbarY.minWidth = 5;
  scrollbarY.startGrip.width=15;
  scrollbarY.startGrip.height=15;
  scrollbarY.startGrip.background.fill = am4core.color("#8A98A7");
  scrollbarY.startGrip.icon.width=3;
  scrollbarY.startGrip.icon.height=5;
  scrollbarY.endGrip.width=15;
  scrollbarY.endGrip.height=15;
  scrollbarY.endGrip.background.fill = am4core.color("#8A98A7");
  scrollbarY.endGrip.icon.width=3;
  scrollbarY.endGrip.icon.height=5;

  if(disabledGrip) {
    scrollbarY.startGrip.disabled = disabledGrip;  
    scrollbarY.endGrip.disabled = disabledGrip;  
  } else {
    /* scrollbarY.startGrip.icon.stroke = am4core.color("#fff");
    scrollbarY.endGrip.icon.stroke = am4core.color("#fff"); */
    scrollbarY.startGrip.icon.disabled=true;
    scrollbarY.endGrip.icon.disabled=true;
  }

  //TODO 확인 필요. series 그려지면서 리셋됨
  if(start) scrollbarY.start = start;
  if(end) scrollbarY.end = end;

  chart.scrollbarY = scrollbarY;
  
  chart.scrollbarY.exportable = false; //export시 scroll bar 제외
  
  return scrollbarY;
}


/**
 * X축/Y축 스크롤바 생성
 * @see {@link https://www.amcharts.com/docs/v4/tutorials/customizing-chart-scrollbar/} for custom
 * @param {*} chart 
 * @param {boolean} disabledGripX X축 스크롤바 그립의 사용여부
 * @param {number} startX X축 start grip 위치 지정(Relative position (0-1))
 * @param {number} endX X축 end grip 위치 지정(Relative position (0-1))
 * @param {boolean} disabledGripY Y축 스크롤바 그립의 사용여부
 * @param {number} startY Y축 start grip 위치 지정(Relative position (0-1))
 * @param {number} endY Y축 end grip 위치 지정(Relative position (0-1))
 */
export const createScrollbar = (chart, disabledGripX, startX, endX, disabledGripY, startY, endY) => {
  createScrollbarX(chart, disabledGripX, startX, endX);
  createScrollbarY(chart, disabledGripY, startY, endY);
}


/**
 * 차트 영역 드래그 이벤트 정의
 * @param {*} chart 
 * @param {string} behavior "zoomX" | "zoomY" | "zoomXY" | "selectX" | "selectY" | "selectXY" | "panX" | "panY" | "panXY" | "none"
 * @param {boolean} disabledX 
 * @param {boolean} disabledY 
 */
export const createCursor = (chart, behavior, disabledX, disabledY) => {
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = behavior;
  chart.cursor.lineX.disabled = disabledX;
  chart.cursor.lineY.disabled = disabledY;
  chart.cursor.maxTooltipDistance = 20;
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * 차트 데이터 익스포트 메뉴
 * @param {*} chart 
 */
export const createExportChart = (chart, exportMenuElement, exportFileName="SG STATS", handleLoading, exportFilter) => {
  /* export chart */
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.container = exportMenuElement;
  chart.exporting.menu.align = "right";
  chart.exporting.menu.verticalAlign = "top";
  chart.exporting.menu.items = [{
    "label" : "...",
    "menu" : [
      { "type": "png", "label": "Download PNG image" },
      { "type": "jpg", "label": "Download JPG image" },
      { "type": "pdf", "label": "Download PDF document" },
      { "type": "custom", "label": "Download EXCEL", options: {
        callback: () => {
          downloadExcel(exportFilter).then((response) => {
            let DOWNLOAD_EL_ID = 'excel_dl_id';
            let _download = document.getElementById(DOWNLOAD_EL_ID);
            if(!_download) {
                _download = document.createElement('a');
            }
            _download.id = DOWNLOAD_EL_ID;
            _download.href = window.URL.createObjectURL(new Blob([response.data]));
            _download.setAttribute('download', `${exportFileName}.xlsx`);
            document.body.appendChild(_download);
            _download.click();
          }).catch(err => {
            toast.error('Failed download EXCEL');
          });
        }
      } },
      { "type": "svg", "label": "Download SVG vector image" }
    ]
  }];
  chart.exporting.filePrefix = exportFileName;
  
  chart.exporting.formatOptions.getKey("pdf").addURL = false;

  let options = chart.exporting.getFormatOptions("png");
  options.minWidth = 1280;
  options.minHeight = 720;
  options.maxWidth = 1920;
  options.maxHeight = 1080;

  chart.exporting.events.on("exportstarted", function(ev) {
    if (ev.format == "png") {
      if (navigator.appVersion.indexOf("Win")!=-1){
        handleLoading('start');
        let chartdiv = document.getElementById("chartdiv");
        document.getElementById("chartdiv").style.visibility = 'hidden';
        if(chart.legend){
          // chart.legend.fontSize = FONT_SIZE;
          //chart.legend.disabled = true;
          // chart.legend.width = LEGEND_WIDTH + 50;
          chart.legend.height = chart.legend.contentHeight;
          // chart.legend.scrollable = false;
        }
        chart.xAxes.values.forEach(o=>{
          o.renderer.labels.template.fontSize = FONT_SIZE;
          o.renderer.labels.template.maxWidth = 100;
          //o.axisRanges.values.forEach(a=>{a.label.fontSize = FONT_SIZE;});
        });
        chartdiv.style.position = 'absolute';
        chartdiv.style.height = '100vh';
        chartdiv.style.width = '100vw';
      }
    }
  });
 
  chart.exporting.events.on("exportfinished", function(ev) {
    if (ev.format == "png") {
      if (navigator.appVersion.indexOf("Win")!=-1){
        let chartdiv = document.getElementById("chartdiv");
        if(chart.legend){
          //chart.legend.disabled = false;
          chart.legend.height = '100%';
          chart.legend.width = LEGEND_WIDTH;
          // chart.legend.scrollable = true;
          // chart.legend.fontSize = FONT_SIZE_LABEL;
        }
        chart.xAxes.values.forEach(o=>{
          o.renderer.labels.template.fontSize = FONT_SIZE_LABEL;
          o.renderer.labels.template.maxWidth = 90;
          //o.axisRanges.values.forEach(a=>{a.label.fontSize = FONT_SIZE_LABEL;});
        });
        chartdiv.style.position = 'relative';
        chartdiv.style.width = '100%';
        chartdiv.style.height = '100%';
        setTimeout(() => {
          chartdiv.style.visibility = 'visible';
          handleLoading('end');
        }, 500);
      }
    }
  });

  //익스포트 Data의 경우 차트의 데이터가 아닌 다른 값을 넘길 수 있다.
  /* chart.exporting.adapter.add("data", (data) => {
    data.data = [{test: 1, test2: 2}];
    return data;
  }); */
  //익스포트 아이템을 각각 사용여부 설정하거나 전체를 셋할수도 있다
  // chart.exporting.formatOptions.getKey("csv").disabled = true;
  // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
  /* chart.exporting.menu.items = [{
    "label" : "...",
    "menu" : [
      {
        "label": "Image",
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "jpg", "label": "JPG" },
          { "type": "svg", "label": "SVG" },
          { "type": "pdf", "label": "PDF" }
        ]
      }, 
      {
        "label": "Data",
        "menu": [
          { "type": "json", "label": "JSON" },
          { "type": "csv", "label": "CSV" },
          { "type": "xlsx", "label": "XLSX" },
          { "type": "html", "label": "HTML" },
          { "type": "pdfdata", "label": "PDF" }
        ]
      }, 
      {
        "label": "Print", "type": "print"
      }
    ]
  }]; */
  
}


/**
 * CT 차트의 plot box
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} dateColumnName 
 * @param {string} minColumnName           // min
 * @param {string} lowerQuartileColumnName // 25%
 * @param {string} upperQuartileColumnName // 75%
 * @param {string} maxColumnName           // max
 * @param {*} tooltipHtml null인 경우 툴팁 생성 안함.
 */
const createCandlestickSeries = (chart, categoryColumnName, dateColumnName, minColumnName, lowerQuartileColumnName, upperQuartileColumnName, maxColumnName, tooltipText, tooltipTitle) => {
  let candleSeries = chart.series.push(new am4charts.CandlestickSeries());
  if(categoryColumnName) {
    candleSeries.dataFields.categoryX = categoryColumnName;
  } else if(dateColumnName) {
    candleSeries.dataFields.dateX = dateColumnName;
  }
  candleSeries.dataFields.valueY = upperQuartileColumnName; //75%
  candleSeries.dataFields.openValueY = lowerQuartileColumnName; //25%
  candleSeries.dataFields.lowValueY = minColumnName; //min
  candleSeries.dataFields.highValueY = maxColumnName; //max
  candleSeries.simplifiedProcessing = true;
  
  // candleSeries.tooltipText = tooltipText;
  // candleSeries.adapter.add('tooltipText', (text, target) => {
  //   return `[${chart.colors.getIndex(target.dataItem.index)}]●[/] ${text}`;
  // });
  
  // candleSeries.tooltip.dy = TOOLTIP_OFFSET; //tooltip 노출 위치 조정
  candleSeries.tooltip = createTooltipTemplate();

  if(tooltipText) {
    candleSeries.columns.template.tooltipText = tooltipText;
    candleSeries.tooltip.pointerOrientation = "right"; // "horizontal" | "vertical" | "up" | "down" | "right" | "left"
    candleSeries.tooltip.fitPointerToBounds = true;
    // candleSeries.tooltip.fontSize = TOOLTIP_FONT_SIZE;
    candleSeries.tooltip.animationDuration = 500;
    candleSeries.columns.template.tooltipX = -3;
    candleSeries.columns.template.tooltipY = am4core.percent(0);

    candleSeries.columns.template.adapter.add('tooltipText', (text, target) => {
      let colorIdx = target.dataItem.colorIndex ? target.dataItem.colorIndex : target.dataItem.index;
      return `${tooltipTitle ? tooltipTitle+'\n' : ''}[${chart.colors.getIndex(colorIdx)}]●[/] ${text}
        [bold]Mean[/] : {average}
        [bold]Median[/] : {median}
        [bold]Max[/] : {highValueY.value}
        [bold]Min[/] : {lowValueY.value}
        [bold]Q3(75%)[/] : {valueY.value}
        [bold]Q1(25%)[/] : {openValueY.value}`;
    });
  } else {
    candleSeries.tooltip.disabled = true;
  }
  candleSeries.riseFromOpenState = undefined;
  candleSeries.dropFromOpenState = undefined;
  candleSeries.fillOpacity = 0;
  candleSeries.strokeWidth = 1.5;
  // candleSeries.sequencedInterpolation = true;

  //로우마다 컬러 다르게 - 필요한 경우 각자 처리하도록
  /* candleSeries.columns.template.adapter.add("stroke", (value, target) => {
      return chart.colors.getIndex(target.dataItem.index);
  }); */
  
  //샘플이 하나인 경우 plot을 투명하게 처리해서 보이지 않도록한다.
  //근데 median, min, max 라인은 개별 처리가 안된다. 차트를 그리기위한 컬럼 추가하고 null로 처리함.
  //위 세개값이 null이면 plot 그리지 않음. 별도 처리 필요없음.
  /* candleSeries.columns.template.adapter.add("strokeOpacity", function(value, target) {
      if(chart.data[target.dataItem.index].isCandleStick) {
          return 1;
      } else {
          return 0;
      }
  });  */

  return candleSeries;
}

/**
 * CT 차트의 plot box (카테고리 X축)
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} dateColumnName 
 * @param {string} minColumnName           // min
 * @param {string} lowerQuartileColumnName // 25%
 * @param {string} upperQuartileColumnName // 75%
 * @param {string} maxColumnName           // max
 * @param {*} tooltipHtml null인 경우 툴팁 생성 안함.
 */
export const createCategoryCandlestickSeries = (chart, categoryColumnName, minColumnName, lowerQuartileColumnName, upperQuartileColumnName, maxColumnName, tooltipText, tooltipTitle) => {
  return createCandlestickSeries(chart, categoryColumnName, null, minColumnName, lowerQuartileColumnName, upperQuartileColumnName, maxColumnName, tooltipText, tooltipTitle);
}

/**
 * CT 차트의 plot box (Date X축)
 * @param {*} chart 
 * @param {string} dateColumnName 
 * @param {string} minColumnName            // min
 * @param {string} lowerQuartileColumnName  // 25%
 * @param {string} upperQuartileColumnName  // 75%
 * @param {string} maxColumnName            // max
 * @param {*} tooltipHtml  null인 경우 툴팁 생성 안함.
 */
export const createDateCandlestickSeries = (chart, dateColumnName, minColumnName, lowerQuartileColumnName, upperQuartileColumnName, maxColumnName, tooltipText, tooltipTitle) => {
  return createCandlestickSeries(chart, null, dateColumnName, minColumnName, lowerQuartileColumnName, upperQuartileColumnName, maxColumnName, tooltipText, tooltipTitle);
}


/**
 * 각각의 막대(or candlestick)에 다른 컬러 적용하기(테마에 적용된 컬러셋을 막대별로 순차 적용) 
 * columnSeries, candlestickSeries 이외의 Series 적용 가능 여부는 확인 필요.
 * @param {*} chart 
 * @param {*} series 
 * @param {string} customType "stroke" | "fill"
 */
export const setColorForEachItem = (chart, series, customType) => {
  series.columns.template.adapter.add(customType, (value, target) => {
    return chart.colors.getIndex(target.dataItem.index);
  });
}

/**
 * 각각의 막대(or candlestick)에 다른 컬러 적용하기(테마에 적용된 컬러셋을 막대별로 순차 적용) 
 * columnSeries, candlestickSeries 이외의 Series 적용 가능 여부는 확인 필요.
 * 2020.11.26 Prevalence Single-Infection vs Co-Infection 에서 사용하기 위해 추가
 * 추후 필요시 createdS.dataFields.colorIndex 와같이 dataFields에 colorIndex를 할당하여 사용
 * @param {*} chart 
 * @param {*} series 
 * @param {string} customType "stroke" | "fill"
 */
export const setColorForEachItemByColorIndex = (chart, series, customType) => {
  series.columns.template.adapter.add(customType, (value, target) => {
    return chart.colors.getIndex(target.dataItem.colorIndex);
  });
}

/**
 * stepLineSeries (CT 차트에서 min, median, max 라인 표현을 위해 사용)
 * @param {*} chart 
 * @param {string} categoryColumnName 
 * @param {string} dateColumnName 
 * @param {string} valueColumnName 
 * @param {*} stepColor 
 */
const createStepLineSeries = (chart, categoryColumnName, dateColumnName, valueColumnName, stepColor, strokeDasharray=false) => {
  let stepSeries = chart.series.push(new am4charts.StepLineSeries());
  stepSeries.noRisers = true; //다음 로우와의 사이를 연결 X (false인 경우는 사용하지 않을듯해 옵션으로 빼지 않음)
  stepSeries.startLocation = 0.18;
  stepSeries.endLocation = 0.82;
  stepSeries.dataFields.valueY = valueColumnName;
  if(categoryColumnName) {
    stepSeries.dataFields.categoryX = categoryColumnName;
  } else if(dateColumnName) {
    stepSeries.dataFields.dateX = dateColumnName;
  }
  if(stepColor) {
    // stepSeries.stroke = chart.colors.getIndex(0);
    stepSeries.stroke = stepColor;
  }
  stepSeries.strokeWidth = 1.5;
  if(strokeDasharray) stepSeries.strokeDasharray = '5';
  return stepSeries;
}

export const createCategoryStepLineSeries = (chart, categoryColumnName, valueColumnName, stepColor, strokeDasharray) => {
  return createStepLineSeries(chart, categoryColumnName, null, valueColumnName, stepColor, strokeDasharray);
}

export const createDateStepLineSeries = (chart, dateColumnName, valueColumnName, stepColor) => {
  return createStepLineSeries(chart, null, dateColumnName, valueColumnName, stepColor);
}



/**
 * CT chart의 ct값 도트
 * @param {*} chart 
 * @param {*} ctValuesColumnName 
 */
export const createCircleBulletForCTchart = (chart, ctValuesColumnName, tooltipText, tooltipTitle, colorIndex, dotFlag, yAxis) => {
  // 1. candlestick 영역별로 ct값들을 찍기 위해 새로운 x축을 생성
  let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
  xAxis.min = 0;
  xAxis.max = chart.data.length;
  xAxis.strictMinMax = true; //min, max 설정 강제
  xAxis.keepSelection = true;
  xAxis.renderer.grid.template.above = true;
  xAxis.renderer.grid.template.strokeOpacity = 0;
  xAxis.renderer.labels.template.disabled = true; //레이블 숨김. 레이블 영역이 사라짐
  // xAxis.renderer.labels.template.visible = false; //레이블 숨김. 레이블 영역은 남아있음
  xAxis.tooltip.disabled = true;
  
  // 2. 각각의 CT 값의 x, y 좌표 계산
  let ctData = [];
  for(let i=0; i<chart.data.length; i++) {
    if(chart.data[i][ctValuesColumnName]){
      let ctlength = chart.data[i][ctValuesColumnName].length;
      chart.data[i][ctValuesColumnName].map((ctValue, j) => {
        let dx = Math.random() + i;
        if(ctlength === 1) dx = i+0.5; //샘플이 하나인 경우 가운데에 찍자...
        let colorIdx = colorIndex ? chart.data[i][colorIndex] : i;
        
        ctData.push({
          column: chart.data[i],
          hasPlot : (ctlength === 1 ? false : true),
          x : (dx < i+0.2 ? dx+0.2 : (dx > i+0.8 ? dx-0.2 : dx)), //캔들스틱 그릴때 0.2~0.8 영역에 그렸으니까 벗어나지 않도록 계산
          y : ctValue,
          color : chart.colors.getIndex(colorIdx) //candleStick과 같은 색으로 그리기 위함
          // color : data[i].color
          // color : '#000'
        });
      })
    }
  }
  
  // 3. 2에서 구한 좌표로 LineSeries 생성(bullet을 찍기위함으로 opacity=0으로 처리해 보이지 않도록 한다.)
  let lineSeries = chart.series.push(new am4charts.LineSeries());
  lineSeries.data = ctData;
  // lineSeries.yAxis = valueAxis;
  lineSeries.xAxis = xAxis;
  lineSeries.dataFields.valueX = "x";
  lineSeries.dataFields.valueY = "y";
  lineSeries.strokeOpacity = 0;
  if(yAxis){
    lineSeries.yAxis = yAxis;
  }
  // lineSeries.minBulletDistance = 0.3;    // 데이터 포인트 사이의 거리가 해당 px보다 가까우면 모두 숨김. (Case가 많아서 Bullet의 수가 너무 많을 경우 고려해야 할지도..?)
  // lineSeries.sequencedInterpolation = true;
  // lineSeries.tooltip.pointerOrientation = 'vertical'; //"horizontal" | "vertical" | "up" | "down" | "right" | "left"
  // lineSeries.hiddenState.transitionDuration = 5000; //범례를 클릭하여 숨길때 천천히 사라지도록 한다.
  // lineSeries.defaultState.transitionDuration = 5000; //범례를 클릭하여 나타낼때 천천히 나타나도록 한다.
  // lineSeries.interpolationDuration = 5000; //범례를 클릭하여 숨기거나 나타낼때 왼쪽부터 순차적으로 천천히 숨겨지거나 나타나도록 한다.
  // lineSeries.sequencedInterpolationDelay = 100;
  
  // 4. 3의 LineSeries에 bullet 생성
  // let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
  let bullet = lineSeries.bullets.push(new am4core.Circle()); // Bullet이 많아지면 속도문제로 특별히 요소별 Instance가 필요없는 경우 Core Circle 사용
  bullet.stroke = am4core.color('#FFF');
  bullet.strokeOpacity = 0.3;
  //차트 테마의 컬러로 설정
  // bullet.adapter.add("fill", (value, target) => {
  //     return chart.colors.getIndex(target.dataItem.index);
  // });
  bullet.propertyFields.fill = "color"; //데이터의 color 컬럼값으로 설정
  bullet.fillOpacity = 1;
  // bullet.circle.radius = 3;
  bullet.radius = 3;
  bullet.tooltipText = '{y}';
  bullet.tooltip = createTooltipTemplate();
  bullet.adapter.add('tooltipText', (value, target) => {
    // if(ctData[target.dataItem.index].hasPlot) return null;
    return `${tooltipTitle ? tooltipTitle+'\n' : ''}[{color}]●[/] ${tooltipText ? tooltipText : ''} {y}`;
  });

  lineSeries.disabled = !dotFlag;
}


//Stacked Area Line hover
const processStackedAreaSeriseOver = (chart, ev, state) => {
  chart.series.each(srs=> {
    if(srs != ev.target) {
      srs.cursorTooltipEnabled = false;
      srs.segments.each(sgm=> {
        sgm.setState(state);
      })
    } 
    /* else {
      srs.segments.each(sgm=> {
        sgm.isHover = (state==='dimmed');
      })
    } */
  })
  ev.target.cursorTooltipEnabled = true; 
  
  chart.legend.itemContainers.each(c => {
    c.setState('default');
  })
}

/**
 * Stacked Area Line Series 생성(DATE X축)
 * @param {*} trendDates 
 * @param {*} chart 
 * @param {string} seriesName Legend에 표시될 이름
 * @param {*} seriesData chart.data와 Line을 그릴 데이터가 다른 경우
 * @param {string} valueYColumnName valueY로 사용할 컬럼명
 * @param {string} dateXColumnName dateX로 사용할 컬럼명
 * @param {boolean} disabledTooltip 
 * @param {string} tooltipHtml 
 * @param {string} tooltipText 
 */
export const createDateStackedAreaLineSeries = (trendDates, chart, seriesName, seriesData, valueYColumnName, dateXColumnName, disabledTooltip, tooltipHtml, tooltipText, tooltipDate) => {
  let _forSeriesData = seriesData;
  if(Object.keys(trendDates).length > seriesData.length) {
    seriesData.map(d => {
      trendDates[d.ymd] = d;
    })
    _forSeriesData = Object.values(trendDates);
  }

  let series = chart.series.push(new am4charts.LineSeries());
  // series.smoothing = 'monotoneX'; //"bezier" | "monotoneX" | "monotoneY"
  series.tensionX = 0.99;
  //series.tensionX = 0.95;
  // series.tensionY = 0.9;
  series.name = seriesName;
  series.toggleName = seriesName.replace(/\((.*?)\)/g, '');
  series.data = _forSeriesData;
  series.dataFields.valueY = valueYColumnName;
  series.dataFields.dateX = dateXColumnName;
  series.strokeWidth = 2;
  series.strokeOpacity = 1;
  
  series.fillOpacity = 0.8;
  series.stacked = true; //

  // series.adapter.add("stroke", (stroke, target) => {
  //   return stroke.lighten(-0.1);
  // });

  if(disabledTooltip) {
    series.tooltip.disabled = disabledTooltip;
  } else {
    series.tooltip = createTooltipTemplate();
    if(tooltipHtml) { //custom tooltip
      series.tooltipHTML = tooltipHtml;
    } else if(tooltipText) {
      series.tooltipText = tooltipText;
    }
  }
  
  series.adapter.add("tooltipText", (text, target, ev) => {
    let _d = target.tooltipDataItem.dataContext;
    if((_d && Object.keys(_d).includes('disabled') && _d.disabled)) {
      return '';
    } else {
      return `${tooltipDate}
              [${series.stroke.hex}]●[/] ${text}`;
    }
  });

  // series.tooltip.getFillFromObject = false;
  // series.tooltip.background.fill = am4core.color("#FFF");

  // series.bulletsContainer.parent = chart.seriesContainer;
  // //hover시 두껍게
  // series.segments.template.interactionsEnabled = true;
  // let hoverState = series.segments.template.states.create('hover');
  // hoverState.properties.strokeWidth = 2;

  /* 
  let hoverState = segment.states.create("hover");
  hoverState.properties.strokeWidth = 3;

  let dimmed = segment.states.create("dimmed");
  dimmed.properties.stroke = am4core.color("#dadada");

  
   */
  let segment = series.segments.template;
  segment.interactionsEnabled = true;
  
  let dimmedState = segment.states.create("dimmed");
  dimmedState.properties.fillOpacity = 0.2;
  dimmedState.properties.strokeOpacity = 0.1;

  series.events.on("over", (ev) => {
    processStackedAreaSeriseOver(chart, ev, 'dimmed');
    // chart.series.each((series)=>{
    //   series.cursorTooltipEnabled = false;
    // })

    // var series = ev.target.parent.parent.parent;
    ev.target.cursorTooltipEnabled = true;
  });

  series.events.on("out", (ev) => {
    processStackedAreaSeriseOver(chart, ev, 'default');
  });

  return series;
}


//Stacked Area Chart legend Toggle Event
const processStackedAreaLegendItemToggle = (chart, ev) => {
  let hitSeries = ev.target.dataItem.dataContext;
  // hitSeries.toFront();
  hitSeries.isActive = !hitSeries.isActive;
  /* ev.target.parent.children.each((legendItem, idx) => {
    if(idx > 0) {
      legendItem.fontWeight = 'normal';
    }
  })
  if(hitSeries.isActive) {
    ev.target.fontWeight = 'bold';
  } */
  //클릭한 모든 시리즈 하이라이트
  /* let _allDimmed = true;
  chart.series.each(function(series) {
    if (series.isActive) {
      _allDimmed = false;
      series.segments.each(function(segment) {
        segment.setState("default");  
      })
    } else {
      series.segments.each(function(segment) {
        segment.setState("dimmed");
      })
    }
  });
  if(_allDimmed) {
    chart.series.each(function(series) {
      series.isActive = false;
      series.segments.each(function(segment) {
        segment.setState("default");  
      })
    });
  } */

  //클릭한 하나의 시리즈만 하이라이트
  if(hitSeries.isActive) {
    chart.series.each((series) => {
      if (series != hitSeries) {
        series.isActive = false;
        series.segments.each((segment) => {
          segment.setState("dimmed");
        })
      } else {
        series.segments.each((segment) => {
          segment.setState("default");
        })
      }
    });
    
    chart.legend.itemContainers.each(c => {
      if(ev.target === c) {
        c.setState('default');
      } else {
        c.setState('dimmed');
      }
    })
  } else {
    chart.series.each((series) => {
      series.segments.each((segment) => {
        segment.setState("default");
      })
    });

    chart.legend.itemContainers.each(c => {
      c.setState('default');
    })
  }
}

/**
 * Stacked Area Legend 생성
 * @param {*} chart 
 * @param {*} position 
 * @param {*} scrollable 
 * @param {*} maxHeight 
 * @param {*} maxWidth 
 * @param {*} useTooltip 
 * @param {*} disabledCursor 
 */
export const createStackedAreaLegend = (chart, position, scrollable, maxHeight, maxWidth, useTooltip, disabledCursor, productFlag=true) => {
  let legend = createCustomLegend(chart, position, scrollable, maxHeight, maxWidth, useTooltip, disabledCursor, productFlag);
  legend.itemContainers.template.togglable = false;
  let dimmedState = legend.labels.template.states.create('dimmed');
  dimmedState.properties.fill = am4core.color("#c6c6c6");

  legend.itemContainers.template.events.on("hit", (ev) => {
    processStackedAreaLegendItemToggle(chart, ev);
  })

  return legend;
}