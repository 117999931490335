import React from 'react';
import Styled from 'styled-components';
import WarnIcon from "assets/svg/icon_warn.svg";
import CheckedIcon from "assets/svg/icon_login_check.svg";

export const TermsPopupData = () => {

  return (
    <StyledTermsPopupData>
      <h2>Terms of Service</h2>
      <ul>
      <li><p>
    SG STATS Terms of Service
</p></li>

<li><p>
    Welcome to SG-STATS!
</p></li>
<li><p>
    Seegene Inc. is a corporation duly organized under the laws of South Korea,
    with a principal place of business located at Taewon Bldg. 91 Ogeum-ro,
    Sogpa-gu, Seoul, South Korea 05548. Using cutting-edge statistical analysis
    technology that yields fast and accurate data analysis and management of
    PCR results, Seegene helps monitor real-time variant trend and discover
    insights for its forecast. If you would like to view more information about
    us, you may visit:
</p></li>
<li><p>
    <a href="https://www.seegene.com/">https://www.seegene.com</a>
</p></li>
<li><p>
    Please read these Terms and Conditions of Use ("Terms", "Terms and
    Conditions") carefully before using the https://sgstats.seegene.com/landing
    (the “Website”) and the SG STATS database (the “Database,” or “Platform”),
    collectively referred to as “Services” owned and operated by Seegene Inc.
    ("us", "we", or "our").
</p></li>
<li><p>
    The Services are made available as courtesy of Seegene, Inc. to our clients
    only. “You” or “your” means you personally (i.e., the individual who reads
    and agrees to be bound by these terms). If you access the Service on behalf
    of a government, organization, corporation or other legal entity
    (including, but not limited to, a partnership, LLC or LLP), collectively,
    you and such corporation or other legal entity on whose behalf you access
    the Service are obliged to abide by these Terms. The Terms apply to all
    users who access or use any part of the Service. Your access to and use of
    the Services are conditioned on your acceptance of and compliance with the
    Terms, which shall become a legally enforceable agreement between us and
    you. If you disagree with any part of the Terms then you may not access the
    Services. By accessing the Services, you agree to comply with and be bound
    by the following Terms. Please review these terms and conditions carefully,
    as the following Terms constitute a legal agreement between us and you. If
    you do not agree to these Terms and Conditions, you must not use the
    Service(s).
</p></li>
<li><p>
    <strong>1. Ownership.</strong>
</p></li>
<li><p>
    All contents provided as part of the Services shall remain the property of
    us and are protected under applicable copyright, patent, trademark, and
    other proprietary rights. Any copying, redistribution, use or publication
    by you of any such content or any part of the Services is strictly
    prohibited, except as expressly permitted by the Terms. Under no
    circumstances will you acquire any ownership rights or other interest in
    any content by or through your use of the Services.
</p></li>
<li><p>
    <strong>2. Use of the Platform.</strong>
</p></li>
<li><p>
    You acknowledge and agree that:
</p></li>
<li><p>
    The Platform utilizes a proprietary platform and software technology owned
    by us and/or third party contractors.
</p></li>
<li><p>
    You may not copy, reverse engineer, disseminate or disclose any part of the
    Service. Further, you may not (a) remove, alter, or obscure any proprietary
    notices, labels, or marks from any information provided on the Platform, or
    (b) modify, translate, adapt, arrange, or create derivative works based on
    the data derived from the Services for any purpose without our prior
    written authorization. The Services and its components may not be separated
    for access, distribution or use unless expressly permitted by us.
</p></li>
<li><p>
    As a client and user, you may not disseminate or utilize any computer
    viruses, Trojan horses, worms, time bombs, malicious code or other
    programming routines that are intended to damage, disrupt, intercept,
    expropriate or interfere with all or any part of the Services.
</p></li>
<li><p>
    <strong>3. Use of Information</strong>
</p></li>
<li><p>
    We reserve the right, and you hereby authorize us, to use and assign all
    information regarding your use of the Services and all information provided
    by you for non-commercial and commercial use, in any manner consistent with
    our Privacy Policy. These Terms contain the entire agreement between you
    and us with respect to this Services and supersede all prior or
    contemporaneous communications and proposals, whether electronic, oral or
    written, between you and us with respect to this Services. You hereby
    acknowledge that you shall have no power or authority to assume or create
    any obligation or responsibility on behalf of us. A party may only waive
    its rights under these Terms, by a written document executed by both
    parties. We also reserve the right at all times to disclose any information
    as necessary to satisfy any applicable law, regulation, legal process, or
    government request.
</p></li>
<li><p>
    <strong>4. License by Client and Users.</strong> You warrant that you have
    the requisite rights to submit, post, reproduce, publish, distribute, or
    otherwise transmit all data, material, information and media submitted or
    uploaded by you as a user on or through the Services platform (“Content”).
</p></li>
<li><p>
    By submitting, posting, or otherwise making Content available to us, or
    available to any individual or entity on or through the Services, you
    hereby grant us a non-exclusive, royalty free, paid up, worldwide,
    sub-licensable license to store, display, reproduce, modify and transmit
    the Content to the etent necessary for the purposes of formatting,
    maintenance, repair, protection, organization, or other administration of
    the Services and create analyses and use such Content in the ordinary
    course of our, or designated third parties’, provision of the data analysis
    collaboration and solutions.
</p></li>
<li><p>

    <strong>5. Permissions Granted; User Names and Passwords. </strong>You
    may be required to create an account to access certain areas of our
    Services. In order to provide the Service, we collect your personal
    information as follows:

</p></li>
<li><p>
    • for sign-up stage 1: Region, Country, Affiliation and
</p></li>
<li><p>
    • for stage 2: Profile image, ID, Password, Retype Password, E-mail, Full
    Name, Position.
</p></li>
<li><p>
    When you are not signed in to an account, we store the information we
    collect with unique identifies tied to the Service you are using. When you
    are signed in, we also collect information that we store with your account,
    which we treat as personal information.
</p></li>
<li><p>
    It is your responsibility to safeguard your account with appropriate and
    strong password. We will not be liable for any loss or damage arising from
    your use of our Services, including failure to safeguard your account and
    all other personal information contained therein.
</p></li>
<li><p>
    With respect to any such registration, we may refuse to grant you, and you
    may not use, a user name (or e-mail address) that is already being used by
    someone else; that may be construed as impersonating another person; that
    belongs to another person; that personally identifies you; that violates
    the intellectual property or other rights of any person; that is offensive;
    or that we rejects for any other reason in our sole discretion.
</p></li>
<li><p>
    Your user name and password are for your personal use only, and not for use
    by any other person. You are responsible for maintaining the
    confidentiality of any password you may use to access the Services, and
    agree not to transfer your password or user name, or lend or otherwise
    transfer your use of or access to the Services, to any third party. You are
    fully responsible for all interaction with the Services that occurs in
    connection with your password or user name. You agree to notify us
    immediately of any unauthorized use of your password or user name or any
    other breach of security related to your account or the Services, and to
    ensure that you “log off”/exit from your account with the Website (if
    applicable) at the end of each session. We are not liable for any loss or
    damage arising from your failure to comply with any of the foregoing
    obligations.
</p></li>
<li><p>
    Seegene Inc. is the responsible controller for your personal information
    provided to us. We process and store your personal data as long as it is
    necessary for the fulfilment of providing the Services to you. You have
    choices regarding the information we collect and how it is used. If you
    wish to manage your information or delete your information, please contact
    at dataprotection@seegene.com
</p></li>
<li><p>
    <strong>6. Additional Provisions. </strong>Your use of the Services is
    subject to additional terms and conditions provided by us and, subject to
    our sole discretion, applicable to certain information, products, and
    offerings available through the Website and Services. Such additional terms
    and conditions include, but are not limited to supplementary user
    agreements and the Privacy Policy (collectively, "Additional Terms"). The
    Additional Terms are hereby incorporated by reference into these Terms.
</p></li>
<li><p>
    6.1 Updating our Terms of Services: As we work constantly to improve our
    services, we may need to update these Terms of Service from time to time to
    accurately reflect our services and practices, without any prior notice.
</p></li>
<li><p>
    6.2 Discontinuation and Change of Services: It is within our sole
    discretion to discontinue these services at any time without prior notice.
    Also, we reserve a right to modify the Services from time to time, without
    informing you of such changes.
</p></li>
<li><p>
    6.3 Free to leave: If you do not agree to our current or updated Terms of
    Services and no longer want to be a member of the Services, you can cancel
    your membership at any time. For clarity, however, we retain all rights to
    the deidentified test results you uploaded and statistical analysis data
    derived therefrom, and as such, the deidentified test results you uploaded
    and statistical analysis data derived therefrom will remain in the
    server(s) of the Services and will continue to be viewed to other users.
</p></li>
<li><p>
    6.4 Information Submitted Through the Website and Platform: Your submission
    of information through the Website or the Platform is governed by our
    Privacy Policy, which is located at https://www.seegene.com/privacy_policy
    and is hereby incorporated into these Terms by this reference. You
    represent and warrant that any information that you provide in connection
    with your use of the Services is and shall remain true, accurate, and
    complete, and that you will maintain and update such information regularly.
    You agree that if any information that you provide is or becomes false,
    inaccurate, obsolete or incomplete, we may terminate your use of the
    Services.
</p></li>
<li><p>
    <strong>7. Account Suspension or Termination</strong>. For use of the
    Services, you agree not to engage in the conduct described below (or to
    facilitate or support others in doing so):
</p></li>
<li><p>
    7.1 YOU SHALL NOT UPLOAD OR DISCLOSE ANY PERSONAL DATA OR IDENTIFIER
    CORRESPONDING TO THE TEST RESULTS YOU UPLOAD. It is solely your
    responsibility to comply with any and all applicable privacy law, rules and
    regulations in uploading and using our Platform.
</p></li>
<li><p>
    7.2 You shall not use this Platform to do or share anything to anyone and
    anywhere in manners that violate these Terms of Service, especially in that
    is unlawful, misleading, discriminatory or fraudulent, or that infringes or
    violates someone else’s rights, including their intellectual property
    rights.
</p></li>
<li><p>
    If we determine that you have clearly, seriously or repeatedly breached our
    Terms, we may permanently suspend your account. We may also suspend or
    disable your account if we are required to do so for legal reasons.
</p></li>
<li><p>
    When we take such action we will let you know and explain any options you
    have to request a review, unless doing so may expose us or others to legal
    liability; harm other users of this platform; compromise or interfere with
    the integrity or operation of any of our services; where we are restricted
    due to technical limitations; or where we are prohibited from doing so for
    legal reasons.
</p></li>
<li><p>
    <strong>8. Limits on Liability</strong>: You understand and agree that the Services are for
    NON-COMMERCIAL AND COMMERCIAL STATISTICAL STUDY AND DISCUSSION, AND NOT FOR
    ANY OTHER PURPOSE. Statistical purposes mean any operation of collection
    and the processing of data necessary for statistical surveys or for the
    production of statistical results.
</p></li>
<li><p>
    The Services are provided “as is” without warranty of any kind, either
    express or implied about the completeness, accuracy, reliability,
    suitability or availability. To the extent permitted by law, we also
    DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED
    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
    NON-INFRINGEMENT. We are not responsible for your actions or conduct or any
    information/data you share. You expressly understand and agree that our
    liability shall be limited to the fullest extent permitted by applicable
    law, and we shall not be liable to you for:
</p></li>
<li><p>
    8.1 any direct, indirect, incidental, special, consequential, punitive, or
    exemplary damages which may be incurred by you, however caused and under
    any theory of liability. This shall include, but not be limited to, any
    loss of profit or revenue (whether incurred directly or indirectly), any
    loss of goodwill or business reputation, any loss of data suffered, cost of
    procurement of substitute goods or services, or other intangible loss;
</p></li>
<li><p>
    8.2 any direct, indirect, incidental, special, consequential, punitive, or
    exemplary damages arising out of or related to this platform or the
    information contained in it, whether such damages arise in contract,
    negligence, tort, under statute, in equity, at law or otherwise;
</p></li>
<li><p>
    8.3 any loss or damage which may be incurred by you, including but not
    limited to loss or damage as a result of:
</p></li>
<li><p>
    8.3.1 any reliance placed by you on the completeness, accuracy,
    reliability, suitability or availability with respect to the information on
    this platform and the Services provided; or
</p></li>
<li><p>
    8.3.2 any changes which we may make to the information or services, or for
    any permanent or temporary cessation in the provision of the information or
    Services (or any related features).
</p></li>
<li><p>
    Notwithstanding the foregoing, applicable law may not allow the limitation
    or exclusion of liability set forth above. In such case, we shall bear
    liability to the extent required by such law.
</p></li>
<li><p>
    Date of Last Revision: March 8, 2022
</p></li>
<li><p>
    I confirm that I have read and hereby agree to the Terms set out above:
</p></li>
      </ul>
    </StyledTermsPopupData>
  )
}

const StyledTermsPopupData = Styled.div`

`;