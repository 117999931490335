import React, { useState, useCallback, useRef, useEffect } from "react";
import styled from "styled-components";
import ViewController from "../common/controller/ViewController";
import ArrowIcon from "assets/svg/icon_arrow_left.svg";
import MeninIcon from "assets/svg/icon_tab_Meningitis.svg";
import HpvIcon from "assets/svg/icon_HPV.svg";
import StiIcon from "assets/svg/icon_STI.svg";
import GiIcon from "assets/svg/icon_GI.svg";
import RvIcon from "assets/svg/icon_RV.svg";
import InputRadioBox from "../common/InputRadioBox";
import InputProdByRadioBox from "../common/InputProductByRadioBox";
import { useQuery } from "@apollo/client";
import { WORLD_PATHOGEN_LIST } from "lib/gql";
import { CircleLoader } from "components/loader";
import _ from 'lodash';
import * as mixins from 'assets/styled/Mixin.styled';

const { REACT_APP_APP_HOST: appHost } = process.env;

const TabMenuName = [
  { name: "Meningitis", value: "MENINGITIS" },
  { name: "HPV", value:"HPV"},
  { name: "STI", value:"STI"},
  { name: "GI", value:"GI"},
  { name: "RV", value:"RV"}
];

const TabMenu = ({ tabColor, pathogenGroup, handleSearchData, handleTabMenuColor, searchCode, setSearchCode, viewport, setViewport, fullScreen}) => {

  const {loading, error, data: mapPathogenList} = useQuery(WORLD_PATHOGEN_LIST, {
    fetchPolicy:"no-cache"
  });

  const TabButtonWrapper = useRef();
  const [extension, setExtension] = useState(false);
  const [tabActive, setTabActive] = useState('#E91313');
  const [checkRadio, setCheckRadio] = useState('FLUA');
  const [inputProduct, setInputProduct] = useState('Allplex Respiratory Panel 1,Allplex Respiratory Panel 1A,Allplex Respiratory Panel 6,Allplex RV Essential Assay,Allplex RV Master Assay,Allplex SARS-CoV-2/FluA/FluB/RSV Assay');
  const TabMenuRef = useRef(null);
  const TabMenuValue = useRef('RV');
  const [height, setHeight] = useState(null);
  
  const filteredList = mapPathogenList?.worldPathogenList
        .filter(i => i.tabGroupCode === TabMenuValue.current)
        .map(i => JSON.parse(i.pathogenList))

  const bgColor = {
    RV: "#E91313",
    GI: "#F3B508",
    STI: "#72C124",
    HPV: "#F53280",
    MENINGITIS: "#0C9EFF",
  };

  const handleActive = (tabName) => {
    if(tabName) {
      setTabActive(bgColor[tabName]);
    }
  }
  
  const handleExtension = useCallback(
    (type, targetName) => {
      if (type === "tabMenu" && !loading) {
        const List = TabButtonWrapper.current.childNodes;
        const target = document.querySelector(`.${targetName}`);
        const selector = (clickTarget) => [...List].filter((inner) => inner === clickTarget);
        const current = selector(target);

        function handleForEach() {
          List.forEach((t) => t.classList.remove("on"));
          current.forEach((t) => current.includes(t) && t.classList.add("on"));
        }
        TabMenuValue.current = targetName;
        handleForEach();
        handleActive(targetName); // 탭메뉴 백그라운드
        setExtension(true);
      }
    },
    [loading, tabColor, pathogenGroup, checkRadio]
  );

  const radioChecked = useCallback((value, relatedProducts) => {
    setCheckRadio(value);
    handleSearchData(TabMenuValue.current)
    setSearchCode(value);
    setInputProduct(relatedProducts);
  }, [checkRadio, pathogenGroup, searchCode])
  
  /* useEffect(() => {
    if(!loading) {
      setTimeout(() => {
        const refHeight = TabMenuRef.current.children[2].clientHeight;
        setHeight(refHeight)
      }, [800])
    }

    window.addEventListener("resize", _.throttle(() => setHeight(TabMenuRef.current.children[2].clientHeight), 500))
  }, [TabMenuRef?.current?.children[2].clientHeight, loading]) */

  return (
    <StyledTabMenu
      extension={extension}
      fullScreen={fullScreen}
      ref={TabMenuRef}
      /* height={height} */
    >
      <TabButton
        ref={TabButtonWrapper}
        extension={extension}
        tabActive={tabActive}
      >
        {TabMenuName.map((button, index) => (
          <button 
            key={`TabMenu-${index}`}
            type="button"
            className={button.value === 'RV' ? 'RV on' : button.value}
            title={button.name}
            onClick={() => handleExtension("tabMenu", button.value)}
          >
            <p>{button.name}</p>
          </button>
        ))}
      </TabButton>

      <Controller>
        <ViewController viewport={viewport} setViewport={setViewport}/>
        <ExtensionButton title="extension" extension={extension} style={{transform: extension? '' : 'rotate(180deg)'}} onClick={() => setExtension(!extension)} />
      </Controller>
      <div>
        <ItemUl>
            {filteredList?.flat().map(({pathogenCode, pathogenName, pathogenFullName, relatedProducts}, index) => (
              <li key={`Item-${index}`}>
                <InputRadioBox
                  key={index}
                  text={pathogenName}
                  pathogenFullName={pathogenFullName}
                  value={pathogenCode}
                  name="pathogenCode"
                  checked={checkRadio === pathogenCode}
                  background={tabColor}
                  onChange={() => radioChecked(pathogenCode, relatedProducts)}
                />
              </li>
            ))}
        </ItemUl>
        <ProductUl>
          {inputProduct.split(',').map((product, index) => (
            <li key={`Product-${index}`}>
              {product}
              {inputProduct.split(',').length-1 > index ? ',' : ''}
            </li>
          ))
          }
        </ProductUl>
      </div>
      
      {
        loading &&
        <CircleLoader isLoading={loading} bgColor="black" />
      }
    </StyledTabMenu>
  );
};

export default TabMenu;

// TabButton Background Image func
function getSvg() {
  const SVG = {
    1: MeninIcon,
    2: HpvIcon,
    3: StiIcon,
    4: GiIcon,
    5: RvIcon,
  };

  let background = "";

  for (let i in SVG) {
    background += `
      &:nth-child(${i}) p::before {
        background-image: url(${appHost}${SVG[i]});
      }
    `;
  }
  return background;
}

const StyledTabMenu = styled.div`
  position: absolute;
  // right:0;
  left:0;
  bottom: 0;
  z-index: 1100;
  //width:${({fullScreen}) => fullScreen ? "100%" : "calc(100% - 323px);"};
  width: 100%;
  background: #161e26;
  transform: ${({ extension }) => (extension ? "translateY(0)" : "translateY(100%)")};
  transition: all 0.3s ease;
  > ul {
    /* height:${({height}) => height && `${height}px`}; */
    z-index:1;
    position:relative;
    padding:19px 20px 20px;
    border-top: 1px solid #262c30;
    background: #161e26;
    li {
      margin:-10px -5px 0 0;

      > span{
        margin: 10px 5px 0 0;
      }
    }
  }
`;

const TabButton = styled.div`
  z-index:0;
  position: absolute;
  left: 0;
  top: -40px;
  overflow: hidden;
  padding: 10px 20px 0 0;
  height: 45px;
  color: #fff;
  > button {
    position:relative;
    top:0;
    float: right;
    margin-left: -10px;
    width: 117px;
    height: 35px;
    background: #161e26;
    border-radius: 4px;
    box-shadow:4px 0 2px rgba(0, 0, 0, 0.2);
    color: inherit;
    transform: skew(0.65rad);
    transition: background 0.3s ease, top 0.3s ease;
    &.on {
      background: ${({ tabActive }) => (tabActive ? tabActive : "#161e26")};
      top:${({ tabActive, extension }) => (tabActive && extension) ? "-3px" : "0"};
    }
    > p {
      display: block;
      position:absolute;
      left:50%;
      top: 50%;
      width: 100%;
      height: 100%;
      margin-left:-9px;
      line-height: 30px;
      font-size:12px;
      font-weight:800;
      transform: skew(-0.65rad) translate3d(-50%, -50%, 0);
      transition:none;
      &::before {
        content:"";
        ${mixins.afterBefore};
        width:18px;
        height:18px;
        margin-right:3px;
        vertical-align:middle;
        background:no-repeat 100% 50%;
      }
    }
    &:after {
      content: "";
      display: block;
      clear: both;
      overflow: hidden;
    }
     &:nth-child(1) {width:128px;}
     &:nth-child(5) {width:129px;}
    ${getSvg()};
  }
`;

const Controller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  // top: -95px;
  // right: 22px;
  top:-160px;
  left:5px;
  height: 102px;
`;

const ExtensionButton = styled.button`
  width: 28px;
  height: 28px;
  background: #161e26 url("${appHost}${ArrowIcon}") no-repeat center;
  border-radius: 50%;
  box-shadow: 2px 2px 3px #00000066;
  transition: visibility 0.3s ease;
`;

const ItemUl = styled.ul`
  padding: 15px 15px 0px 15px;
  > li {
    display: inline-block;
    margin-right: 5px;
  }
`;

const ProductUl = styled.ul`
  padding: 10px 15px 15px 15px;
  font-size: 12px; 
  font-weight: 400; 
  font-family: Lato, Dotum, Arial, sans-serif; 
  color: rgb(167, 173, 180);
  > li {
    display: inline-block;
    margin-right: 5px;
  }
~`;